import { Card, Grid, Typography } from "@material-ui/core";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { useEffect, useRef, useState } from "react";
import DyanmicTable from "../DyanmicTable";
import Legend from "../../RepPerformance/AsOfDate/Legend";
import CompetitorPerformanceLegend from "../../EnhancedInsights/CompetitorPerformance/CompetitorPerformanceLegend";
import { updatedScheme10 } from "../../util/chartColorScheme";
import { getUniqueValues } from "../../util/customFunctions";
import * as d3 from "d3";
import D3WonStackBar from "../D3Components/D3WonStackBar";
import {
  coloredCells,
  colorStages,
} from "../../CustomerSuccess/Pages/AccountHealth/utils";
import D3MixStackBarChart from "../D3Components/D3MixAnalysisStackBarChart";
import D3DonutChart from "../D3Components/D3DonutChart";
import D3CompetitorPerformanceBar from "../D3Components/D3CompetitorPerformanceBar";
import { getCustomSortedStages } from "./utils";
import { renewalsRow } from "../../CustomerSuccess/Pages/RenewalsCompleted/components/utils";
import {
  customSortedOppType,
  customSortedSegments,
} from "../../util/customSortings";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

export default function DataVisualizer(props) {
  var stackBarContainer, doughnutContainer, graphData;
  var color;
  let sortedUniqueStages =
    props.bar === "bar"
      ? props.legends
      : props.type === "qfq"
      ? ["CFQ-0", "CFQ-1", "CFQ-2", "CFQ-3", ">=CFQ-4"]
      : props.winACVMixAnalysisData
          .map((item) => item[props.type])
          .filter(getUniqueValues);

  console.log(props.toggleValue, props.title, 'TTTT');
  let uniqueQuarters = props.winACVMixAnalysisData
    .map((item) => item[props.quarter])
    .filter(getUniqueValues);

  if (props.chartName === "AccountHealth") {
    const missingRows = sortedUniqueStages.filter(function (obj) {
      return colorStages.indexOf(obj) == -1;
    });
    const missingQuarters = uniqueQuarters.filter(function (obj) {
      return renewalsRow.indexOf(obj) == -1;
    });
    sortedUniqueStages = [...colorStages, ...missingRows];
    uniqueQuarters = [...renewalsRow, ...missingQuarters];
  }
  if (props.type === "Acct_Segment") {
    const missing = sortedUniqueStages.filter(function (obj) {
      return customSortedSegments.indexOf(obj) == -1;
    });
    sortedUniqueStages = [...customSortedSegments, ...missing];
  }
  if (props.sortByTotal) {
    var sortedUniquestages = {};
    if (props.sortableKey === "deals") {
      sortedUniqueStages.map(
        (item) =>
          (sortedUniquestages[item] = props.winACVMixAnalysisData
            .filter((f) => f[props.type] === item)
            .map((i) => i.deals)
            .reduce((a, b) => a + b, 0))
      );
    } else {
      sortedUniqueStages.map(
        (item) =>
          (sortedUniquestages[item] = props.winACVMixAnalysisData
            .filter((f) => f[props.type] === item)
            .map((i) => i.acv)
            .reduce((a, b) => a + b, 0))
      );
    }
    const sortable = Object.fromEntries(
      Object.entries(sortedUniquestages)
        .sort(([, a], [, b]) => a - b)
        .reverse()
    );
    const uniqueStages = Object.keys(sortable);
    sortedUniqueStages = [...uniqueStages];
  }
  if (props.chart === "pipelinetrendsRolling") {
    if (!["Stage_At_Time_T", "Booking_Type_NL_CS_US"].includes(props.type)) {
      sortedUniqueStages = sortedUniqueStages.sort();
    }
  }

  const [legend, setLegend] = useState([]);
  useEffect(() => {
    stackBarContainer = document.getElementById(
      `svgContainerStackBar${props.type}${
        props.chartType !== undefined ? props.chartType : ""
      }`
    );
    doughnutContainer = document.getElementById(
      `svgContainerDoughnut${props.type}${
        props.chartType !== undefined ? props.chartType : ""
      }`
    );
    window.addEventListener("resize", drawChart);
    return () => window.removeEventListener("resize", drawChart);
  }, [props.toggleValue]);
  useEffect(() => {
    stackBarContainer = document.getElementById(
      `svgContainerStackBar${props.type}${
        props.chartType !== undefined ? props.chartType : ""
      }`
    );
    doughnutContainer = document.getElementById(
      `svgContainerDoughnut${props.type}${
        props.chartType !== undefined ? props.chartType : ""
      }`
    );
    window.addEventListener("resize", drawChart);
    drawChart();
    return () => window.removeEventListener("resize", drawChart);
  }, [props.winACVMixAnalysisData, props.chartType, props.toggleValue]);

  if (
    ["Pipeline mix by Stage", "Pipeline Created mix by Stage" , "Building Pipeline to Win by Stage"].includes(
      props.title
    ) &&
    props.toggleValue === "stageSequence"
  ) {
    console.log(props.title, props.sortedStages, 'TTTT');
    sortedUniqueStages = props.sortedStages
      .filter((e) => sortedUniqueStages.indexOf(e) !== -1)
      .filter(Boolean);
  }

  const drawChart = async () => {
    color =
      props.colors !== undefined
        ? d3.scaleOrdinal(props.colors)
        : d3.scaleOrdinal(updatedScheme10);
    // if (props.quarter === "Exec_Outreach_Fiscal_Qtr" || props.quarter === "QBR_Fiscal_Qtr") {
    //     uniqueStages = uniqueStages.filter(item => item !== "Prime")
    //     const PrimeData = props.winACVMixAnalysisData.filter(item => item[props.type] === "Prime")[0]
    //     graphData = props.winACVMixAnalysisData.filter(item => item[props.type] !== "Prime")
    //     graphData.unshift(PrimeData)
    //     uniqueStages.unshift("Prime")
    // }

    sortedUniqueStages =
      props.bar === "bar"
        ? sortedUniqueStages
        : getCustomSortedStages(sortedUniqueStages, props.chartName);

    color.domain(sortedUniqueStages);
    if (props.winACVMixAnalysisData.length > 0) {
      setLegend(
        sortedUniqueStages.map((stage) => ({
          text: stage,
          color: color(stage),
        }))
      );
    }

    // if(props.bar){
    //     setLegend(props.legends.map(stage => ({text: stage, color: color(stage)})))
    // }

    // destroy the component

    var _chart21;
    if (props.bar === "bar") {
      D3CompetitorPerformanceBar.destroy(stackBarContainer);
      _chart21 = D3CompetitorPerformanceBar.create(stackBarContainer, {
        data: props.winACVMixAnalysisData,
        Value_Label: props.Value_Label,
        top: props.topCompetitors,
        type: props.type,
        quarter: props.quarter,
        sortedUniqueStages,
        handleChangeLocation: props.handleChangeLocation,
        color,
        unit: props.unit,
        uniqueQuarters,
        chartName: props.chartName,
        arra: props.arra,
        status: props.oppStatus,
      });
    }
    if (props.chartType === "number") {
      D3WonStackBar.destroy(stackBarContainer);
      _chart21 = D3WonStackBar.create(stackBarContainer, {
        data: props.winACVMixAnalysisData,
        type: props.type,
        quarter: props.quarter,
        sortedUniqueStages,
        handleChangeLocation: props.handleChangeLocation,
        color,
        unit: props.unit,
        uniqueQuarters,
        isDrilldown: props.isDrilldown,
        chartName: props.chartName,
        isWeek: props.isWeek
      });
    }
    if (["renewalsCompleted", "acv", "CARR"].includes(props.chartType)) {
      D3MixStackBarChart.destroy(stackBarContainer);
      _chart21 = D3MixStackBarChart.create(stackBarContainer, {
        data: props.winACVMixAnalysisData,
        type: props.type,
        quarter: props.quarter,
        sortedUniqueStages,
        handleChangeLocation: props.handleChangeLocation,
        color,
        chartType: props.chartType,
        isDrilldown: props.isDrilldown,
        uniqueQuarters,
        chartName: props.chartName,
        disablecolumnTotal: props.disablecolumnTotal,
        isWeek: props.isWeek
      });
    }
    // props.bar === "bar" ? D3CompetitorPerformanceBar.destroy(stackBarContainer) : props.chartType === 'number' ? D3WonStackBar.destroy(stackBarContainer) : D3MixStackBarChart.destroy(stackBarContainer)

    D3DonutChart.destroy(doughnutContainer);

    // create the components
    const _chart22 = props.doughnutNoNeeded
      ? null
      : D3DonutChart.create(doughnutContainer, {
          data: props.winACVMixAnalysisData,
          type: props.type,
          quarter: props.quarter,
          sortedUniqueStages,
          handleChangeLocation: props.handleChangeLocation,
          color,
          unit: props.unit,
          chartType: props.chartType,
          isDrilldown: props.isDrilldown,
          chartName: props.chartName,
          uniqueQuarters,
          isMonth: props.isMonth,
        });
  };

  return (
    <Grid container component={Card} raised>
      <Grid
        item
        style={{ padding: "1rem" }}
        xs={
          props.type === "qfq" ||
          ["Pipeline mix by Stage", "Pipeline Created mix by Stage", "Building Pipeline to Win by Stage"].includes(
            props.title
          )
            ? 11
            : 12
        }
      >
        <Typography align="center" variant="h2" style={{ margin: "1rem" }}>
          {props.title}
        </Typography>
      </Grid>
      {props.type === "qfq" && (
        <Grid item xs={1} style={{ padding: "1rem", height: "2rem" }}>
          <ToggleButtonGroup
            color="primary"
            indicatorColor="primary"
            value={props.toggleValue}
            exclusive
            size="small"
            onChange={props.handleChangeToggle}
          >
            <ToggleButton
              style={{
                background: props.toggleValue === "count" ? "#4472C4" : "",
                color: props.toggleValue === "count" ? "white" : "",
                fontSize: "1rem",
                fontWeight: 600,
                width: "3rem",
                height: "2rem",
              }}
              value="count"
            >
              #
            </ToggleButton>
            <ToggleButton
              style={{
                background: props.toggleValue === "acv" ? "#4472C4" : "",
                color: props.toggleValue === "acv" ? "white" : "",
                fontSize: "1rem",
                fontWeight: 600,
                width: "3rem",
                height: "2rem",
              }}
              value="acv"
            >
              $
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      {["Pipeline mix by Stage", "Pipeline Created mix by Stage", "Building Pipeline to Win by Stage"].includes(
        props.title
      ) && (
        <Grid
          item
          xs={1}
          style={{ padding: "1rem", height: "2rem", paddingTop: "1.6rem" }}
        >
          <ToggleButtonGroup
            color="primary"
            indicatorColor="primary"
            value={props.toggleValue}
            exclusive
            size="small"
            onChange={props.handleChangeToggle}
          >
            <CustomWidthTooltip title="Sort by Total ACV." placement="bottom">
              <ToggleButton
                style={{
                  background: props.toggleValue === "AcvSort" ? "#4472C4" : "",
                  color: props.toggleValue === "AcvSort" ? "white" : "",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "3rem",
                  height: "2rem",
                }}
                value="AcvSort"
              >
                $
              </ToggleButton>
            </CustomWidthTooltip>
            <CustomWidthTooltip
              title="Sort by stage sequence."
              placement="bottom"
            >
              <ToggleButton
                style={{
                  background:
                    props.toggleValue === "stageSequence" ? "#4472C4" : "",
                  color: props.toggleValue === "stageSequence" ? "white" : "",
                  fontSize: "1rem",
                  fontWeight: 600,
                  width: "3rem",
                  height: "2rem",
                }}
                value="stageSequence"
              >
                &#x2193;
              </ToggleButton>
            </CustomWidthTooltip>
          </ToggleButtonGroup>
        </Grid>
      )}

      <Grid container id="printChart" item xs={12}>
        <Grid
          item
          xs={12}
          md={props.doughnutNoNeeded ? 12 : window.innerWidth <= 1368 ? 6 : 7}
        >
          <Grid item xs={12} container>
            <div
              style={{
                justifyContent: "center",
                marginTop: 20,
                margin: "auto",
                width: "95%",
              }}
              id={`svgContainerStackBar${props.type}${
                props.chartType !== undefined ? props.chartType : ""
              }`}
              className="svg-container"
            />
          </Grid>
        </Grid>
        {props.doughnutNoNeeded ? null : (
          <Grid container item xs={12} md={window.innerWidth <= 1368 ? 6 : 5}>
            <Grid item xs={12}>
              <div
                style={{
                  justifyContent: "center",
                  marginTop: 20,
                  margin: "auto",
                  width: "95%",
                }}
                id={`svgContainerDoughnut${props.type}${
                  props.chartType !== undefined ? props.chartType : ""
                }`}
                className="svg-container"
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} container style={{ margin: "1rem" }}>
          {props.legendComponent === true ? (
            <CompetitorPerformanceLegend
              square={15}
              legends={legend}
              legendOpacity={0.9}
            />
          ) : (
            <Legend
              square={15}
              legends={legend}
              type={props.type}
              legendOpacity={0.9}
              chartName={props.chartName}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ margin: "2rem", marginTop: "0rem", paddingRight: "5rem" }}
        justify="flex-start"
        container
      >
        {props.tableComponent !== undefined ? (
          props.tableComponent
        ) : (
          <DyanmicTable
            customSort={props.customSort}
            type={props.type}
            quarter={props.quarter}
            winAcvData={props.winACVMixAnalysisData}
            clusterTotal={props.clusterTotal}
            handleChangeLocation={props.handleChangeLocation}
            sortByTotal={props.sortByTotal}
            label={props.label}
            isMonth={props.isMonth}
            sotedStages={props.sortedStages}
            toggleValue={props.toggleValue}
            Value_Label={props.Value_Label}
            UICols={
              props.UICols
                ? props.UICols
                : [
                    {
                      col: "count",
                      label: "# of Opps",
                      type: "number",
                    },
                    {
                      col: "acv",
                      label: "ACV",
                      type: "currency",
                    },
                    {
                      col: null,
                      label: "% of Total",
                      type: "perc",
                    },
                  ]
            }
            isWeek = {props.isWeek}
          />
        )}
      </Grid>
    </Grid>
  );
}
