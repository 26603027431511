import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import D3ErrorBoundary from "../../components/D3ErrorComponent.js";
import d3SankeyChartBuilder from "./SankeyChartBuilder.js";
import { sankeyDataTransformer } from "./utils.js";

const D3SankeyContainer = (props) => {
  const elementId = `svgContainer${props.id}`;
  const [isChartDrawn, setIsChartDrawn] = useState(false);
  useEffect(() => {
    const svgContainer = document.getElementById(elementId);
    let data = sankeyDataTransformer(
      props.tableData.data.filter((it) => it.Opp_Count > 0),
      props.tableData.Qualified_Date_Initial_Fiscal_Quarter,
      props.tableData.allStagesSet,
      {
        source: "Qualified initially",
        quarterRefernce: props.tableData.fqType,
        countReference: "Opp_Count",
        acvReference: "ACV",
        toogleValue: props.toggleValue,
      }
    );
    console.log(data, "sankey_pipeline_progression");
    // data = data.map((it) => ({
    //   ...it,
    //   value: it[props.toggleValue === "count" ? "value" : "acv"],
    // }));
    const options = {
      label: props.toggleValue === "count" ? "# of Opps" : "ACV",
      isAcv: props.toggleValue === "count" ? "" : "$",
      stageToColorMap: props.tableData.stageToColorMap,
    };
    // // console.log(data,'data')
    // // console.log(data.map(it=>({...it,value:it.acv})))
    const result = d3SankeyChartBuilder.create(data, svgContainer, options);
    // setIsChartDrawn(result);
    return () => {
      const el = document.getElementById(elementId);
      d3SankeyChartBuilder.remove(el);
    };
  }, [props.tableData, props.toggleValue, isChartDrawn]);
  console.log(props.tableData, "sankey_pipeline_progression");
  return (
    <>
      {isChartDrawn && (
        <D3ErrorBoundary>
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            {!isChartDrawn && (
              <Typography align="center" variant="h2">
                Chart Not Loaded
              </Typography>
            )}
            <div
              style={{ display: "flex", justifyContent: "center" }}
              id={elementId}
            ></div>
          </Grid>
        </D3ErrorBoundary>
      )}
      {!isChartDrawn && (
        <D3ErrorBoundary>
          <Grid container item style={{ padding: "1rem" }}>
            <Grid container item xs={12}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}></Grid>
              <Grid item xs={1} style={{ padding: "1rem", height: "2rem" }}>
                <ToggleButtonGroup
                  color="primary"
                  indicatorColor="primary"
                  value={props.toggleValue}
                  exclusive
                  size="small"
                  onChange={props.handleChangeToggle}
                >
                  <ToggleButton
                    style={{
                      background:
                        props.toggleValue === "count" ? "#4472C4" : "",
                      color: props.toggleValue === "count" ? "white" : "",
                      fontSize: "1rem",
                      fontWeight: 600,
                      width: "3rem",
                      height: "2rem",
                    }}
                    value="count"
                  >
                    #
                  </ToggleButton>
                  <ToggleButton
                    style={{
                      background: props.toggleValue === "acv" ? "#4472C4" : "",
                      color: props.toggleValue === "acv" ? "white" : "",
                      fontSize: "1rem",
                      fontWeight: 600,
                      width: "3rem",
                      height: "2rem",
                    }}
                    value="acv"
                  >
                    $
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              style={{ padding: "2rem" }}
            >
              <div
                style={{ display: "flex", justifyContent: "center" }}
                id={elementId}
              ></div>
            </Grid>
          </Grid>
        </D3ErrorBoundary>
      )}
    </>
  );
};

export default D3SankeyContainer;
