import {
  Button,
  Card,
  FormControl,
  TextField,
  InputAdornment,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  CardContent,
} from "@material-ui/core";
import React, { Component } from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NumberFormat from "react-number-format";
import D3RepPerformanceStack from "./Charts/D3RepPerformanceStack";
import D3RepPerformanceBar from "./Charts/D3RepPerformanceBar";
import D3BubbleChart from "./Charts/D3BubbleChart";
import { appService } from "../../App/app.service";
import Skeleton from "@material-ui/lab/Skeleton";
import { RepPerformanceTable } from "./Tables/RepPerformanceTable";
import {
  stageFormattter,
  getDefaultSelectedFiscalQuarters,
  getDefaultSalesType,
  countOrACVFormatter,
} from "../../util/customFunctions";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";
const styles = (theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
});
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="$"
      allowNegative={false}
    />
  );
}

class RepPerformanceAnalysis extends Component {
  filters = [
    "closed_fiscal_quarter",
    "Acct_Vertical",
    "Acct_Segment",
    "Acct_Size_Segment",
    "Acct_Industry",
    "Opp_Created_By",
    "Booking_Type_NL_CS_US",
    "Lead_Source",
    "Solution_Cat",
    "Region",
    "Cust_Type",
    "deal_category__c",
    "cross_sell1__c",
    "Type",
    "team",
    "Opp_Value",
    "ACV_Value",
    "Opportunity_Record_Type",
  ];

  state = {
    Opportunity_Record_Type: ["All"],
    year: ["all"],
    month: ["all"],
    quarter: ["all"],
    closed_fiscal_quarter: ["All"],
    Acct_Vertical: ["All"],
    Acct_Segment: ["All"],
    Acct_Industry: ["All"],
    Acct_Size_Segment: ["All"],
    Opp_Created_By: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Lead_Source: ["All"],
    Solution_Cat: ["All"],
    Region: ["All"],
    Cust_Type: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    Type: ["All"],
    team: ["All"],
    disabled: ["year", "month", "quarter"],
    timeFrame: "quarter",
    topReps: [],
    topCalculated: false,
    topRepsTable: [],
    benchmarks: [],
    benchmarks_acv: [],
    acv: null,
    acvlt: null,
    filtersForDrilldown: [],
    repFilters: [],
    secondLevelLocation: null,
    Opp_Value: ["All"],
    ACV_Value: ["All"],
  };
  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Acct_Vertical",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Region",
          LeadSource: "Lead_Source",
          OppCreatedBy: "Opp_Created_By",
          SolutionCat: "Booking_Type_NL_CS_US",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          LeadSource: "Lead_Source",
          OppCreatedBy: "Opp_Created_By",
          SolutionCat: "Solution_Cat",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
        };
  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };
  handleChange = (e) => {
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  handleChangeLocation = (location, stage, lastStage, key, rep) => {
    var wonLabel = this.props.repPerformanceData.labels.filter(
      (item) => item.type === "won"
    )[0].label;
    //  console.log(stage, wonLabel)
    var header =
      lastStage || stage === wonLabel
        ? `${stage.replace(/_/g, " ")} Opportunities`
        : key === "lost"
        ? `Lost / Disqualified from ${
            Array.isArray(stage) ? "Open" : stage.replace(/_/g, " ")
          } stage`
        : key === "cameTo"
        ? `Came to ${stage.replace(/_/g, " ")} stage`
        : key === "movedOn"
        ? `Moved Forward from ${stage.replace(/_/g, " ")} stage`
        : `Opportunities lost at ${stage.replace(/_/g, " ")} stage`;

    let repFilters = [];
    if (rep) {
      repFilters = [
        { title: "Rep", value: typeof rep === "string" ? [rep] : rep },
      ];
    } else {
      repFilters = [{ title: "Rep", value: ["All"] }];
    }

    this.setState({
      ...this.state,
      location: location,
      selectedDrilldownStage: stage,
      lastStage: lastStage,
      header,
      repFilters: [
        {
          title: "Rep",
          value:
            typeof rep === "string" ? [rep] : rep.length === 1 ? rep : ["All"],
        },
      ],
      filtersForDrilldown: [...this.state.filtersForDrilldown],
    });
    // console.log(rep)

    let filters = this.props.currentFilters;
    if (rep !== "All") {
      filters = [
        ...filters,
        { name: "Owner_Name", value: typeof rep === "string" ? [rep] : rep },
      ];
    }

    if (location !== "overview") {
      this.props.getClosedDrilldownOnGraph(key, {
        stage: Array.isArray(stage) ? stage : [stage],
        filters: filters,
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Fiscal Quarter",
          value: this.state.closed_fiscal_quarter.sort(),
        },
      ];
    }

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    if (!this.state.acv !== null && this.state.acv !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Greater Than`,
          value:
            "$ " +
            d3.format(",")(
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv
            ),
        },
      ];

    if (!this.state.Acct_Industry.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value:
            this.props.company === "C0003"
              ? this.state.Acct_Vertical
              : this.state.Acct_Industry,
        },
      ];

    if (!this.state.Cust_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value:
            this.props.company === "C0003"
              ? this.state.Region
              : this.state.Cust_Type,
        },
      ];
    if (!this.state.Lead_Source.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Lead Source", value: this.state.Lead_Source },
      ];
    if (!this.state.Opp_Created_By.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];

    if (!this.state.Solution_Cat.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];

    if (!this.state.Opportunity_Record_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Opportunity Record Type",
          value: this.state.Opportunity_Record_Type,
        },
      ];

    if (!this.state.deal_category__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Deal Category", value: this.state.deal_category__c },
      ];

    // if (!this.state.Type.includes("All"))
    //   filtersArray = [
    //     ...filtersArray,
    //     {
    //       title: this.props.company === "C0008" ? "Deal Type" : "Opp. Type",
    //       value: this.state.Type,
    //     },
    //   ];

    return filtersArray;
  };

  handleChangeLocationBack = (location) => {
    this.setState(
      {
        ...this.state,
        location: location,
        secondLevelLocation: location,
        repFilters: [],
      },
      () => {
        window.scrollTo({
          top: document.documentElement.scrollHeight, // Scroll to the bottom of the page
          // behavior: "smooth", // Optional: for smooth scrolling
        });
      }
    );
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : "",
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (this.state.acv !== null) {
      filters = [
        ...filters,
        {
          name: "acv",
          value: parseInt(this.state.acv),
          string: false,
        },
      ];
    }
    if (this.state.acvlt !== null) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value: parseInt(this.state.acvlt),
          string: false,
        },
      ];
    }
    this.props.getRepPerformanceData(filters);

    this.setState({
      ...this.state,

      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
  };
  componentDidMount() {
    this.props.getfunnelCompareFilters(this.props.company);
    window.addEventListener("resize", this.onResize);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter(
            (s) => s.type === "cross_sell1__c"
          ),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter((s) => s.type === "Type"),
          "Type"
        );
      }

      let OppVal = getDefaultSalesType(
        this.props.funnelCompareFilters.filter((s) => s.type === "Opp_Value"),
        "Opp_Value"
      );

      let ACVVal = getDefaultSalesType(
        this.props.funnelCompareFilters.filter((s) => s.type === "ACV_Value"),
        "ACV_Value"
      );

      this.setState({
        ...prevState,
        closed_fiscal_quarter: getDefaultSelectedFiscalQuarters(
          this.props.funnelCompareFilters,
          "closed_fiscal_quarter"
        ),
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
        Opp_Value: OppVal,
        ACV_Value: ACVVal,
        Type: dealsTypes,
      });
    }
    if (this.props.repPerformanceData !== "" || undefined) {
      D3RepPerformanceBar.destroy(this._rootNode1);
      D3RepPerformanceStack.destroy(this._rootNode2);
      D3BubbleChart.destroy(this._rootNode3);
      this._chart1 = D3RepPerformanceBar.create(this._rootNode1, {
        data: this.props.repPerformanceData.final,
        label: this.props.repPerformanceData.labels,
        Value_Label: `${this.props.Value_Label}`,
      });
      this._chart2 = D3RepPerformanceStack.create(this._rootNode2, {
        data: this.props.repPerformanceData.final,
        label: this.props.repPerformanceData.labels,
        type: "RepPerformance",
      });
      this.chart3 = D3BubbleChart.create(this._rootNode3, {
        data: this.props.repPerformanceData.final,
        label: this.props.repPerformanceData.labels,
      });
    }
  }
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
    D3RepPerformanceBar.destroy(this._rootNode1);
    D3RepPerformanceStack.destroy(this._rootNode2);
    D3BubbleChart.destroy(this._rootNode3);
  };
  onResize = async () => {
    D3RepPerformanceBar.destroy(this._rootNode1);
    D3RepPerformanceStack.destroy(this._rootNode2);
    D3BubbleChart.destroy(this._rootNode3);
    this._chart1 = D3RepPerformanceBar.create(this._rootNode1, {
      data: this.props.repPerformanceData.final,
      label: this.props.repPerformanceData.labels,
      Value_Label: `${this.props.Value_Label}`,
    });
    this._chart2 = D3RepPerformanceStack.create(this._rootNode2, {
      data: this.props.repPerformanceData.final,
      label: this.props.repPerformanceData.labels,
      type: "RepPerformance",
    });
    this.chart3 = D3BubbleChart.create(this._rootNode3, {
      data: this.props.repPerformanceData.final,
      label: this.props.repPerformanceData.labels,
    });
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }
  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }
  _setRef3(componentNode) {
    this._rootNode3 = componentNode;
  }

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onOppValueChange = (event, value) => {
    this.setState({
      ...this.state,
      Opp_Value: value,
    });
  };

  onACVValueChange = (event, value) => {
    this.setState({
      ...this.state,
      ACV_Value: value,
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container justify="flex-start" className={classes.grid}>
          {/* <Grid item xs={12}>
            <Typography variant="h2" align="center" style={{ marginBottom: 5 }}>
              Rep Performance Analysis
            </Typography>
            <Typography variant="body1" style={{ marginLeft: "7rem" }}>
              Deep dive into rep performance by stage with this Pareto analysis
              and dissect conversion rates at various stages of the funnel.
            </Typography>
          </Grid> */}
          {addHeaderdescription(
            "Rep Performance Analysis",
            "Deep dive into rep performance by stage with this Pareto analysis and dissect conversion rates at various stages of the funnel."
          )}
          {this.props.funnelCompareFilters !== "" ? (
            <>
              <Grid style={{ margin: "0.5rem" }} item container xs={12}>
                <Grid item container xs={12}>
                  <Grid
                    container
                    item
                    style={{ marginBottom: 10, padding: 10, paddingBottom: 15 }}
                    xs={2}
                    lg={2}
                  >
                    <Grid
                      item
                      spacing={2}
                      xs={12}
                      container
                      component={Paper}
                      justify="space-around"
                      style={{ paddingTop: 8 }}
                    >
                      <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state.closed_fiscal_quarter.includes(
                                "All"
                              )
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-fiscalQuarter-label"
                          >
                            Closed Fiscal Quarter:
                          </InputLabel>
                          <Select
                            value={this.state.closed_fiscal_quarter}
                            onChange={this.handleChange}
                            name={"closed_fiscal_quarter"}
                            multiple
                            data-test="closed-fiscal-quarter"
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelCompareFilters !== "" &&
                              this.props.funnelCompareFilters
                                .filter(
                                  (y) =>
                                    y.type === "closed_fiscal_quarter" &&
                                    y.closed_fiscal_quarter !== undefined
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y.closed_fiscal_quarter}
                                    value={y.closed_fiscal_quarter}
                                  >
                                    {y.closed_fiscal_quarter}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    style={{ marginBottom: 10, padding: 10, paddingBottom: 15 }}
                    xs={12}
                    lg={4}
                  >
                    <Grid
                      item
                      spacing={2}
                      xs={12}
                      container
                      component={Paper}
                      justify="space-around"
                      style={{ paddingTop: 5 }}
                    >
                      <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            this.props.funnelCompareFilters.filter(
                              (s) => s.type === "acvFilter"
                            )[0].acv
                          }
                          onChange={this.onAcvChange}
                          value={this.state.acv}
                          autoSelect={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${this.props.Value_Label} >= :`}
                              margin="normal"
                              variant="standard"
                              style={{ marginTop: 0 }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color:
                                    this.state.acv !== "" &&
                                    this.state.acv !== null
                                      ? "#4472c4"
                                      : "#000",
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                  <>
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            this.props.funnelCompareFilters.filter(
                              (s) => s.type === "acvFilter"
                            )[0].acv
                          }
                          onChange={this.onAcvLtChange}
                          value={this.state.acvlt}
                          autoSelect={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${this.props.Value_Label} <= :`}
                              margin="normal"
                              variant="standard"
                              style={{ marginTop: 0 }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color:
                                    this.state.acvlt !== "" &&
                                    this.state.acvlt !== null
                                      ? "#4472c4"
                                      : "#000",
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                  <>
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    style={{ marginBottom: 10, padding: 10, paddingBottom: 15 }}
                    xs={12}
                    lg={6}
                  >
                    <Grid
                      item
                      spacing={2}
                      xs={12}
                      container
                      component={Paper}
                      justify="space-around"
                      style={{ paddingTop: 8 }}
                    >
                      <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.Industry
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-industry-label"
                          >
                            {this.props.company === "C0003"
                              ? "Vertical:"
                              : "Industry:"}
                          </InputLabel>
                          <Select
                            value={this.state[this.variableCols.Industry]}
                            onChange={this.handleChange}
                            name={this.variableCols.Industry}
                            data-test="industry"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelCompareFilters !== "" &&
                              this.props.funnelCompareFilters
                                .filter(
                                  (y) =>
                                    y.type === this.variableCols.Industry &&
                                    y[this.variableCols.Industry] !== ""
                                )
                                .sort((a, b) =>
                                  a[this.variableCols.Industry] <
                                  b[this.variableCols.Industry]
                                    ? -1
                                    : a[this.variableCols.Industry] >
                                      b[this.variableCols.Industry]
                                    ? 1
                                    : 0
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y[this.variableCols.Industry]}
                                    value={y[this.variableCols.Industry]}
                                  >
                                    {y[this.variableCols.Industry]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.CustomerSegment
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-region-label"
                          >
                            Customer Segment:
                          </InputLabel>
                          <Select
                            value={
                              this.state[this.variableCols.CustomerSegment]
                            }
                            onChange={this.handleChange}
                            name={this.variableCols.CustomerSegment}
                            data-test="customer_segment"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelCompareFilters !== "" &&
                              this.props.funnelCompareFilters &&
                              this.props.funnelCompareFilters
                                .filter(
                                  (y) =>
                                    y.type ===
                                      this.variableCols.CustomerSegment &&
                                    y[this.variableCols.CustomerSegment] !== ""
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y[this.variableCols.CustomerSegment]}
                                    value={y[this.variableCols.CustomerSegment]}
                                  >
                                    {y[this.variableCols.CustomerSegment]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.CustomerType
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-industry-label"
                          >
                            {this.props.company === "C0003"
                              ? "Region:"
                              : "Customer Type:"}
                          </InputLabel>
                          <Select
                            value={this.state[this.variableCols.CustomerType]}
                            onChange={this.handleChange}
                            name={this.variableCols.CustomerType}
                            data-test="customer_type"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelCompareFilters !== "" &&
                              this.props.funnelCompareFilters
                                .filter(
                                  (y) =>
                                    y.type === this.variableCols.CustomerType &&
                                    y[this.variableCols.CustomerType] !== ""
                                )
                                .sort((a, b) =>
                                  a[this.variableCols.CustomerType] <
                                  b[this.variableCols.CustomerType]
                                    ? -1
                                    : a[this.variableCols.CustomerType] >
                                      b[this.variableCols.CustomerType]
                                    ? 1
                                    : 0
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y[this.variableCols.CustomerType]}
                                    value={y[this.variableCols.CustomerType]}
                                  >
                                    {y[this.variableCols.CustomerType]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  {/* Lead source, opportunity created by, solution cat */}
                  <Grid
                    container
                    item
                    style={{ margin: 7, padding: 0, marginLeft: 0 }}
                    xs={12}
                    lg={5}
                  >
                    <Grid
                      item
                      spacing={2}
                      xs={12}
                      container
                      component={Paper}
                      justify="space-around"
                      style={{ margin: 3, padding: 0 }}
                    >
                      <Grid item xs={12} sm={4} style={{ paddingTop: 10 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.LeadSource
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-region-label"
                          >
                            Lead Source:
                          </InputLabel>
                          <Select
                            value={this.state[this.variableCols.LeadSource]}
                            onChange={this.handleChange}
                            name={this.variableCols.LeadSource}
                            data-test="lead_source"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelCompareFilters !== "" &&
                              this.props.funnelCompareFilters
                                .filter(
                                  (y) =>
                                    y.type === this.variableCols.LeadSource &&
                                    y[this.variableCols.LeadSource] !== ""
                                )
                                .sort((a, b) =>
                                  a[this.variableCols.LeadSource] <
                                  b[this.variableCols.LeadSource]
                                    ? -1
                                    : a[this.variableCols.LeadSource] >
                                      b[this.variableCols.LeadSource]
                                    ? 1
                                    : 0
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y[this.variableCols.LeadSource]}
                                    value={y[this.variableCols.LeadSource]}
                                  >
                                    {y[this.variableCols.LeadSource]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} style={{ paddingTop: 10 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.OppCreatedBy
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-industry-label"
                          >
                            Opportunity created by:
                          </InputLabel>
                          <Select
                            value={this.state[this.variableCols.OppCreatedBy]}
                            onChange={this.handleChange}
                            name={this.variableCols.OppCreatedBy}
                            data-test="opp_created_by"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelCompareFilters !== "" &&
                              this.props.funnelCompareFilters
                                .filter(
                                  (y) =>
                                    y.type === this.variableCols.OppCreatedBy &&
                                    y[this.variableCols.OppCreatedBy] !== ""
                                )
                                .sort((a, b) =>
                                  a[this.variableCols.OppCreatedBy] <
                                  b[this.variableCols.OppCreatedBy]
                                    ? -1
                                    : a[this.variableCols.OppCreatedBy] >
                                      b[this.variableCols.OppCreatedBy]
                                    ? 1
                                    : 0
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y[this.variableCols.OppCreatedBy]}
                                    value={y[this.variableCols.OppCreatedBy]}
                                  >
                                    {y[this.variableCols.OppCreatedBy]}
                                  </MenuItem>
                                ))}{" "}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} style={{ paddingTop: 10 }}>
                        <FormControl fullWidth className={classes.formControl}>
                          <InputLabel
                            className={classes.filterLabel}
                            style={{
                              color: !this.state[
                                this.variableCols.SolutionCat
                              ].includes("All")
                                ? "#4472c4"
                                : "#000",
                            }}
                            id="select-industry-label"
                          >
                            {this.props.company === "C0003"
                              ? "Opportunity Type"
                              : "Solution Category"}
                            :
                          </InputLabel>
                          <Select
                            value={this.state[this.variableCols.SolutionCat]}
                            onChange={this.handleChange}
                            name={this.variableCols.SolutionCat}
                            data-test="solution_category"
                            multiple
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {this.props.funnelCompareFilters !== "" &&
                              this.props.funnelCompareFilters
                                .filter(
                                  (y) =>
                                    y.type === this.variableCols.SolutionCat &&
                                    y[this.variableCols.SolutionCat] !== ""
                                )
                                .sort((a, b) =>
                                  a[this.variableCols.SolutionCat] <
                                  b[this.variableCols.SolutionCat]
                                    ? -1
                                    : a[this.variableCols.SolutionCat] >
                                      b[this.variableCols.SolutionCat]
                                    ? 1
                                    : 0
                                )
                                .map((y) => (
                                  <MenuItem
                                    key={y[this.variableCols.SolutionCat]}
                                    value={y[this.variableCols.SolutionCat]}
                                  >
                                    {y[this.variableCols.SolutionCat]}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    spacing={2}
                    xs={2}
                    container
                    component={Paper}
                    style={{ margin: 12, paddingTop: 5 }}
                  >
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          id="select-team-label"
                          style={{
                            color: !this.state.team.includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                        >
                          Team:
                        </InputLabel>
                        <Select
                          labelId="select-team-label"
                          id="select-team"
                          value={this.state.team}
                          onChange={this.handleChange}
                          label="Team"
                          data-test="team"
                          name="team"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter((i) => i.type === "team")
                              .map((t) => t.Display_Name)
                              .sort()
                              .map((y) => (
                                <MenuItem key={y} value={y}>
                                  {y}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {this.props.company === "C0001" && (
                    <Grid
                      container
                      item
                      style={{ padding: 10, paddingTop: 10, margin: 7 }}
                      xs={2}
                      lg={2}
                    >
                      <Grid
                        item
                        spacing={2}
                        xs={12}
                        container
                        component={Paper}
                        justify="space-around"
                        style={{ paddingTop: 12 }}
                      >
                        <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            <InputLabel
                              className={classes.filterLabel}
                              style={{
                                color: !this.state[
                                  this.variableCols.deal_category__c
                                ].includes("All")
                                  ? "#4472c4"
                                  : "#000",
                              }}
                              id="select-region-label"
                            >
                              Deal Category:
                            </InputLabel>
                            <Select
                              value={
                                this.state[this.variableCols.deal_category__c]
                              }
                              onChange={this.handleChange}
                              name={this.variableCols.deal_category__c}
                              data-test="deal_category__c"
                              multiple
                            >
                              <MenuItem value={"All"}>All</MenuItem>
                              {this.props.funnelCompareFilters !== "" &&
                                this.props.funnelCompareFilters
                                  .filter(
                                    (y) =>
                                      y.type ===
                                        this.variableCols.deal_category__c &&
                                      y[this.variableCols.deal_category__c] !==
                                        ""
                                  )
                                  .map((y) => (
                                    <MenuItem
                                      key={
                                        y[this.variableCols.deal_category__c]
                                      }
                                      value={
                                        y[this.variableCols.deal_category__c]
                                      }
                                    >
                                      {y[this.variableCols.deal_category__c]}
                                    </MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {/*New filters*/}
                  <Grid
                    item
                    spacing={2}
                    xs={2}
                    container
                    component={Paper}
                    style={{ margin: 12, paddingTop: 5 }}
                  >
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={this.props.funnelCompareFilters
                          .filter((f) => f.type === "Opp_Value")
                          .map((f) => f.Opp_Value)}
                        // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                        //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                        onChange={this.onOppValueChange}
                        value={this.state.Opp_Value}
                        data-test="Opp_Value"
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Pursued Opps >= :"
                            margin="normal"
                            variant="standard"
                            style={{ marginTop: "0px", marginBottom: "0px" }}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color:
                                  parseInt(this.state.Opp_Value) > 0
                                    ? "#4472c4"
                                    : "#000",
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              inputComponent: NumberFormatCustom,
                              startAdornment: (
                                <>
                                  {/* <InputAdornment position="start">
                                  $
                                </InputAdornment> */}
                                  {params.InputProps.startAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    spacing={2}
                    xs={2}
                    container
                    component={Paper}
                    style={{ margin: 12, paddingTop: 5 }}
                  >
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={this.props.funnelCompareFilters
                          .filter((f) => f.type === "ACV_Value")
                          .map((f) => countOrACVFormatter(f.ACV_Value))}
                        onChange={this.onACVValueChange}
                        value={this.state.ACV_Value}
                        data-test="ACV_Value"
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Pursued ${this.props.Value_Label} >= :`}
                            margin="normal"
                            variant="standard"
                            style={{ marginTop: "0px", marginBottom: "0px" }}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color:
                                  parseInt(this.state.ACV_Value) > 0
                                    ? "#4472c4"
                                    : "#000",
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              inputComponent: NumberFormatCustom,
                              startAdornment: (
                                <>
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              ),
                              style: {
                                paddingTop: "0.35rem", // Add top padding here
                                ...params.InputProps.style, // Preserve any existing styles
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  {this.props.company === "C0008" && (
                    <Grid
                      container
                      item
                      style={{
                        marginBottom: 15,
                        padding: 10,
                        paddingTop: 10,
                      }}
                      xs={4}
                    >
                      <Grid
                        item
                        spacing={2}
                        xs={12}
                        container
                        component={Paper}
                        justify="space-around"
                        style={{ paddingTop: 8, marginLeft: 0 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ paddingTop: 0, marginLeft: 0 }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            <InputLabel
                              className={classes.filterLabel}
                              style={{
                                color: !this.state[
                                  this.variableCols.cross_sell1__c
                                ].includes("All")
                                  ? "#4472c4"
                                  : "#000",
                              }}
                              id="select-region-label"
                            >
                              Sales Type:
                            </InputLabel>
                            <Select
                              value={
                                this.state[this.variableCols.cross_sell1__c]
                              }
                              onChange={this.handleChange}
                              name={this.variableCols.cross_sell1__c}
                              data-test="cross_sell1__c"
                              multiple
                            >
                              <MenuItem value={"All"}>All</MenuItem>
                              {this.props.funnelCompareFilters !== "" &&
                                this.props.funnelCompareFilters
                                  .filter(
                                    (y) =>
                                      y.type ===
                                        this.variableCols.cross_sell1__c &&
                                      y[this.variableCols.cross_sell1__c] !== ""
                                  )
                                  .map((y) => (
                                    <MenuItem
                                      key={y[this.variableCols.cross_sell1__c]}
                                      value={
                                        y[this.variableCols.cross_sell1__c]
                                      }
                                    >
                                      {y[this.variableCols.cross_sell1__c]}
                                    </MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            <InputLabel
                              className={classes.filterLabel}
                              style={{
                                color: !this.state[
                                  this.variableCols.Type
                                ].includes("All")
                                  ? "#4472c4"
                                  : "#000",
                              }}
                              id="select-region-label"
                            >
                              Deal Type:
                            </InputLabel>
                            <Select
                              value={this.state[this.variableCols.Type]}
                              onChange={this.handleChange}
                              name={this.variableCols.Type}
                              data-test="Type"
                              multiple
                            >
                              <MenuItem value={"All"}>All</MenuItem>
                              {this.props.funnelCompareFilters !== "" &&
                                this.props.funnelCompareFilters
                                  .filter(
                                    (y) =>
                                      y.type === this.variableCols.Type &&
                                      y[this.variableCols.Type] !== ""
                                  )
                                  .map((y) => (
                                    <MenuItem
                                      key={y[this.variableCols.Type]}
                                      value={y[this.variableCols.Type]}
                                    >
                                      {y[this.variableCols.Type]}
                                    </MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/*  */}
                  {this.props.company === "C0013" && (
                    <Grid
                      container
                      item
                      style={{ marginBottom: 10, padding: 10 }}
                      // justify="space-between"
                      xs={2}
                      // md={12}
                      // lg={4}
                    >
                      <Grid
                        item
                        spacing={2}
                        xs={12}
                        container
                        component={Paper}
                        // style={{ paddingTop: 8 }}
                      >
                        <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            <InputLabel
                              className={classes.filterLabel}
                              // style={{ color: "#4472c4" }}
                              style={{
                                color:
                                  !this.state.Opportunity_Record_Type.includes(
                                    "All"
                                  )
                                    ? "#4472c4"
                                    : "#000",
                              }}
                              id="select-region-label"
                            >
                              Opportunity Record Type:
                            </InputLabel>
                            <Select
                              data-test="Opportunity_Record_Type"
                              value={this.state.Opportunity_Record_Type}
                              onChange={this.handleChange}
                              name="Opportunity_Record_Type"
                              multiple
                            >
                              <MenuItem value={"All"}>All</MenuItem>
                              {this.props.funnelCompareFilters !== "" &&
                                this.props.funnelCompareFilters
                                  .filter(
                                    (y) =>
                                      y.type === "Opportunity_Record_Type" &&
                                      y.Opportunity_Record_Type !== ""
                                  )
                                  .map((y) => (
                                    <MenuItem
                                      key={y.Opportunity_Record_Type}
                                      value={y.Opportunity_Record_Type}
                                    >
                                      {y.Opportunity_Record_Type}
                                    </MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    container
                    item
                    style={{ marginBottom: 10, padding: 10 }}
                    justify="space-between"
                    xs={2}
                    lg={2}
                  >
                    <Grid item xs={4} xl={3} container justify="flex-end">
                      <Button
                        data-test="go"
                        variant="contained"
                        color="primary"
                        onClick={this.handleGo}
                      >
                        GO
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              item
              container
              component={Card}
              style={{ margin: "0.5rem" }}
              justify="center"
            >
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="98%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          )}
          {typeof this.props.repPerformanceData === "string" &&
          this.props.repPerformanceData !== "" ? (
            <Grid item container>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h2">
                      {this.props.repPerformanceData}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} style={{ margin: "0.5rem" }}>
                <Card style={{ margin: "auto", width: "98%" }} raised={true}>
                  <Typography
                    variant="h2"
                    style={{ margin: "1rem", textAlign: "center" }}
                  >
                    Relative Performance
                  </Typography>

                  {this.props.repPerformanceData ? (
                    <div
                      style={{
                        justifyContent: "center",
                        marginTop: 20,
                        margin: "auto",
                        width: "95%",
                      }}
                      className="line-container"
                      ref={this._setRef1.bind(this)}
                    />
                  ) : (
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width="95%"
                      height={230}
                      style={{ margin: "1rem" }}
                    />
                  )}
                </Card>
              </Grid>
              <Grid item xs={12} style={{ margin: "0.5rem" }}>
                <Card style={{ margin: "auto", width: "98%" }} raised={true}>
                  <Typography
                    variant="h2"
                    style={{ margin: "1rem", textAlign: "center" }}
                  >
                    Contribution to total
                  </Typography>

                  {this.props.repPerformanceData ? (
                    <div
                      style={{
                        justifyContent: "center",
                        marginTop: 20,
                        margin: "auto",
                        width: "95%",
                      }}
                      className="line-container"
                      ref={this._setRef2.bind(this)}
                    />
                  ) : (
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width="95%"
                      height={130}
                      style={{ margin: "1rem" }}
                    />
                  )}
                </Card>
              </Grid>
              <Grid item xs={12} style={{ margin: "0.5rem" }}>
                <Card style={{ margin: "auto", width: "98%" }} raised={true}>
                  {this.props.repPerformanceData ? (
                    <div>
                      <Typography
                        variant="h2"
                        style={{ margin: "1rem", textAlign: "center" }}
                      >
                        {stageFormattter(
                          this.props.repPerformanceData.labels.filter(
                            (item) => item.type === "first"
                          )[0].label
                        )}{" "}
                        to{" "}
                        {stageFormattter(
                          this.props.repPerformanceData.labels.filter(
                            (item) => item.type === "second"
                          )[0].label
                        )}{" "}
                        &{" "}
                        {stageFormattter(
                          this.props.repPerformanceData.labels.filter(
                            (item) => item.type === "second"
                          )[0].label
                        )}{" "}
                        to{" "}
                        {stageFormattter(
                          this.props.repPerformanceData.labels.filter(
                            (item) => item.type === "won"
                          )[0].label
                        )}{" "}
                        conversion rates
                      </Typography>
                      <div
                        // id="Rep_Performance_Analysis_Bubble_Chart_svg"
                        style={{
                          justifyContent: "center",
                          marginTop: 20,
                          margin: "auto",
                          width: "95%",
                        }}
                        className="line-container"
                        ref={this._setRef3.bind(this)}
                      />
                    </div>
                  ) : (
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width="95%"
                      height={330}
                      style={{ margin: "1rem" }}
                    />
                  )}
                </Card>
              </Grid>

              <Grid item xs={12} style={{ margin: "0.5rem" }}>
                <Paper style={{ margin: "auto", width: "98%" }}>
                  {this.props.repPerformanceData ? (
                    <div>
                      <Typography
                        variant="h2"
                        style={{
                          margin: "2rem 0 0 1rem",
                          padding: "1rem 0 0 0rem",
                          textAlign: "center",
                        }}
                      >
                        All Opportunities that closed from{" "}
                        {stageFormattter(
                          this.props.repPerformanceData.labels.filter(
                            (item) => item.type === "first"
                          )[0].label
                        )}{" "}
                        or later stage
                      </Typography>
                      <RepPerformanceTable
                        repPerformanceData={this.props.repPerformanceData.final}
                        labels={this.props.repPerformanceData.labels}
                        handleChangeLocation={this.handleChangeLocation}
                        Value_Label={this.props.Value_Label}
                        Win_Rate_Prefix={this.props.Win_Rate_Prefix}
                      />
                    </div>
                  ) : (
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width="95%"
                      height={330}
                      style={{ margin: "1rem" }}
                    />
                  )}
                </Paper>
              </Grid>
              {this.state.location === "drilldownOnGraph" && (
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "absolute",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <SingleLevelDrilldown
                    header={this.state.header}
                    back={this.handleChangeLocationBack}
                    filters={[
                      ...this.state.filtersForDrilldown,
                      ...this.state.repFilters,
                    ]}
                  >
                    <Drilldown
                      header={this.state.header}
                      body={this.props.drilldownOnGraphData}
                      company={this.props.company}
                      Value_Label={this.props.Value_Label}
                      handleSecondLevelDrilldown={
                        this.handleSecondLevelDrilldown
                      }
                    />
                  </SingleLevelDrilldown>
                </Grid>
              )}

              {this.state.secondLevelLocation === "drilldownOppId" && (
                <Grid
                  item
                  xs={12}
                  style={{
                    position: "absolute",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <SingleLevelDrilldown
                    header={"Opportunity Change Events"}
                    back={this.handleChangeLocationBack}
                    stepBack={this.stepBack}
                    filters={[]}
                  >
                    <ExpectedAcvPage
                      body={this.props.ExpectedAcvData}
                      Value_Label={this.props.Value_Label}
                    />
                  </SingleLevelDrilldown>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    funnelCompareFilters,
    repPerformanceData,
    user,
    currentFilters,
    drilldownOnGraphData,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelCompareFilters,
    repPerformanceData: repPerformanceData,
    company: user.company,
    Value_Label: user.Value_Label,
    Win_Rate_Prefix: user.Win_Rate_Prefix,
    currentFilters,
    drilldownOnGraphData,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getfunnelCompareFilters: (company) => {
    dispatch({ type: "get_funnelCompare_filters_request" });
    appService.getRepPerformanceAnalysisFilters().then(
      (json) => {
        dispatch({ type: "get_funnelCompare_filters_success", json });
        dispatch({ type: "get_rep_performance_analysis_data_request" });
        let filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
        ];
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }

        const oppValues = getDefaultSalesType(
          json.message.filter((s) => s.type === "Opp_Value"),
          "Type"
        );

        const ACVValues = getDefaultSalesType(
          json.message.filter((s) => s.type === "ACV_Value"),
          "Type"
        );

        if (oppValues[0] !== "All") {
          filters = [...filters, { name: "Opp_Value", value: oppValues }];
        }

        if (ACVValues[0] !== "All") {
          filters = [...filters, { name: "ACV_Value", value: ACVValues }];
        }

        dispatch({ type: "current_selected_filters", filters });
        appService
          .getRepPerformanceAnalysisData(
            json.message.length === 0 ? [] : filters
          )
          .then(
            (json) => {
              dispatch({
                type: "get_rep_performance_analysis_data_success",
                json,
              });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_rep_performance_analysis_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_rep_performance_analysis_data_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnelCompare_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnelCompare_filters_failure", error });
      }
    );
  },
  getRepPerformanceData: (filters = []) => {
    dispatch({ type: "get_rep_performance_analysis_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getRepPerformanceAnalysisData(filters).then(
      (json) => {
        dispatch({ type: "get_rep_performance_analysis_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_rep_performance_analysis_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({
            type: "get_rep_performance_analysis_data_failure",
            error,
          });
      }
    );
  },
  getClosedDrilldownOnGraph: (key, filters) => {
    console.log(key, filters);
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.closedDrilldownOnGraph(key, filters).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedRepPerformanceAnalysis = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RepPerformanceAnalysis));
export { connectedRepPerformanceAnalysis as RepPerformanceAnalysis };