import * as d3 from "d3";
// import './D3RepPerformanceBar.scss'
import { updatedScheme10 } from "../../util/chartColorScheme";
import { computeDomainLimits } from "../../Dashboard/OpenOpportunities/utils";
import {
  customTickFormat,
  getUniqueValues,
  stageFormattter,
  countOrACVFormatter,
  countOrACVFormatterThousand,
  countOrACVFormatterThousandNew,
} from "../../util/customFunctions";
import connectedRegionPerformance from "../../RepPerformance/RegionPerformance/RegionPerformance";
const D3CompetitorPerformanceBar = {};
const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};
D3CompetitorPerformanceBar.create = (el, config) => {
  d3.selection.prototype.last = function () {
    return d3.select(this.nodes()[this.size() - 1]);
  };

  //   console.log(config.data)

  if (config.data && config.top) {
    // config.data = config.data.filter(item => item.Name !== "total")
    const uniqueCompetitors = config.data
      .map((item) => item.competitor)
      .filter(getUniqueValues)
      .slice(0, 8);
    var color =
      config.color !== undefined
        ? config.color
        : d3.scaleOrdinal(updatedScheme10);
    config.barHeight = 10;
    const status = config.status.map((st) => st.stage_name);

    var marginLeftCalc = config.data;

    config.margin = {
      top: 20,
      right: 0,
      bottom: 60,
      left: 120,
      // config.type === "Region" || config.type === "Segment" ? marginLeftCalc.sort((a, b) => b.Display_Name.length - a.Display_Name.length)[0]['Display_Name'].length * 9 : marginLeftCalc.sort((a, b) => b['Sales Rep'].length - a['Sales Rep'].length)[0]['Sales Rep'].length * 7
    };
    if (!config.width) {
      config.width = d3.select(el).node()
        ? d3.select(el).node().getBoundingClientRect().width -
          config.margin.left -
          config.margin.right
        : 50;
    }

    const uniqueQuarters = config.data
      .map((item) => item.quarter)
      .filter(getUniqueValues)
      .sort();

    // console.log(config.data)

    //console.log(uniqueQuarters)

    config.height = 400;
    //  config.type === "Region" || config.type === "Segment" ? config.data.length * 90 : config.data.length * 30

    var data = config.data;

    var x0 = d3
      .scaleBand()
      .rangeRound([0, config.width])
      //  .padding(0.6)
      .align(0.5);
    // set y scale
    var x1 = d3.scaleBand().paddingOuter(0.9);

    var y = d3.scaleLinear().range([0, config.height]);

    const xAxis = d3.axisBottom(x0);

    const yAxis = d3
      .axisLeft(y)
      .tickFormat((d) =>
        d !== 0
          ? `$${d3.format(",")(Math.round(d / 1000))}K`.toUpperCase()
          : `$0K`
      )
      .ticks(8);

    var subCat = config.top
      .map((x) => x.competitor)
      .filter(getUniqueValues)
      .slice(0, 8);
    // console.log(subCat)

    // console.log(config.data.map(d => d.PrimaryCompetitor).filter(getUniqueValues))

    x0.domain(uniqueQuarters);
    x1.domain(subCat).rangeRound([0, x0.bandwidth()]);

    //console.log(uniqueQuarters)

    const { lower_bound, upper_bound } = computeDomainLimits(
      d3.min(config.data, (d) => d.status === status[1] && -d.acv * 1.05),
      d3.max(config.data, (d) => d.status === status[0] && d.acv * 1.05)
    );
    y.domain([upper_bound, lower_bound]);

    // var xAxisTransform =  config.height;
    // if(d3Min < 0 && 0 < d3Max) {
    //     xAxisTransform = config.height * (d3Max / (d3Max - d3Min));
    // }
    // console.log()
    var data = config.type === "net" ? config.arra : config.data;

    // console.log(data)

    function make_x_gridlines() {
      return d3.axisBottom(x1).ticks(8);
    }

    // gridlines in y axis function
    function make_y_gridlines() {
      return d3.axisLeft(y).ticks(10);
    }

    var xAxisHeight = config.height;

    var chartContainer = d3
      .select(el)
      .append("svg")
      .attr("width", config.width + config.margin.left + config.margin.right)
      .attr(
        "height",
        config.height + config.margin.top + config.margin.bottom + 30
      );
    var chart = chartContainer
      .append("g")
      .attr(
        "transform",
        "translate(" + config.margin.left + "," + config.margin.top + ")"
      );

    // const chart = d3
    //   .select(el)
    //   .append('svg')
    //   .attr('width', config.width + config.margin.left + config.margin.right)
    //   .attr('height', config.height + config.margin.top + config.margin.bottom)
    //   .append('g')
    //   .attr('transform', `translate(${config.margin.left},${config.margin.top})`);

    chart
      .append("g")
      .attr("class", "grid")
      .attr("z-index", -1)
      .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));

    chart
      .append("g")
      .attr("class", "xaxis")
      .style("font-size", "0.85rem")
      .attr("transform", "translate(" + 0 + "," + config.height + ")")
      .call(xAxis);

    chart.append("g").attr("class", "yaxis").call(yAxis);

    // console.log('data-data',data)

    var bars = chart
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d, i) {
        return "translate(" + x0(d.quarter) + ",0)";
      });

    if (config.type === "CompetitorPerformance") {
      bars
        .selectAll("rect")
        .data(function (d) {
          return subCat.map(function (key) {
            return {
              key: key,
              value:
                d.competitor === key &&
                (d.status === status[0] ? d.acv : -d.acv),
              status: d.status,
              quarter: d.quarter,
              acv: d.acv,
              drilldown: d.drilldown,
            };
          });
        })
        .enter()
        .append("rect")
        .style("cursor", "pointer")
        .on("click", (d) =>
          config.handleChangeLocation(
            "drilldownOnGraph1",
            d.quarter,
            d.key,
            d.status,
            d.drilldown,
            d.acv
          )
        )
        .attr("x", (d) => x1(d.key))
        //If the value is negative, put the top left corner of the rect bar on the zero line
        .attr("y", (d) => {
          return d.value < 0 ? y(0) : y(d.value);
        })
        .attr("width", x1.bandwidth())
        .attr("height", (d) => Math.abs(y(d.value) - y(0)))
        .attr("fill", function (d) {
          return color(d.key);
        })
        .style("opacity", 0.9);
    } else if (config.type === "net") {
      bars
        .selectAll("rect")
        .data(function (d) {
          return subCat.map(function (key) {
            return {
              key: key,
              value:
                d.competitor === key && (d.status === status[0] ? d.acv : null),
            };
          });
        })
        .enter()
        .append("rect")
        .attr("x", (d) => x1(d.key))
        //If the value is negative, put the top left corner of the rect bar on the zero line
        .attr("y", (d) => {
          return d.value < 0 ? y(0) : y(d.value);
        })
        .attr("width", x1.bandwidth())
        .attr("height", (d) => Math.abs(y(d.value) - y(0)))
        .attr("fill", function (d) {
          return color(d.key);
        })
        .style("opacity", 0.9);
    }

    if (config.type === "CompetitorPerformance") {
      bars
        .selectAll("text")
        .data(function (d) {
          return subCat.map(function (key) {
            return {
              key: key,
              value:
                d.competitor === key &&
                (d.status === status[0] ? d.acv : -d.acv),
              status: d.status,
              quarter: d.quarter,
              acv: d.acv,
              drilldown: d.drilldown,
            };
          });
        })
        .enter()
        .append("text")
        .attr("x", (d) => x1(d.key) + x1.bandwidth() / 2)
        //offset the position of the y value (positive / negative) to have the text over/under the rect bar
        .attr("y", (d) =>
          d.value <= 0
            ? y(0) - (y(4) - Math.abs(y(d.value) - 10))
            : y(d.value) + 15
        )
        .style("cursor", "pointer")
        .on("click", (d) =>
          config.handleChangeLocation(
            "drilldownOnGraph1",
            d.quarter,
            d.key,
            d.status,
            d.drilldown,
            d.acv
          )
        )
        .style("fill", "white")
        .attr("text-anchor", "middle")
        .style("font-size", "0.85rem")
        //make sure one just decimal place is displayed
        .text(
          (d, i) =>
            `${
              (y(d.value) - y(0) >= 20 || y(0) - y(d.value) >= 20) &&
              x1.bandwidth() > 20
                ? `${countOrACVFormatterThousandNew(d.value, true)}`
                : ""
            }`
        );
      // .text(d =>  countOrACVFormatterThousand(d.value, true))
    } else if (config.type === "net") {
      bars
        .selectAll("text")
        .data(function (d) {
          return subCat.map(function (key) {
            return {
              key: key,
              value:
                d.competitor === key &&
                (d.status === status[0] ? d.acv : -d.acv),
            };
          });
        })
        .enter()
        .append("text")
        .attr("x", (d) => x1(d.key) + x1.bandwidth() / 2)
        //offset the position of the y value (positive / negative) to have the text over/under the rect bar
        .attr("y", (d) =>
          d.value <= 0
            ? y(0) - (y(4) - Math.abs(y(d.value) - 10))
            : y(d.value) + 15
        )
        .style("fill", "white")
        .attr("text-anchor", "middle")
        .style("font-size", "0.85rem")
        //make sure one just decimal place is displayed
        .text(
          (d, i) =>
            `${
              (y(d.value) - y(0) >= 20 || y(0) - y(d.value) >= 20) &&
              x1.bandwidth() > 20
                ? `${countOrACVFormatterThousandNew(d.value, true)}`
                : ""
            }`
        );
      // .text(d =>  countOrACVFormatterThousand(d.value, true))
    }

    // console.log(data.map(d => uniqueCompetitors.map(function(key) { return {key: key, value: d.acv}; })))

    chart
      .append("text")
      .attr("y", config.height + 40)
      .attr("x", config.width / 2 - 90)
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      // .attr("font-weight", "500")
      .attr("fill", "#000000")
      .text("Closed Fiscal Quarter");

    chart
      .append("text")
      .attr("y", config.margin.left - 210)
      .attr("x", -(config.height / 2))
      .attr("dy", "1em")
      .attr("class", "axis-label")
      .attr("font-size", "0.85rem")
      .attr("transform", "rotate(-90)") // although axis is rotated, text is not
      .attr("fill", "#00000")
      // .style('text-transform', 'capitalize')
      .text(`${config.Value_Label}`);

    bars
      .append("line")
      .attr("y1", y(0))
      .attr("y2", y(0))
      .attr("x1", 0)
      .attr("x2", x0.bandwidth())
      .attr("stroke", "black")
      .attr(
        "stroke-width",
        config.type === "CompetitorPerformance" ? "0.1px" : "0.5px"
      );
  }
};
D3CompetitorPerformanceBar.destroy = (el) => {
  d3.select(el).selectAll("svg").remove();
  d3.select(".legend-flex").remove();
};

export default D3CompetitorPerformanceBar;
