import { Card, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import NQPSectionTable from "./NQPSectionTable";
import Drilldown from "./Drilldown";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import { customDateFormatter } from "../../util/customFunctions";
import NQPThresholdText from "./NQPThresholdText";
import NQPDrilldown from "../ScoreCard/NQPDrilldownBody";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";

const styles = (theme) => ({
  gridItem: {
    width: "100%",
    padding: "1rem",
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

const classes = styles();

const NQP = (props) => {
  const [location, setlLocation] = useState("");
  const [trailingAvarage, setTrailingAvarage] = useState(false);
  const [filtersForDrilldown, setFiltersForDrilldown] = useState([]);
  const [toggleValue, setToggleValue] = useState("CurrentAcv");
  const [secondlevellocation, setsecondlevellocation] = useState("");
  const [num, setNum] = useState(2);

  useEffect(() => {
    console.log(
      props.filters,
      props.filters === undefined || props.filters === null,
      ![undefined, null].includes(props.filters)
    );
    if (![undefined, null, ""].includes(props.filters)) {
      props.getNQPData(props.filters, props.type);
    }
  }, [props.filters]);

  const handleChangeLocationBack = () => {
    // console.log(location)
    setlLocation("");
    setsecondlevellocation("");
  };

  const handleChangeToggle = (e) => {
    setToggleValue(e.target.value);
  };

  const nqpAccountsegmentLocation = (
    location,
    Week_Ending,
    accountSegment,
    showTrailingAverage
  ) => {
    var filters = [{ name: "Week_Ending", value: Week_Ending, string: true }];
    var drilldownfilter = [
      {
        title: Week_Ending.length > 1 ? "Time Frame" : "Week Ending",
        value:
          Week_Ending.length > 1
            ? "Trailing 8-weeks Avg."
            : customDateFormatter(Week_Ending),
      },
    ];

    if (accountSegment !== undefined) {
      filters = [
        ...filters,
        {
          name: props.type === "Deal" ? "deal_category__c" : "Acct_Segment",
          value: accountSegment,
          string: true,
        },
      ];
      drilldownfilter = [
        ...drilldownfilter,
        {
          title: props.type === "Deal" ? "Deal Category" : "Account Segment",
          value: accountSegment.length > 1 ? "All" : accountSegment,
        },
      ];
    }
    setlLocation(location);
    setFiltersForDrilldown(drilldownfilter);
    setTrailingAvarage(showTrailingAverage);

    props.getNQPAccountsegmentDrilldown(
      filters,
      props.selectedTeam.includes("All") ? undefined : props.selectedTeam,
      props.type
    );
  };

  const handleChangeLocation = (
    location,
    Week_Ending,
    team,
    showTrailingAverage
  ) => {
    var filters = [...props.filters];
    const timeFrame =
      props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].frequencyChurnedStageChangeColumn;
    const drilldowmTimeFrameName =
      props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].UILabelFirstColumn;
    const drilldownTimeframeHeader =
      timeFrame === "Half_Month_Ending"
        ? props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].UILabelLastColumn ===
          "Trailing 4-Month Avg."
          ? "Trailing 4-Month Avg."
          : "Trailing 2-Month Avg."
        : "Trailing 8-weeks Avg.";
    filters = [...filters, { name: timeFrame, value: Week_Ending }];
    var drilldownfilter = [
      {
        title: Week_Ending.length > 1 ? "Time Frame" : drilldowmTimeFrameName,
        value:
          Week_Ending.length > 1
            ? drilldownTimeframeHeader
            : customDateFormatter(Week_Ending),
      },
    ];
    if (
      props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].UILabelLastColumn ===
      "Trailing 4-Month Avg."
    ) {
      setNum(4);
    }
    if (team !== undefined && team.length > 0) {
      var filter =
        props.selectedTeam.includes("All") && props.selectedRep.includes("All")
          ? { name: "Team", value: team }
          : { name: "Owner_Name", value: team };
      filters = [...filters, filter];
      drilldownfilter = [
        {
          title:
            props.selectedTeam.includes("All") &&
            props.selectedRep.includes("All")
              ? "Team"
              : "Rep",
          value: team.length > 1 ? ["All"] : team,
        },
        ...drilldownfilter,
      ];
    }
    setlLocation(location);
    setFiltersForDrilldown(drilldownfilter);
    setTrailingAvarage(showTrailingAverage);
    props.getNQPDrilldown(filters, props.type);
  };

  const handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    setsecondlevellocation(location);
    props.getOpenOppData(oppId);
  };

  const stepBack = () => {
    setsecondlevellocation("");
    props.clearExpectedAcvData();
  };
  // console.log(props)
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12}>
        {props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`] !== "" ? (
          <Grid style={{ padding: "0.5rem 0.5rem" }}>
            <Grid item container xs={12} justify="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Typography
                  variant="h2"
                  style={{
                    width: "100%",
                    margin: "auto",
                    paddingTop: "0.3rem",
                  }}
                  align="center"
                >{`${
                  props.type === "SAL"
                    ? "New SAL Pipeline created"
                    : ["SQLOpen"].includes(props.type)
                    ? `Fresh New Open Pipeline created by half month`
                    : ["NonOpen"].includes(props.type)
                    ? `Fresh New Open Pipeline created by half month - Non Renewals`
                    : ["RenewalsOpen"].includes(props.type)
                    ? `Fresh New Open Pipeline created by half month - Renewals`
                    : ["SQLCreated"].includes(props.type)
                    ? `Fresh New Pipeline created by ${props.user.company === 'C0013' ? 'week': 'half month'}`
                    : ["NonCreated"].includes(props.type)
                    ? `Fresh New Pipeline created by half month - Non Renewals`
                    : ["RenewalsCreated"].includes(props.type)
                    ? `Fresh Created New created by half month - Renewals`
                    : "Fresh " + props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].UITableTitle
                } ${
                  props.tableType ? `- ${props.tableType} ` : ""
                }`}</Typography>
              </Grid>
              <Grid item xs={1}>
                <ToggleButtonGroup
                  color="primary"
                  indicatorColor="primary"
                  value={toggleValue}
                  exclusive
                  // size='small'
                  onChange={handleChangeToggle}
                >
                  <CustomWidthTooltip
                    title={`${props.Value_Label} at ${
                      props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].stageName
                    }`}
                    placement="bottom"
                  >
                    <ToggleButton
                      style={{
                        background: toggleValue === "Acv" ? "#4472C4" : "",
                        color: toggleValue === "Acv" ? "white" : "",
                        fontSize: "1rem",
                        fontWeight: 600,
                        width: "3rem",
                        height: "2rem",
                      }}
                      data-test={`acv-button-${props.type}`}
                      value="Acv"
                    >
                      &#x27F2;$
                    </ToggleButton>
                  </CustomWidthTooltip>
                  <CustomWidthTooltip
                    title={`Current ${props.Value_Label}`}
                    placement="bottom"
                  >
                    <ToggleButton
                      style={{
                        background:
                          toggleValue === "CurrentAcv" ? "#4472C4" : "",
                        color: toggleValue === "CurrentAcv" ? "white" : "",
                        fontSize: "1rem",
                        fontWeight: 600,
                        width: "3rem",
                        height: "2rem",
                      }}
                      data-test={`currentAcv-button-${props.type}`}
                      value="CurrentAcv"
                    >
                      $
                    </ToggleButton>
                  </CustomWidthTooltip>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Grid item container xs={12} justify="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Typography
                  variant="body1"
                  style={{
                    width: "100%",
                    margin: "auto",
                    paddingTop: "0.1rem",
                    fontWeight: 500,
                  }}
                  align="center"
                >
                  {props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].stageName
                    ? `(Came to ${
                        props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].stageName
                      } or later)`
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            {
              // props.type === "SQL" ? <>{
              // ![undefined,null,''].includes(props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].nqpThresholds) ?
              //  <NQPThresholdText selectedRep={props.selectedRep} selectedTeam={props.selectedTeam} thresholds={props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].nqpThresholds} /> : <></> } </> : <></>
            }
            <NQPSectionTable
              selectedTeam={props.selectedTeam}
              selectedRep={props.selectedRep}
              trailingFourWeeks={
                props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].trailingFourWeeks
              }
              trailingFourWeeksAverage={
                props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].trailingFourWeeksAverage
              }
              trailingEightWeeksAverage={
                props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`]
                  .frequencyChurnedStageChangeColumn === "Week_Ending" ||
                props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].UILabelLastColumn ===
                  "Trailing 4-Month Avg."
                  ? props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].trailingEightWeeksAverage
                  : props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].trailingFourWeeksAverage
              }
              trailingFourWeeksData={
                props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].trailingFourWeeksData
              }
              frequencyChurnedStageChangeColumn={
                props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].frequencyChurnedStageChangeColumn
              }
              dataTest={`new-qualified-pipeline-table-existing${props.type}`}
              handleChangeLocation={
                props.type === "Segment" || props.type === "Deal"
                  ? nqpAccountsegmentLocation
                  : handleChangeLocation
              }
              type={props.type}
              traillingFourWeeksDataSegment={
                props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].traillingFourWeeksDataSegment
              }
              traillingFourWeeksSegmentAverage={
                props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].traillingFourWeeksSegmentAverage
              }
              traillingEightWeeksSegmentAverage={
                props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`]
                  .frequencyChurnedStageChangeColumn === "Week_Ending" ||
                props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].UILabelLastColumn ===
                  "Trailing 4-Month Avg."
                  ? props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`]
                      .trailingEightWeeksAverageSegment
                  : props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`]
                      .traillingFourWeeksSegmentAverage
              }
              stageName={props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].stageName}
              firstColumn={props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].UILabelFirstColumn}
              lastColumn={props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].UILabelLastColumn}
              uniqueWeeks={
                props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`]
                  .frequencyChurnedStageChangeColumn === "Week_Ending"
                  ? props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].trailingEightWeeks
                  : props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].UILabelLastColumn ===
                    "Trailing 4-Month Avg."
                  ? props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].trailingEightWeeks
                  : props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].trailingFourWeeks
              }
              thresholds={
                props.type === "SQL"
                  ? props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].nqpThresholds
                  : undefined
              }
              thresholdFlag={
                [
                  "SQL",
                  "SQLOpen",
                  "NonOpen",
                  "RenewalsOpen",
                  "SQLCreated",
                  "NonCreated",
                  "RenewalsCreated",
                  "Renewals",
                  "Non",
                ].includes(props.type)
                  ? props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].thresholdFlag
                  : undefined
              }
              uniqueCustomerSegments={
                props.type === "Segment" || props.type === "Deal"
                  ? props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].acctSegmentOrder
                  : undefined
              }
              acvColumnName={
                toggleValue === "Acv"
                  ? `${props.Value_Label} at ${
                      props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].stageName
                    }`
                  : `Current ${props.Value_Label}`
              }
              acvColumn={toggleValue === "Acv" ? "acv" : "currentACV"}
              isrepScoreCard={props.isrepScoreCard}
              dataID={
                props.type === "SQL"
                  ? `New_Qualified_Pipeline_table`
                  : props.type === "SQLOpen"
                  ? "New_Open_Pipeline_table"
                  : props.type === "SQLCreated"
                  ? "New_Created_Pipeline_table"
                  : props.type === "Renewals"
                  ? "New_Qualified_Pipeline_table_Renewals"
                  : props.type === "RenewalsOpen"
                  ? "New_Open_Pipeline_table_Renewals"
                  : props.type === "RenewalsCreated"
                  ? "New_Created_Pipeline_table_Renewals"
                  : props.type === "Non"
                  ? "New_Qualified_Pipeline_table_Non_Renewals"
                  : props.type === "NonOpen"
                  ? "New_Open_Pipeline_table_Non_Renewals"
                  : props.type === "NonCreated"
                  ? "New_Created_Pipeline_table_Non_Renewals"
                  : `New_Qualified_Pipeline_table_${props.type}`
              }
              showOrange={
                !props.isrepScoreCard
                  ? props[`NQPData${props.type === 'Cross-sell' ? 'CrossSell' : props.type}`].showOrange
                  : false
              }
              Value_Label={props.Value_Label}
            />
          </Grid>
        ) : (
          <Grid
            component={Card}
            item
            container
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
      </Grid>
      {location === "drilldownOnGraph" && (
        <Grid
          item
          xs={12}
          style={{ position: "absolute", width: "100%", marginTop: "30px" }}
        >
          <SingleLevelDrilldown
            header={
              ["SQLCreated", "NonCreated", "RenewalsCreated"].includes(
                props.type
              )
                ? "New Created Pipeline"
                : ["SQLOpen", "NonOpen", "RenewalsOpen"].includes(props.type)
                ? "New Open Pipeline"
                : "New Qualified Pipeline"
            }
            back={handleChangeLocationBack}
            filters={filtersForDrilldown}
          >
            {props.type === "Segment" || props.type === "Deal" ? (
              <NQPDrilldown
                header={"New Qualified Pipeline"}
                body={props.NewQualifiedPipelineDrilldownData}
                showCurrentACV={toggleValue}
                handleSecondLevelDrilldown={handleSecondLevelDrilldown}
                trailingAvarage={trailingAvarage}
                nqpScoreCard={true}
                metaArr={
                  trailingAvarage === true
                    ? [
                        {
                          baseInfo: [{ label: "Added in the past 8 weeks" }],
                        },
                      ]
                    : undefined
                }
              />
            ) : (
              <Drilldown
                header={"New Qualified Pipeline"}
                body={props.drilldownOnGraphData}
                nqpScoreCard={true}
                showCurrentACV={toggleValue}
                handleSecondLevelDrilldown={handleSecondLevelDrilldown}
                trailingAvarage={trailingAvarage}
                showPointer={true}
                sideCol={`Add. in the past ${num}-Months`}
                Value_Label={props.Value_Label}
              />
            )}
          </SingleLevelDrilldown>
        </Grid>
      )}

      {secondlevellocation === "drilldownOppId" && (
        <Grid
          item
          xs={12}
          style={{ position: "absolute", width: "100%", marginTop: "30px" }}
        >
          <SingleLevelDrilldown
            header={"Opportunity Change Events"}
            back={handleChangeLocationBack}
            stepBack={stepBack}
            filters={[]}
          >
            <ExpectedAcvPage
              body={props.ExpectedAcvData}
              Value_Label={props.Value_Label}
            />
          </SingleLevelDrilldown>
        </Grid>
      )}
    </Grid>
  );
};
const mapStateToProps = (state) => {
  const {
    NQPDataSQLCreated,
    NQPDataNonCreated,
    NQPDataRenewalsCreated,
    NQPDataSQLOpen,
    NQPDataNonOpen,
    NQPDataRenewalsOpen,
    NQPDataSQLCQC,
    NQPDataSAL,
    NQPDataSQL,
    NQPDataNew,
    NQPDataExisting,
    NQPDataSegment,
    NQPDataDeal,
    NQPDataNon,
    NQPDataRenewals,
    user,
    drilldownOnGraphData,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
    NQPDataUpsell,
    NQPDataCrossSell,
  } = state.app;

  return {
    NQPDataSQLCreated,
    NQPDataNonCreated,
    NQPDataRenewalsCreated,
    NQPDataSQLOpen,
    NQPDataNonOpen,
    NQPDataRenewalsOpen,
    NQPDataSQLCQC,
    NQPDataNon,
    NQPDataRenewals,
    NQPDataSAL,
    NQPDataSQL,
    NQPDataNew,
    NQPDataExisting,
    NQPDataSegment,
    NQPDataDeal,
    NQPDataUpsell,
    NQPDataCrossSell,
    user,
    Value_Label: user.Value_Label,
    drilldownOnGraphData,
    NewQualifiedPipelineDrilldownData,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getNQPData: (filters, type) => {
    dispatch({ type: `get_msnqp_data_${type}_request` });
    appService.getSQLCreatedData(filters, type).then(
      (json) => {
        dispatch({ type: `get_msnqp_data_${type}_success`, json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: `get_msnqp_data_${type}_failure`,
            error: "Something went wrong",
          });
        else dispatch({ type: "get_quota_attainment_data_failure", error });
      }
    );
  },
  getNQPDrilldown: (filters, type) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getNQPDrilldown(filters, type).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
          a.acvAtStage > b.acvAtStage ? -1 : 1
        );
        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json.message = {
          ...json,
          columns: json.message.columns,
          count_acv: json.message.opportunities[0],
          opportunities: tempOpportunities,
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getNQPAccountsegmentDrilldown: (filters, team, tableType) => {
    dispatch({ type: "get_NewQualifiedPipeline_drilldown_data_request" });
    appService
      .getNewQualifiedPipelineAccountSegmentDrilldownData(
        filters,
        team,
        tableType
      )
      .then(
        (json) => {
          let tempOpportunities = json.message.opportunities[1].sort((a, b) =>
            a.acvAtStage > b.acvAtStage ? -1 : 1
          );
          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json = {
            ...json,
            message: {
              ...json.message,
              recordset: tempOpportunities,
            },
          };

          dispatch({
            type: "get_NewQualifiedPipeline_drilldown_data_success",
            json,
          });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_NewQualifiedPipeline_drilldown_data_failure",
              error: "Something went wrong",
            });
          else
            dispatch({
              type: "get_NewQualifiedPipeline_drilldown_data_failure",
              error,
            });
        }
      );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const NQPSection = connect(mapStateToProps, mapDispatchToProps)(NQP);
export default NQPSection;
