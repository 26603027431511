/**
 *
 * @param {any[]} data
 * @param {string} quarter
 * @param {string[]} stages
 */
export const sankeyDataTransformer = (
  data,
  quarter,
  stages,
  { source, quarterRefernce, countReference, acvReference, toogleValue }
) => {
  console.log(data);
  const sankeyData = stages
    .map((stage) => {
      const stageDataForQuarter = data.filter((it) => {
        console.log(
          it[quarterRefernce],
          quarterRefernce,
          it,
          quarter,
          it.Stage,
          stage
        );
        return it[quarterRefernce] === quarter && it.Stage === stage;
      });
      // console.log(stageDataForQuarter, quarterRefernce);
      const reference = toogleValue === "count" ? countReference : acvReference;
      const count = stageDataForQuarter.reduce(
        (a, b) => a + b[countReference],
        0
      );
      if (count === 0) {
        return null;
      }
      const sankeyBlock = {
        source: `${source} in  ${quarter}`,
        target: stage,
        value: stageDataForQuarter.reduce((a, b) => a + b[reference], 0),
        count,
        acv: stageDataForQuarter.reduce((a, b) => a + b[acvReference], 0),
      };
      return sankeyBlock;
    })
    .filter(Boolean);
  return sankeyData;
};
