import Skeleton from "@material-ui/lab/Skeleton";
import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import DrilldownBody from "./Drilldown";
import { NumberFormatCustom } from "../../components/CustomNumberFormat";
import {
  customDateFormatterNew,
  getDefaultACV,
} from "../../util/customFunctions";
import StageProgressionTable from "./StageProgressionTable";
import PipelineMovementSummaryTable from "./PipelineMovementSummaryTable";
import DatePicker from "../../components/DatePicker_v2";
import { repToUserIDMapping } from "../../util/RepToUserNameMapping";
import Drilldown from "../../components/Drilldown/Drilldown";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";

// // import QualifiedPipelineTable from './QualifiedPipelineTable';
// import { weekEndingMapping } from './utils';
const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  formControl: {
    maxWidth: 250,
  },
  datesFilterContainer: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    padding: 10,
    paddingBottom: 10,
    width: "32%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  repTeamFilterContainer: {
    padding: 10,
    margin: 5,
    width: "29%",
    [theme.breakpoints.down("md")]: {
      width: "37%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  acvFilterContainer: {
    padding: 10,
    margin: 5,
    paddingBottom: 2,
    width: "20%",
    [theme.breakpoints.down("md")]: {
      width: "35%",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
  stageFilterContainer: {
    padding: 5,
    paddingTop: 10,
    margin: 5,
    paddingBottom: 10,
    width: "10%",
    [theme.breakpoints.down("md")]: {
      width: "20%",
    },
  },
  buttonContainer: {
    padding: 5,
    width: "5%",
    alignSelf: "center",
  },
});

class StageProgression extends Component {
  filters = [
    "rep",
    "team",
    "Cust_Type",
    "deal_category__c",
    "Opportunity_Record_Type",
  ];

  state = {
    Opportunity_Record_Type: ["All"],
    location: null,
    disabled: ["year", "month", "quarter"],
    filtersForDrilldown: [],
    activeFilters: "",
    activelast: "",
    rep: ["All"],
    repTeam: "rep",
    team: ["All"],
    Cust_Type: ["All"],
    acv: "",
    acvlt: 0,
    from: "",
    to: "",
    minDateForTo: "",
    maxDateForTo: "",
    minDateForFrom: "",
    maxDateForFrom: "",
    errorTextFrom: "",
    errorTextTo: "",
    last: "",
    fromDisplay: "",
    toDisplay: "",
    activeFrom: "",
    activeTo: "",
    DrildownFilters: [],
    deal_category__c: ["All"],
    secondLevelLocation: null,
    defaultACV: "",
  };
  componentDidMount = () => {
    // get params from browser's local storage. If invoked through the URL
    const params = localStorage.getItem("params");

    // this function is invoked with params if available else undefined will be passed
    this.props.getStageProgressionFilters(JSON.parse(params));
  };
  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    console.log(this.props);
    if (
      this.props.StageProgressionFilters !== "" &&
      prevProps.StageProgressionFilters === ""
    ) {
      // console.log(month)
      // console.log(dateFormatterMoment({year,month:month,date:dt}),year,month,dt)
      const fromDateComponents =
        this.props.StageProgressionFilters.filters.dateFilters.prior7Days.split(
          "-"
        );
      const toDateComponents =
        this.props.StageProgressionFilters.filters.dateFilters.currentDate.split(
          "-"
        );
      const fromDateObj = new Date(
        parseInt(fromDateComponents[0]),
        parseInt(fromDateComponents[1]) - 1,
        parseInt(fromDateComponents[2])
      );
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      const minDateSplit =
        this.props.StageProgressionFilters.minMax.minDate.split("-");
      const minDate = new Date(
        parseInt(minDateSplit[0]),
        parseInt(minDateSplit[1]) - 1,
        parseInt(minDateSplit[2])
      );
      const maxDateSplit =
        this.props.StageProgressionFilters.minMax.maxDate.split("-");
      const maxDate = new Date(
        parseInt(maxDateSplit[0]),
        parseInt(maxDateSplit[1]) - 1,
        parseInt(maxDateSplit[2])
      );
      const defaultACVSelection = getDefaultACV(
        this.props.StageProgressionFilters.filters.acv
      );
      this.setState({
        ...this.state,
        minDateForTo: minDate,
        maxDateForTo: maxDate,
        minDateForFrom: minDate,
        maxDateForFrom: maxDate,
        from: fromDate,
        to: toDate,
        last: this.props.StageProgressionFilters.filters.timeFrame,
        acv:
          defaultACVSelection === ""
            ? ""
            : getDefaultACV(this.props.StageProgressionFilters.filters.acv) > 0
            ? d3.format(",")(
                String(
                  getDefaultACV(this.props.StageProgressionFilters.filters.acv)
                )
              )
            : getDefaultACV(this.props.StageProgressionFilters.filters.acv),
        defaultACV: defaultACVSelection,
        fromDisplay: customDateFormatterNew(fromDate),
        toDisplay: customDateFormatterNew(toDate),
        activeFrom: fromDate,
        activeTo: toDate,
        filtersForDrilldown:
          defaultACVSelection === ""
            ? []
            : [
                {
                  title: `${this.props.Value_Label} >=`,
                  value: d3.format(",")(
                    String(
                      getDefaultACV(
                        this.props.StageProgressionFilters.filters.acv
                      )
                    )
                  ),
                },
              ],
      });
    }
    if (this.props.currentFilters !== "" && prevProps.currentFilters === "") {
      if (this.props.currentFilters.length >= 2) {
        const currentFilters = this.props.currentFilters;
        console.log(currentFilters);
        const fromDateComponents = currentFilters
          .filter((ft) => ft.name === "from")[0]
          .value.split("-");
        const toDateComponents = currentFilters
          .filter((ft) => ft.name === "to")[0]
          .value.split("-");
        const fromDateObj = new Date(
          parseInt(fromDateComponents[0]),
          parseInt(fromDateComponents[1]) - 1,
          parseInt(fromDateComponents[2])
        );
        const toDateObj = new Date(
          parseInt(toDateComponents[0]),
          parseInt(toDateComponents[1]) - 1,
          parseInt(toDateComponents[2])
        );
        const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
          fromDateObj
        ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
        const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
          toDateObj
        ).getDate()}-${new Date(toDateObj).getFullYear()}`;
        this.setState({
          ...this.state,
          acv:
            currentFilters.filter((ft) => ft.name === "acv")[0] &&
            d3.format(",")(
              currentFilters.filter((ft) => ft.name === "acv")[0].value
            ),
          team: currentFilters.filter((ft) => ft.name === "team")[0]
            ? currentFilters.filter((ft) => ft.name === "team")[0].value
            : ["All"],
          Cust_Type: currentFilters.filter((ft) => ft.name === "Cust_Type")[0]
            ? currentFilters.filter((ft) => ft.name === "Cust_Type")[0].value
            : ["All"],
          rep: currentFilters.filter((ft) => ft.name === "rep")[0]
            ? currentFilters.filter((ft) => ft.name === "rep")[0].value
            : ["All"],
          from: currentFilters.filter((ft) => ft.name === "from")[0]
            ? fromDate
            : "",
          to: currentFilters.filter((ft) => ft.name === "to")[0] ? toDate : "",
          last:
            currentFilters.filter((ft) => ft.name === "from").length >= 1
              ? 1
              : this.props.StageProgressionFilters.filters.timeFrame,
          activeFrom: currentFilters.filter((ft) => ft.name === "from")[0]
            ? currentFilters.filter((ft) => ft.name === "from")[0].value
            : "",
          activeTo: currentFilters.filter((ft) => ft.name === "to")[0]
            ? currentFilters.filter((ft) => ft.name === "to")[0].value
            : "",
          filtersForDrilldown: this.paramFiltersForDrilldown(currentFilters),
        });
      }
    }
  }

  paramFiltersForDrilldown = (currentFilters) => {
    const acv =
      currentFilters.filter((ft) => ft.name === "acv")[0] &&
      d3.format(",")(currentFilters.filter((ft) => ft.name === "acv")[0].value);
    const team = currentFilters.filter((ft) => ft.name === "team")[0]
      ? currentFilters.filter((ft) => ft.name === "team")[0].value
      : ["All"];
    const Cust_Typ = currentFilters.filter((ft) => ft.name === "Cust_Type")[0]
      ? currentFilters.filter((ft) => ft.name === "Cust_Type")[0].value
      : ["All"];
    const Deal_Cat = currentFilters.filter(
      (ft) => ft.name === "deal_category__c"
    )[0]
      ? currentFilters.filter((ft) => ft.name === "deal_category__c")[0].value
      : ["All"];
    const rep = currentFilters.filter((ft) => ft.name === "rep")[0]
      ? currentFilters.filter((ft) => ft.name === "rep")[0].value
      : ["All"];
    let filtersArray = [];

    if (!rep.includes("All")) {
      filtersArray = [...filtersArray, { title: "Rep", value: rep }];
    }
    if (!team.includes("All")) {
      filtersArray = [...filtersArray, { title: "Team", value: team }];
    }
    if (!["", undefined, null].includes(acv)) {
      filtersArray = [
        ...filtersArray,
        { title: `${this.props.Value_Label} >=`, value: acv },
      ];
    }
    if (!Cust_Typ.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Customer Type", value: Cust_Typ },
      ];
    }
    if (!Deal_Cat.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Deal Category", value: Deal_Cat },
      ];
    }

    return filtersArray;
  };

  handleChange = (e) => {
    if (e.target.value !== 1) {
      const projectedClosedDateMapping = {
        7: this.props.StageProgressionFilters.filters.dateFilters.prior7Days.split(
          "-"
        ),
        15: this.props.StageProgressionFilters.filters.dateFilters.prior15Days.split(
          "-"
        ),
        30: this.props.StageProgressionFilters.filters.dateFilters.prior1Month.split(
          "-"
        ),
        90: this.props.StageProgressionFilters.filters.dateFilters.prior3Month.split(
          "-"
        ),
        180: this.props.StageProgressionFilters.filters.dateFilters.prior6Month.split(
          "-"
        ),
        365: this.props.StageProgressionFilters.filters.dateFilters.prior1Year.split(
          "-"
        ),
      };
      const fromDateObj = new Date(
        parseInt(projectedClosedDateMapping[e.target.value][0]),
        parseInt(projectedClosedDateMapping[e.target.value][1]) - 1,
        parseInt(projectedClosedDateMapping[e.target.value][2])
      );
      const fromDate = `${new Date(fromDateObj).getMonth() + 1}-${new Date(
        fromDateObj
      ).getDate()}-${new Date(fromDateObj).getFullYear()}`;
      const toDateComponents =
        this.props.StageProgressionFilters.filters.dateFilters.currentDate.split(
          "-"
        );
      const toDateObj = new Date(
        parseInt(toDateComponents[0]),
        parseInt(toDateComponents[1]) - 1,
        parseInt(toDateComponents[2])
      );
      const toDate = `${new Date(toDateObj).getMonth() + 1}-${new Date(
        toDateObj
      ).getDate()}-${new Date(toDateObj).getFullYear()}`;
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
        to: toDate,
        from: fromDate,
        errorTextFrom: "",
        errorTextTo: "",
      });
    } else {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  };

  handleChangeFilter = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };

  orderRepsByLastName = () => {
    let temp = this.props.StageProgressionFilters.filters.reps;
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleChangeLocation = (location, fromStage, toStage) => {
    // console.log(fromStage.includes('') ? [...fromStage, null] : fromStage)

    if (location !== "overview") {
      var filters = this.props.currentFilters;
      filters = [
        ...filters,
        {
          name: "fromStage",
          value: fromStage.includes("") ? [...fromStage, null] : fromStage,
        },
        { name: "toStage", value: toStage },
      ];
      var drilldownfilters = [
        {
          title: "From Stage",
          value: fromStage.length > 1 ? "All" : fromStage,
        },
        { title: "To Stage", value: toStage.length > 1 ? "All" : toStage },
      ];
      this.props.getStageProgressionDrilldown(
        this.state.activeFrom,
        this.state.activeTo,
        filters.map((ft) => {
          if (ft.name === "rep") {
            return {
              name: ft.name,
              value: repToUserIDMapping(
                ft.value,
                this.props.StageProgressionFilters.filters
              ),
              string: true,
            };
          } else {
            return ft;
          }
        })
      );
      this.setState({
        location,
        secondLevelLocation: location,
        DrildownFilters: [
          ...drilldownfilters,
          ...this.state.filtersForDrilldown,
        ],
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
      drilldownTable: "",
    });
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  setFromDate = (date) => {
    const split = this.state.to.split("-");
    const toDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) > toDate) {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "From date cannot be later than the To date",
      });
    } else {
      this.setState({
        ...this.state,
        from: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "",
        errorTextTo: "",
      });
    }
  };

  setToDate = (date) => {
    const split = this.state.from.split("-");
    const fromDate = new Date(
      parseInt(split[2]),
      parseInt(split[0]) - 1,
      parseInt(split[1])
    );
    if (new Date(date) < fromDate) {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextFrom: "To date cannot be earlier than the From date",
      });
    } else {
      this.setState({
        ...this.state,
        to: `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`,
        errorTextTo: "",
        errorTextFrom: "",
      });
    }
  };

  plot = () => {
    const fromSplit = this.state.from.split("-");
    const toSplit = this.state.to.split("-");

    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : repToUserIDMapping(
                    this.state[f],
                    this.props.StageProgressionFilters.filters
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.StageProgressionFilters.filters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
          string: "true",
        };
      })
      .filter((f) => !f.value.includes("All"))
      .filter((f) => !this.state.disabled.includes(f.name));
    if (
      this.state.acv !== null &&
      this.state.acv !== 0 &&
      this.state.acv !== ""
    ) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    } else if (this.state.acv === 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }
    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }
    const fromDate = `${this.state.from.split("-")[2]}-${
      this.state.from.split("-")[0]
    }-${this.state.from.split("-")[1]}`;
    const toDate = `${this.state.to.split("-")[2]}-${
      this.state.to.split("-")[0]
    }-${this.state.to.split("-")[1]}`;
    this.props.getStageProgressionData(fromDate, toDate, filters);
    this.setState({
      ...this.state,
      fromDisplay: customDateFormatterNew(this.state.from),
      toDisplay: customDateFormatterNew(this.state.to),
      activeFrom: this.state.from,
      activeTo: this.state.to,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      activeFilters: filters,
      activelast: this.state.last,
    });
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    if (!this.state.rep.includes("All")) {
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];
    }
    if (!this.state.team.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];
    }
    filtersArray = [
      ...filtersArray,
      { title: `${this.props.Value_Label} >=`, value: this.state.acv },
    ];
    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filtersArray = [
        ...filtersArray,
        { title: "ACV <", value: this.state.acvlt },
      ];
    }
    if (!this.state.Cust_Type.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Customer Type", value: this.state.Cust_Type },
      ];
    }

    if (!this.state.deal_category__c.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Deal Category", value: this.state.deal_category__c },
      ];
    }

    return filtersArray;
  };
  componentWillUnmount() {
    localStorage.removeItem("params");
  }

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.gridContainer}>
        <Grid
          item
          container
          className={classes.grid}
          style={{ margin: 0, marginBottom: 10 }}
          xs={12}
        >
          {/* <Grid item xs={12} justify="center">
            <Typography variant="h2" align="center">
              Stage Progression Analysis
            </Typography>
          </Grid>
          <Grid item xs={12} justify="left" style={{ margintop: 10 }}>
            <Typography variant="body1">
              Analyze the progression of opportunities across various stages.
            </Typography>
          </Grid> */}
          {addHeaderdescription(
            "Stage Progression Analysis",
            "Analyze the progression of opportunities across various stages."
          )}
          {this.props.StageProgressionFilters !== "" ? (
            <>
              <Grid
                container
                xs={12}
                alignItems="flex-start"
                style={{ marginBottom: 5 }}
              >
                <Grid
                  xs={4}
                  item
                  container
                  className={classes.datesFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-last-label"
                        style={{ color: "#4472c4" }}
                      >
                        Time Frame - Last:
                      </InputLabel>
                      <Select
                        labelId="select-last-label"
                        id="select-last"
                        value={this.state.last}
                        onChange={this.handleChange}
                        label="Last:"
                        name="last"
                        disabled={this.state.timeFrame === "custom"}
                      >
                        {[
                          { text: "Custom", value: 1 },
                          { text: "7 days", value: 7 },
                          { text: "15 days", value: 15 },
                          { text: "1 month", value: 30 },
                          { text: "3 months", value: 90 },
                          { text: "6 months", value: 180 },
                          { text: "1 year", value: 365 },
                        ].map((y) => (
                          <MenuItem key={y.text} value={y.value}>
                            {y.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: 10 }}>
                    <DatePicker
                      label="From start of:"
                      setDate={(date) => this.setFromDate(date)}
                      disabled={this.state.last !== 1}
                      min={this.state.minDateForFrom}
                      max={this.state.maxDateForFrom}
                      current={this.state.from}
                      errorText={this.state.errorTextFrom}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: 10 }}>
                    <DatePicker
                      label="To end of:"
                      setDate={(date) => this.setToDate(date)}
                      disabled={this.state.last !== 1}
                      min={this.state.minDateForTo}
                      max={this.state.maxDateForTo}
                      current={this.state.to}
                      errorText={this.state.errorTextTo}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={4}
                  container
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={6} style={{ paddingTop: 0 }}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={
                        this.props.StageProgressionFilters !== "" &&
                        this.orderRepsByLastName()
                      }
                      getOptionLabel={(option) => option}
                      value={this.state.rep[0]}
                      onChange={this.onRepChange}
                      name="rep"
                      disabled={!this.state.team.includes("All")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rep:"
                          variant="standard"
                          InputLabelProps={{
                            style: {
                              color: !this.state.rep.includes("All")
                                ? "#4472c4"
                                : "#000",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: 10 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={!this.state.rep.includes("All")}
                    >
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.team.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Team:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-team"
                        value={this.state.team}
                        onChange={this.handleChangeFilter}
                        label="Team"
                        name="team"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.StageProgressionFilters !== "" &&
                          this.props.StageProgressionFilters.filters.teams
                            .map((t) => t.Display_Name)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={2}
                  className={classes.repTeamFilterContainer}
                  component={Paper}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.Cust_Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Customer Type:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-cust-type"
                        data-test="select-cust-type"
                        value={this.state.Cust_Type}
                        onChange={this.handleChangeFilter}
                        label=""
                        name="Cust_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.StageProgressionFilters !== "" &&
                          this.props.StageProgressionFilters.filters.custType
                            .map((t) => t.Cust_Type)
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {this.props.company === "C0013" && (
                  <Grid
                    item
                    container
                    xs={2}
                    className={classes.repTeamFilterContainer}
                    component={Paper}
                  >
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          id="select-region-label"
                          style={{
                            color: !this.state.Opportunity_Record_Type.includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                        >
                          Opportunity Record Type:
                        </InputLabel>
                        <Select
                          data-test="Opportunity_Record_Type"
                          value={this.state.Opportunity_Record_Type}
                          onChange={this.handleChangeFilter}
                          name="Opportunity_Record_Type"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.StageProgressionFilters !== "" &&
                            this.props.StageProgressionFilters.filters.OpportunityRecordType.filter(
                              (y) =>
                                y.type === "Opportunity_Record_Type" &&
                                y.Opportunity_Record_Type !== ""
                            ).map((y) => (
                              <MenuItem
                                key={y.Opportunity_Record_Type}
                                value={y.Opportunity_Record_Type}
                              >
                                {y.Opportunity_Record_Type}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                {/* OppType */}
                {this.props.StageProgressionFilters.filters.dealCat && (
                  <Grid
                    item
                    container
                    xs={2}
                    className={classes.repTeamFilterContainer}
                    component={Paper}
                  >
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          id="select-team-label"
                          style={{
                            color: !this.state.deal_category__c.includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                        >
                          Deal Category:
                        </InputLabel>
                        <Select
                          labelId="select-team-label"
                          id="select-cust-type"
                          data-test="select-cust-type"
                          value={this.state.deal_category__c}
                          onChange={this.handleChangeFilter}
                          label=""
                          name="deal_category__c"
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.StageProgressionFilters !== "" &&
                            this.props.StageProgressionFilters.filters.dealCat
                              .map((t) => t.deal_category__c)
                              .map((y) => (
                                <MenuItem key={y} value={y}>
                                  {y}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {/* Adding Acv Value */}
                <Grid container item style={{ padding: 13 }} xs={4}>
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 15 }}
                  >
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      {this.state.acv === "" ? (
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            this.props.StageProgressionFilters !== "" &&
                            this.props.StageProgressionFilters.filters.acv.map(
                              (d) => d3.format(",")(String(d.acv))
                            )
                          }
                          // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                          //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                          onChange={this.onAcvChange}
                          value={this.state.acv}
                          data-test="acv"
                          autoSelect={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Current ${this.props.Value_Label} >= :`}
                              margin="normal"
                              variant="standard"
                              style={{ marginTop: "0px", marginBottom: "0px" }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color:
                                    this.state.acv !== null &&
                                    this.state.acv !== 0 &&
                                    this.state.acv !== ""
                                      ? "#4472c4"
                                      : "#000",
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                  <>
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            this.props.StageProgressionFilters !== "" &&
                            this.props.StageProgressionFilters.filters.acv.map(
                              (d) => d3.format(",")(String(d.acv))
                            )
                          }
                          // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                          //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                          onChange={this.onAcvChange}
                          value={this.state.acv}
                          data-test="acv"
                          autoSelect={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Current ${this.props.Value_Label} >= :`}
                              margin="normal"
                              variant="standard"
                              style={{ marginTop: "0px", marginBottom: "0px" }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color:
                                    this.state.acv !== null &&
                                    this.state.acv !== 0
                                      ? "#4472c4"
                                      : "#000",
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                  <>
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={
                          this.props.StageProgressionFilters !== "" &&
                          this.props.StageProgressionFilters.filters.acv.map(
                            (d) => d3.format(",")(String(d.acv))
                          )
                        }
                        // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                        //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                        onChange={this.onAcvLtChange}
                        value={this.state.acvlt}
                        data-test="acvlt"
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Current ${this.props.Value_Label} < :`}
                            margin="normal"
                            variant="standard"
                            style={{ marginTop: "0px", marginBottom: "0px" }}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color:
                                  this.state.acvlt !== null &&
                                  this.state.acvlt !== 0
                                    ? "#4472c4"
                                    : "#000",
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              inputComponent: NumberFormatCustom,
                              startAdornment: (
                                <>
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/*  */}
                <Grid
                  container
                  item
                  className={classes.repTeamFilterContainer}
                  justify="space-between"
                  xs={4}
                  lg={2}
                >
                  {/* <Grid
                    item
                    spacing={2}
                    xs={8}
                    xl={9}
                    container
                    component={Paper}
                  >
                    <Grid item xs={12} sm={12} style={{ paddingBottom: "0px" }}>
                      {this.state.acv === "" ? (
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            this.props.StageProgressionFilters !== "" &&
                            this.props.StageProgressionFilters.filters.acv.map(
                              (d) => d3.format(",")(String(d.acv))
                            )
                          }
                          // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                          //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                          onChange={this.onAcvChange}
                          value={this.state.acv}
                          data-test="acv"
                          autoSelect={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${this.props.Value_Label} >= :`}
                              margin="normal"
                              variant="standard"
                              style={{ marginTop: 4 }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color:
                                    this.state.acv !== null &&
                                    this.state.acv !== 0 &&
                                    this.state.acv !== ""
                                      ? "#4472c4"
                                      : "#000",
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                  <>
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            this.props.StageProgressionFilters !== "" &&
                            this.props.StageProgressionFilters.filters.acv.map(
                              (d) => d3.format(",")(String(d.acv))
                            )
                          }
                          // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                          //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                          onChange={this.onAcvChange}
                          value={this.state.acv}
                          data-test="acv"
                          autoSelect={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${this.props.Value_Label} >= :`}
                              margin="normal"
                              variant="standard"
                              style={{ marginTop: 4 }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color:
                                    this.state.acv !== null &&
                                    this.state.acv !== 0
                                      ? "#4472c4"
                                      : "#000",
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                  <>
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid> */}
                  <Grid item xs={4} xl={3} container justify="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.plot}
                      disabled={
                        this.state.errorTextFrom !== "" ||
                        this.state.errorTextTo !== ""
                      }
                    >
                      GO
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              item
              container
              component={Card}
              style={{ margin: "1rem" }}
              justify="center"
            >
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            className={classes.grid}
            container
            justify="center"
          >
            <Grid
              item
              xs={12}
              style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
            >
              <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                {this.props.StageProgressionData !== "" ? (
                  <Grid item container xs={12} justify="center">
                    <Typography
                      variant="h2"
                      style={{
                        margin: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      Stage Progression Analysis Table
                    </Typography>
                    <StageProgressionTable
                      StageProgressionData={this.props.StageProgressionData}
                      handleChangeLocation={this.handleChangeLocation}
                      Value_Label={this.props.Value_Label}
                      dataTest={"Stage Progression Analysis Table"}
                    />
                  </Grid>
                ) : (
                  <Grid item container justify="center">
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                )}
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
            >
              <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                {this.props.StageProgressionData !== "" ? (
                  <Grid item container xs={12} justify="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="h2"
                        style={{
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        Pipeline Movement Summary
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <PipelineMovementSummaryTable
                        StageProgressionData={this.props.StageProgressionData}
                        handleChangeLocation={this.handleChangeLocation}
                        Value_Label={this.props.Value_Label}
                        dataTest={"Pipeline Movement Summary"}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container justify="center">
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Stage Progression Analysis"}
              back={this.handleChangeLocationBack}
              filters={this.state.DrildownFilters}
            >
              <Drilldown
                body={this.props.drilldownOnGraphData}
                Value_Label={this.props.Value_Label}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  var {
    StageProgressionData,
    StageProgressionFilters,
    drilldownOnGraphData,
    currentFilters,
    user,
    ExpectedAcvData,
  } = state.app;

  return {
    StageProgressionData,
    StageProgressionFilters,
    drilldownOnGraphData,
    currentFilters,
    company: user.company,
    Value_Label: user.Value_Label,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getStageProgressionFilters: (paramsFilters) => {
    console.log(paramsFilters);
    dispatch({ type: "get_stageProgression_filters_request" });
    appService.getStageProgressionFilters().then(
      (json) => {
        dispatch({ type: "get_stageProgression_filters_success", json });
        dispatch({ type: "get_stageProgression_data_request" });
        const defaultACV = getDefaultACV(json.message.filters.acv);

        let filters =
          defaultACV !== ""
            ? [{ name: "acv", value: defaultACV, string: false }]
            : [];

        let from, to, filterParameters;
        from = json.message.filters.dateFilters.prior7Days;
        to = json.message.filters.dateFilters.currentDate;
        paramsFilters = JSON.parse(paramsFilters);
        console.log(paramsFilters);
        // filters = [...filters, { name: "from", value: from }]
        // filters = [...filters, { name: "to", value: to }]

        if (paramsFilters) {
          // filtering from and to Dates from the param filters
          // const fromDateFunc = paramsFilters.filter(function(ft){
          //   return ft.name === "from"
          // })[0]
          const fromDate = paramsFilters.filter((ft) => ft.name === "from")[0];
          const toDate = paramsFilters.filter((ft) => ft.name === "to")[0];
          // if from and to Dates are available from params use it else use original from and to
          if (fromDate) {
            from = fromDate.value;
          }
          if (toDate) {
            to = toDate.value;
          }

          // filters = [...paramsFilters]
          const filtersName = filters.map((item) => item.name);
          const paramsFiltersNames = paramsFilters.map((item) => item.name);
          const commonFitlers = filtersName.filter((item) =>
            paramsFiltersNames.includes(item)
          );
          const defaultFiltersUncommon = filters.filter(
            (item) => !commonFitlers.includes(item.name)
          );
          console.log(defaultFiltersUncommon, filters, paramsFilters);
          filters = [...paramsFilters, ...defaultFiltersUncommon];
          console.log(filters);
          filterParameters = filters.filter(
            (ft) => ft.name !== "from" && ft.name !== "to"
          );
        }
        dispatch({ type: "current_selected_filters", filters });
        // as from and to are part of the query string, removing the from and to from the paramfilters
        const stageProgressionFilters = filters
          .filter((ft) => ft.name !== "from" && ft.name !== "to")
          .map((ft) => {
            if (ft.name === "rep") {
              return {
                name: ft.name,
                value: repToUserIDMapping(ft.value, json.message.filters),
                string: true,
              };
            } else {
              return ft;
            }
          });
        console.log(from, to, filterParameters);
        appService
          .getStageProgressionData(from, to, stageProgressionFilters)
          .then(
            (json) => {
              dispatch({ type: "get_stageProgression_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_stageProgression_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({ type: "get_stageProgression_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_stageProgression_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_stageProgression_filters_failure", error });
      }
    );
  },
  getStageProgressionDrilldown: (from, to, filters) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService
      .getStageProgressionDrilldown(
        from,
        to,
        filters.filter((ft) => ft.name !== "from" && ft.name !== "to")
      )
      .then(
        (json) => {
          let tempOpportunities = json.message.opportunities[1];
          tempOpportunities.sort((a, b) =>
            a.acvAtStage > b.acvAtStage ? -1 : 1
          );

          tempOpportunities = tempOpportunities.map((item, index) => {
            let temp = {
              ...item,
              OppNo: index + 1,
            };
            return temp;
          });

          json = {
            ...json,
            message: {
              ...json.message,
              count_acv: json.message.opportunities[0],
              opportunities: tempOpportunities,
              columns: json.message.columns,
            },
          };
          dispatch({ type: "get_drilldown_on_graph_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_drilldown_on_graph_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_drilldown_on_graph_failure", error });
        }
      );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getStageProgressionData: (from, to, filters) => {
    dispatch({ type: "get_stageProgression_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getStageProgressionData(from, to, filters).then(
      (json) => {
        dispatch({ type: "get_stageProgression_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_stageProgression_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_stageProgression_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedStageProgression = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StageProgression));
export default connectedStageProgression;
