import React from "react";
import {
  Card,
  Paper,
  CardContent,
  InputAdornment,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  CardHeader,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Link,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Fragment } from "react";
import * as d3 from "d3";
import Skeleton from "@material-ui/lab/Skeleton";
import NumberFormat from "react-number-format";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import { updatedScheme10 } from "../../util/chartColorScheme";
import CompetitorPerformanceTable from "./CompetitorPerformaceTable";
import CompetitorPerformanceTable2 from "./CompetitorPerformancetable2";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
// import FunnelDrilldownBody from './FunnelDrilldownBody'
// import StyledTableRow from '../../components/StyledTableRow'
// import FunnelDrilldownOnOpenBody from './FunnelDrilldownOnOpenBody'
import { AgGridReact } from "ag-grid-react";
import CompetitorPerformanceLegend from "./CompetitorPerformanceLegend";
import WinAcvDrillDownDrilldownBody from "../WinACVAnalysis/WinAcvDrillDown";
import CompetitorDrilldown from "./CompetitorDrilldown";
import {
  getUniqueValues,
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
} from "../../util/customFunctions";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";

const styles = (theme) => ({
  root: {},
  content: { width: "100%" },
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    minWidth: "169px",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    cursor: "pointer",
  },
  repNumberACV: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "92px",
    cursor: "pointer",
  },
  repNumberPercent: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    minWidth: "90px",
    fontWeight: 600,
    cursor: "pointer",
  },
  repTextH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
    fontWeight: 600,
  },
  repNumberH: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
    fontWeight: 600,
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  // filterLabel: {
  //     color: 'black', fontSize: '0.9375rem', paddingTop: 5
  // },
  // disabledFilterLabel: {
  //     color: 'black', fontSize: '0.9375rem', paddingTop: 5
  // },
  mainTable: {
    // maxWidth: '100%',
    // [theme.breakpoints.up(1250)]: {
    //     width: 1000,
    // },
    // [theme.breakpoints.up(1900)]: {
    //     width: 1400,
    // }
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  // formControl: {
  //     maxWidth: 250
  // },
  tableHeader: {
    fontSize: "15px",
    whiteSpace: "normal",
    textAlign: "center",
  },
  tableCell: {
    whiteSpace: "normal",
  },
  blackLabel: {
    color: "black",
  },
  inputFieldContainers: {
    marginBottom: 10,
    padding: 10,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 0,
    },
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      //prefix="$"
      allowNegative={false}
    />
  );
}

const _isNaN = function (value) {
  var n = Number(value);
  return n !== n;
};

const formatter = (value) => {
  if (_isNaN(value) || value === null) return 0;
  return d3.format(",")(value.toFixed(1));
};

const stringNumFormatter = (value) => {
  if (_isNaN(value) || value === null) return 0;
  return d3.format(",")(Math.round(value));
};

const currencyFormatter = (value) => {
  if (_isNaN(value) || value === null) return 0;
  return "$ " + d3.format(",")(Math.round(value));
};
const formatWithPercent = (value) => {
  if (_isNaN(value) || value === null) return 0 + " %";
  return Math.round(value) + " %";
};

class CompetitorPerformance extends React.Component {
  state = {
    Opportunity_Record_Type: ["All"],
    closed_year: ["All"],
    closed_month: ["All"],
    closed_fiscal_quarter: ["All"],
    projected_close_calendar_year: ["All"],
    projected_close_calendar_month: ["All"],
    projected_close_fiscal_quarter: ["All"],
    projected_close_date_year: ["All"],
    projected_close_date_month: ["All"],
    projected_close_date_fiscal_quarter: ["All"],
    open_calendar_year: ["All"],
    open_calendar_month: ["All"],
    open_fiscal_quarter: ["All"],
    validated_year: ["All"],
    validated_month: ["All"],
    validated_fiscal_quarter: ["All"],
    rep: ["All"],
    Acct_Industry: ["All"],
    Acct_Vertical: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    Cust_Type: ["All"],
    Region: ["All"],
    deal_category__c: ["All"],
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    Solution_Cat: ["All"],
    Exclude_Primary_Competitor: ["All"],
    Earliest_Lost_Stage:
      this.props.funnelFilters === ""
        ? []
        : [
            this.props.funnelFilters
              .filter((item) => item.type === "Earliest_Lost_Stage")
              .map((i) => i.stage_name)[1],
          ],
    team: ["All"],
    timeFrame: "quarter",
    disabled: [
      // 'year', 'month', 'quarter', 'team', 'acv'
    ],
    repTeam: "rep",
    acv: null,
    acvlt: 0,
    acvPreset: "All",
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
    uniqueQuarters: ["All"],
    drilldownStage: "",
    drilldownQuarter: "",
    location: "",
    drilldownOnGraphData: "",
    filtersForDrilldown: [],
    secondLevelLocation: null,
    // data: {

    // },
  };
  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Acct_Vertical",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Region",
          LeadSource: "Lead_Source",
          quarter: "validated_fiscal_quarter",
          year: "validated_year",
          month: "validated_month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          LeadSource: "Lead_Source",
          quarter: "open_fiscal_quarter",
          year: "open_calendar_year",
          month: "open_calendar_month",
          projectedQuarter: "projected_close_fiscal_quarter",
          projectedMonth: "projected_close_calendar_month",
          projectedYear: "projected_close_calendar_year",
          deal_category__c: "deal_category__c",
        };

  filters = [
    "closed_year",
    "closed_month",
    "closed_fiscal_quarter",
    this.variableCols.projectedMonth,
    this.variableCols.projectedYear,
    this.variableCols.projectedQuarter,
    this.variableCols.year,
    this.variableCols.month,
    this.variableCols.quarter,
    "rep",
    "team",
    this.variableCols.CustomerSegment,
    this.variableCols.CustomerType,
    this.variableCols.Industry,
    "Acct_Segment",
    this.variableCols.LeadSource,
    "Opp_Created_By",
    "Solution_Cat",
    "Earliest_Lost_Stage",
    "Exclude_Primary_Competitor",
    "deal_category__c",
    "Opportunity_Record_Type",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // stages = this.props.company === 'C0003' ? ['Qualified Suspect (E)',
  //     'Qualified Sponsor (D)',
  //     'Qualified Power Sponsor (C)',
  //     'Approval Received (B)',
  //     'Pending Sale (A)',
  //     'Win (W)']
  //     :
  //     this.props.company === 'C0002' ? [
  //         'Qualify', 'Demo', 'POC', 'Proposal', 'Negotiate', 'Won'
  //     ]
  //         :
  //         ['Suspect', 'Qualified', 'Proposed', 'Selected', 'Committed', 'Closed Won']

  // staticCustSegment = this.props.company === 'C0002' ?
  //     [
  //         { value: '<=$100M', displayValue: '<=$100M' },
  //         { value: '$100M-$1B', displayValue: '$100M-$1B' },
  //         { value: '>$1B', displayValue: '>$1B' },
  //     ]
  //     :
  //     [
  //         { value: '<=$25M', displayValue: '<=$25M' },
  //         { value: '$25-50M', displayValue: '$25M-50M' },
  //         { value: '$50-250M', displayValue: '$50M-250M' },
  //         { value: '$250-500M', displayValue: '$250M-500M' },
  //         { value: '$500M-2B', displayValue: '$500M-2B' },
  //         { value: '>$2B', displayValue: '>$2B' }
  //     ]

  componentDidMount() {
    this.props.getFunnelFilters(this.props.company);
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelFilters !== "" && prevProps.funnelFilters === "") {
      let temp = getDefaultSelectedFiscalQuarters(
        this.props.funnelFilters,
        "closed_fiscal_quarter"
      );
      const defaultPrimaryCompetitors = this.props.funnelFilters.filter(
        (i) => i.type === "defaultPrimaryCompetitors"
      )[0].primaryCompetitors;
      this.setState({
        ...prevState,
        closed_fiscal_quarter: temp,
        Exclude_Primary_Competitor:
          defaultPrimaryCompetitors.length > 0
            ? defaultPrimaryCompetitors
            : ["All"],
        filtersForDrilldown: [
          {
            title: "Open Fiscal Quarter",
            value: [
              this.props.funnelFilters.filter(
                (y) =>
                  y.type === "closed_fiscal_quarter" &&
                  y["closed_fiscal_quarter"] !== ""
              )[1].closed_fiscal_quarter,
            ],
          },
        ],
        uniqueQuarters: temp,
        Earliest_Lost_Stage: [
          this.props.funnelFilters
            .filter((item) => item.type === "Earliest_Lost_Stage")
            .map((i) => i.stage_name)[1],
        ],
      });
    }
    if (
      this.props.funnelData !== prevProps.funnelData &&
      prevProps.funnelData !== ""
    ) {
      let uniqueQuarters = [];
      if (this.props.funnelData) {
        this.props.final.map((i) => {
          if (!uniqueQuarters.includes(i.quarter) && i.quarter) {
            uniqueQuarters = [...uniqueQuarters, i.quarter];
          }
        });

        this.setState({
          ...this.state,
          uniqueQuarters: uniqueQuarters.sort(),
        });
      }
    }
  }

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Fiscal Quarter",
          value: this.state.closed_fiscal_quarter,
        },
      ];
    }
    if (!this.state.Opportunity_Record_Type.includes("All")) {
      console.log(
        "this.state.Opportunity_Record_Type",
        this.state.Opportunity_Record_Type
      );
      filtersArray = [
        ...filtersArray,
        {
          title: "Opportunity Record Type",
          value: this.state.Opportunity_Record_Type,
        },
      ];
    }
    if (!this.state.closed_year.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Closed Calendar Year", value: this.state.closed_year },
      ];
    }
    if (!this.state.closed_month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Calendar Month",
          value: this.state.closed_month.map((i) => this.months[i - 1]),
        },
      ];
    }

    if (!this.state.rep.includes("All"))
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (this.state.acv !== null && this.state.acv !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Greater Than`,
          value:
            "$ " +
            d3.format(",")(
              this.state.acv && typeof this.state.acv === "string"
                ? this.state.acv.match(/,/g)
                  ? this.state.acv.match(/,/g).length === 1
                    ? parseInt(this.state.acv) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acv) * 1000000
                    : parseInt(this.state.acv)
                  : parseInt(this.state.acv)
                : this.state.acv
            ),
        },
      ];

    if (!this.state.acvlt !== null && this.state.acvlt !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Less Than`,
          value:
            "$ " +
            d3.format(",")(
              this.state.acvlt && typeof this.state.acvlt === "string"
                ? this.state.acvlt.match(/,/g)
                  ? this.state.acvlt.match(/,/g).length === 1
                    ? parseInt(this.state.acvlt) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acvlt) * 1000000
                    : parseInt(this.state.acvlt)
                  : parseInt(this.state.acvlt)
                : this.state.acvlt
            ),
        },
      ];

    if (!this.state[this.variableCols.projectedQuarter].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Fiscal Quarter",
          value: this.state[this.variableCols.projectedQuarter],
        },
      ];

    if (!this.state[this.variableCols.projectedYear].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Calendar Year:",
          value: this.state[this.variableCols.projectedYear],
        },
      ];

    if (!this.state[this.variableCols.projectedMonth].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Proj. Close Calendar Month",
          value: this.state[this.variableCols.projectedMonth].map(
            (i) => this.months[i - 1]
          ),
        },
      ];

    if (!this.state[this.variableCols.Industry].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value: this.state[this.variableCols.Industry],
        },
      ];

    if (!this.state[this.variableCols.CustomerSegment].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Customer Segment",
          value: this.state[this.variableCols.CustomerSegment],
        },
      ];

    if (!this.state[this.variableCols.CustomerType].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value: this.state[this.variableCols.CustomerType],
        },
      ];

    if (!this.state[this.variableCols.quarter].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Stage E Fiscal Quarter:"
              : "Open Fiscal Quarter:",
          value: this.state[this.variableCols.quarter],
        },
      ];

    if (!this.state[this.variableCols.year].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Stage E Fiscal Year:"
              : "Open Fiscal Year:",
          value: this.state[this.variableCols.year],
        },
      ];

    if (!this.state[this.variableCols.month].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title:
            this.props.company === "C0003"
              ? "Stage E Fiscal Month:"
              : "Open Fiscal Month:",
          value: this.state[this.variableCols.month].map(
            (i) => this.months[i - 1]
          ),
        },
      ];

    if (!this.state[this.variableCols.LeadSource].includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Lead Source",
          value: this.state[this.variableCols.LeadSource],
        },
      ];

    if (!this.state.Opp_Created_By.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state.Solution_Cat.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];

    return filtersArray;
  };

  handleChange = (e) => {
    if (
      e.target.name === "year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        month: ["All"],
        quarter: ["All"],
      });
    } else if (typeof e.target.value === "string") {
      this.setState({
        ...this.state,
        [e.target.name]: [e.target.value],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string:
            f === "team"
              ? true
              : f === "Opportunity_Record_Type"
              ? true
              : this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0]
              ? this.props.funnelFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0].string === "Y"
              : true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (this.state.acv !== null) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }
    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }
    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
    // let stage_names = []
    // this.props.funnelFilters.map((i) => {
    //     if (i.stage_name !== undefined) {
    //         if (i.stage_name === this.state.Earliest_Lost)
    //             stage_names = [...stage_names, i.stage_name]
    //     }
    // })

    this.props.getCompetitorAnalysisData(filters);
  };

  orderRepsByLastName = () => {
    let temp = this.props.funnelFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  handleChangeLocation = (
    location,
    quarter,
    owner,
    status,
    drilldownData,
    count_acv
  ) => {
    console.log(drilldownData);
    if (location !== "" && quarter !== "") {
      //console.log(this.state.closed_fiscal_quarter)
      var quarter = quarter;
      if (quarter === "All") {
        quarter = this.state.closed_fiscal_quarter.join(", ");
      }
      drilldownData.sort((a, b) => parseFloat(b.acv) - parseFloat(a.acv));
      drilldownData.map((d, i) => (d.oppNo = i + 1));
      var headerName = `${status} Opportunities`;

      //console.log(drilldownData)

      let filtersArray = [];
      if (!this.state.closed_fiscal_quarter.includes("All")) {
        filtersArray = [
          ...filtersArray,
          { title: "Closed Fiscal Quarter", value: quarter },
        ];
      } else {
        filtersArray = [
          ...filtersArray,
          { title: "Closed Fiscal Quarter", value: quarter },
        ];
      }

      if (!this.state.rep.includes("All"))
        filtersArray = [
          ...filtersArray,
          { title: "Rep", value: this.state.rep },
        ];

      if (!this.state.team.includes("All"))
        filtersArray = [
          ...filtersArray,
          { title: "Team", value: this.state.team },
        ];

      if (this.state.acv !== null && this.state.acv !== 0)
        filtersArray = [
          ...filtersArray,
          {
            title: `${this.props.Value_Label} Greater Than`,
            value:
              "$ " +
              d3.format(",")(
                this.state.acv && typeof this.state.acv === "string"
                  ? this.state.acv.match(/,/g)
                    ? this.state.acv.match(/,/g).length === 1
                      ? parseInt(this.state.acv) * 1000
                      : this.state.acv.match(/,/g).length === 2
                      ? parseInt(this.state.acv) * 1000000
                      : parseInt(this.state.acv)
                    : parseInt(this.state.acv)
                  : this.state.acv
              ),
          },
        ];

      if (this.state.acvlt !== null && this.state.acvlt !== 0) {
        filtersArray = [
          ...filtersArray,
          {
            name: "acvlt",
            value:
              this.state.acvlt && typeof this.state.acvlt === "string"
                ? this.state.acvlt.match(/,/g)
                  ? this.state.acvlt.match(/,/g).length === 1
                    ? parseInt(this.state.acvlt) * 1000
                    : this.state.acvlt.match(/,/g).length === 2
                    ? parseInt(this.state.acvlt) * 1000000
                    : parseInt(this.state.acvlt)
                  : parseInt(this.state.acvlt)
                : this.state.acvlt,
            string: false,
          },
        ];
      }

      if (!this.state[this.variableCols.Industry].includes("All"))
        filtersArray = [
          ...filtersArray,
          {
            title: this.props.company === "C0003" ? "Vertical" : "Industry",
            value: this.state[this.variableCols.Industry],
          },
        ];

      if (!this.state[this.variableCols.CustomerSegment].includes("All"))
        filtersArray = [
          ...filtersArray,
          {
            title: "Customer Segment",
            value: this.state[this.variableCols.CustomerSegment],
          },
        ];

      if (!this.state[this.variableCols.deal_category__c].includes("All"))
        filtersArray = [
          ...filtersArray,
          {
            title: "Deal Category",
            value: this.state[this.variableCols.deal_category__c],
          },
        ];

      if (!this.state.Exclude_Primary_Competitor.includes("All"))
        filtersArray = [
          ...filtersArray,
          {
            title: "Exclude Primary Competitor",
            value: this.state.Exclude_Primary_Competitor,
          },
        ];

      if (this.state.Earliest_Lost_Stage !== "")
        filtersArray = [
          ...filtersArray,
          {
            title: "Earliest Lost Stage",
            value: this.state.Earliest_Lost_Stage,
          },
        ];

      if (!this.state[this.variableCols.CustomerType].includes("All"))
        filtersArray = [
          ...filtersArray,
          {
            title: this.props.company === "C0003" ? "Region" : "Customer Type",
            value: this.state[this.variableCols.CustomerType],
          },
        ];

      if (
        this.props.company !== "C0003" &&
        !this.state.Solution_Cat.includes("All")
      )
        filtersArray = [
          ...filtersArray,
          { title: "Solution Category", value: this.state.Solution_Cat },
        ];

      var columns = [...this.props.CompetitorColumns];
      if (status === "Closed Lost" || status === "Lost") {
        columns.splice(7, 1, {
          label: "Lost from Stage",
          field: "final_stage_for_closed",
          type: "string",
        });
      }

      this.setState({
        ...this.state,
        location: location,
        headerName: headerName,
        filtersForDrilldown: filtersArray,
        drilldownOnGraphData: {
          columns: columns,
          opportunities: drilldownData,
          count_acv: [{ acv: count_acv, count: drilldownData.length }],
        },
      });
    }
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      secondLevelLocation: location,
    });
  };

  defaultColDef = {
    flex: 1,
    cellClass: "cell-wrap-text",
    autoHeight: true,
    sortable: true,
    resizable: true,
    columnDefs: [],
    rowData: [],
    containerWidth: "100%",
    colResizeDefault: "shift",
    getRowHeight: function (params) {
      return params.data.rowHeight;
    },
  };

  gridApi = null;
  columnApi = null;
  params = null;

  gridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.params = params;
  };
  onFirstDataRendered = (params) => {
    if (window.innerWidth < 500) {
      var allColumns = params.columnApi.getAllColumns();
      var allColumnIds = allColumns.map((c) => c.colId);
      this.columnApi.autoSizeColumns(allColumnIds, true);
    }
  };

  onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };
  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      secondLevelLocation: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      secondLevelLocation: "",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    let currentColumn = 0;
    //console.log(this.state)
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Performance against Key Competitors</Typography>
                    <Typography variant='body1'>
                        Explore win / loss performance against key competitors across various time frames.
                    </Typography>
                </Grid> */}
        {addHeaderdescription(
          "Performance against Key Competitors",
          "Explore win / loss performance against key competitors across various time frames."
        )}
        {this.props.funnelFilters !== "" ? (
          <>
            {/* closed dates */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={3}
              lg={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={
                      this.state.closed_year[0] !== "All" ||
                      this.state.closed_month[0] !== "All"
                    }
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.closed_fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-fiscalQuarter-label"
                    >
                      Closed Fiscal Quarter:
                    </InputLabel>
                    <Select
                      data-test="closed_fiscal_quarter"
                      value={this.state.closed_fiscal_quarter}
                      onChange={this.handleChange}
                      name="closed_fiscal_quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.map((y) =>
                          y.type === "closed_fiscal_quarter" ? (
                            <MenuItem
                              key={y["closed_fiscal_quarter"]}
                              value={y["closed_fiscal_quarter"]}
                            >
                              {y["closed_fiscal_quarter"]}
                            </MenuItem>
                          ) : null
                        )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Rep Team */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={9}
              lg={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    data-test="rep"
                    id="combo-box-demo"
                    options={
                      this.props.funnelFilters !== "" &&
                      this.orderRepsByLastName()
                    }
                    getOptionLabel={(option) => option}
                    value={this.state.rep[0]}
                    onChange={this.onRepChange}
                    name="rep"
                    disabled={!this.state.team.includes("All")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: !this.state.rep.includes("All")
                              ? "#4472c4"
                              : "#000",
                          },
                        }}
                        label="Rep:"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    disabled={!this.state.rep.includes("All")}
                  >
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.team.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-team-label"
                    >
                      Team:
                    </InputLabel>
                    <Select
                      data-test="team"
                      value={this.state.team}
                      onChange={this.handleChange}
                      name="team"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) => y.type === "team" && y.Display_Name !== ""
                          )
                          .sort((a, b) =>
                            a.Display_Name < b.Display_Name
                              ? -1
                              : a.Display_Name > b.Display_Name
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y.Display_Name}
                              value={y.Display_Name}
                            >
                              {y.Display_Name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* industry, customer segment, customer type */}
            <Grid
              container
              item
              className={classes.inputFieldContainers}
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={12}
              lg={6}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[this.variableCols.Industry].includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Vertical:"
                        : "Industry:"}
                    </InputLabel>
                    <Select
                      data-test="Industry"
                      value={this.state[this.variableCols.Industry]}
                      onChange={this.handleChange}
                      name={this.variableCols.Industry}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.Industry &&
                              y[this.variableCols.Industry] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.Industry] <
                            b[this.variableCols.Industry]
                              ? -1
                              : a[this.variableCols.Industry] >
                                b[this.variableCols.Industry]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.Industry]}
                              value={y[this.variableCols.Industry]}
                            >
                              {y[this.variableCols.Industry]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerSegment
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      Customer Segment:
                    </InputLabel>
                    <Select
                      data-test="Customer Segment"
                      value={this.state[this.variableCols.CustomerSegment]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerSegment}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerSegment &&
                              y[this.variableCols.CustomerSegment] !== ""
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerSegment]}
                              value={y[this.variableCols.CustomerSegment]}
                            >
                              {y[this.variableCols.CustomerSegment]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state[
                          this.variableCols.CustomerType
                        ].includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.company === "C0003"
                        ? "Region:"
                        : "Customer Type:"}
                    </InputLabel>
                    <Select
                      data-test="Customer Type"
                      value={this.state[this.variableCols.CustomerType]}
                      onChange={this.handleChange}
                      name={this.variableCols.CustomerType}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.CustomerType &&
                              y[this.variableCols.CustomerType] !== ""
                          )
                          .sort((a, b) =>
                            a[this.variableCols.CustomerType] <
                            b[this.variableCols.CustomerType]
                              ? -1
                              : a[this.variableCols.CustomerType] >
                                b[this.variableCols.CustomerType]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y[this.variableCols.CustomerType]}
                              value={y[this.variableCols.CustomerType]}
                            >
                              {y[this.variableCols.CustomerType]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Lead source, opportunity created by, solution cat */}
            <Grid
              container
              item
              className={classes.inputFieldContainers}
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={3}
              lg={2}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.Solution_Cat.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      Solution Category:
                    </InputLabel>
                    <Select
                      data-test="Solution Category"
                      value={this.state.Solution_Cat}
                      onChange={this.handleChange}
                      name="Solution_Cat"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === "Solution_Cat" &&
                              y["Solution_Cat"] !== ""
                          )
                          .sort((a, b) =>
                            a["Solution_Cat"] < b["Solution_Cat"]
                              ? -1
                              : a["Solution_Cat"] > b["Solution_Cat"]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y["Solution_Cat"]}
                              value={y["Solution_Cat"]}
                            >
                              {y["Solution_Cat"]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* ACV and GO */}
            {/* <Grid container item style={{ marginBottom: 10, padding: 10 }} justify='space-evenly' xs={12} lg={4} >
                                 > */}

            <Grid
              container
              item
              className={classes.inputFieldContainers}
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={3}
              lg={2}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.Exclude_Primary_Competitor.includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      Exclude Competitors:
                    </InputLabel>
                    <Select
                      data-test="Exclude Competitors"
                      value={this.state.Exclude_Primary_Competitor}
                      onChange={this.handleChange}
                      name="Exclude_Primary_Competitor"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === "Exclude_Primary_Competitor" &&
                              y["Primary_Competitor"] !== ""
                          )
                          .sort((a, b) =>
                            a["Primary_Competitor"] < b["Primary_Competitor"]
                              ? -1
                              : a["Primary_Competitor"] >
                                b["Primary_Competitor"]
                              ? 1
                              : 0
                          )
                          .map((y) => (
                            <MenuItem
                              key={y["Primary_Competitor"]}
                              value={y["Primary_Competitor"]}
                            >
                              {y["Primary_Competitor"]}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              className={classes.inputFieldContainers}
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={3}
              lg={2}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.Earliest_Lost_Stage.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      Earliest Lost from Stage:
                    </InputLabel>
                    <Select
                      data-test="Earliest lost from Stage"
                      value={this.state.Earliest_Lost_Stage}
                      onChange={this.handleChange}
                      name="Earliest_Lost_Stage"
                    >
                      {/* <MenuItem value={'All'} >All</MenuItem> */}
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters
                          .filter(
                            (y) =>
                              y.type === "Earliest_Lost_Stage" &&
                              y.stage_name !== ""
                          )
                          .map((y) => (
                            <MenuItem key={y.stage_name} value={y.stage_name}>
                              {y.stage_name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {this.props.company === "C0001" && (
              <Grid
                container
                item
                className={classes.inputFieldContainers}
                style={{ padding: 10, marginBottom: 10 }}
                xs={2}
              >
                <Grid item spacing={2} xs={12} container component={Paper}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.deal_category__c
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state[this.variableCols.deal_category__c]}
                        onChange={this.handleChange}
                        name={this.variableCols.deal_category__c}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.deal_category__c &&
                                y[this.variableCols.deal_category__c] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.deal_category__c]}
                                value={y[this.variableCols.deal_category__c]}
                              >
                                {y[this.variableCols.deal_category__c]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company === "C0013" && (
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                // justify="space-between"
                xs={2}
                // md={12}
                // lg={4}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  // style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        // style={{ color: "#4472c4" }}
                        style={{
                          color: !this.state.Opportunity_Record_Type.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Opportunity Record Type:
                      </InputLabel>
                      <Select
                        data-test="Opportunity_Record_Type"
                        value={this.state.Opportunity_Record_Type}
                        onChange={this.handleChange}
                        name="Opportunity_Record_Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters
                            .filter(
                              (y) =>
                                y.type === "Opportunity_Record_Type" &&
                                y.Opportunity_Record_Type !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Opportunity_Record_Type}
                                value={y.Opportunity_Record_Type}
                              >
                                {y.Opportunity_Record_Type}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* Adding Acv Value */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={4}
              md={4}
              lg={4}
            >
              <Grid item spacing={2} xs={12} container component={Paper}>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelFilters !== "" &&
                      this.props.funnelFilters
                        .filter((y) => y.type === "acvFilter" && y.acv !== "")
                        .map((y) => y.acv)[0]
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    data-test="acv"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== null && this.state.acv !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelFilters !== "" &&
                      this.props.funnelFilters
                        .filter((y) => y.type === "acvFilter" && y.acv !== "")
                        .map((y) => y.acv)[0]
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    data-test="acvlt"
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "2px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== null &&
                              this.state.acvlt !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={3}
              lg={2}
            >
              {/* <Grid item spacing={2} xs={8} xl={9} container component={Paper}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ paddingTop: 0, height: "0px" }}
                >
                  <Autocomplete
                    id="free-solo-demo"
                    data-test="ACV"
                    freeSolo
                    options={
                      this.props.funnelFilters !== "" &&
                      this.props.funnelFilters
                        .filter((y) => y.type === "acvFilter" && y.acv !== "")
                        .map((y) => y.acv)[0]
                    }
                    // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                    //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    autoSelect={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: 4 }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== "" && this.state.acv !== null
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid> */}

              <Grid item xs={4} xl={3} container justify="flex-end">
                <Button
                  data-test="Go"
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                  disabled={this.state.acvError !== ""}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        <Grid className={classes.grid} item xs={12}>
          <Grid>
            {this.props.final !== undefined &&
            this.props.topCompetitors !== undefined ? (
              <>
                <Grid container>
                  <Grid className={classes.grid} item xs={12}>
                    <DataVisualizer
                      title={`Performance against Key Competitors`}
                      type={"CompetitorPerformance"}
                      chartType=""
                      chartName="Performance against Key Competitors"
                      winACVMixAnalysisData={this.props.final}
                      topCompetitors={this.props.topCompetitors}
                      oppStatus={this.props.oppStatus}
                      handleChangeLocation={this.handleChangeLocation}
                      quarter={"closed_fiscal_year"}
                      unit="deals"
                      page="Performance against Key Competitors"
                      colors={updatedScheme10}
                      doughnutNoNeeded={true}
                      bar={"bar"}
                      Value_Label={this.props.Value_Label}
                      legends={this.props.topCompetitors
                        .map((d) => d.competitor)
                        .filter(getUniqueValues)
                        .slice(0, 8)}
                      legendComponent={true}
                      tableComponent={
                        <CompetitorPerformanceTable2
                          data={this.props.final}
                          top={this.props.topCompetitors}
                          status={this.props.oppStatus}
                          handleChangeLocation={this.handleChangeLocation}
                        />
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: "2rem" }}>
                  <Grid className={classes.grid} item xs={12}>
                    <DataVisualizer
                      title={`Top 8 known primary competitors  Net Win Trend (Balance of Trade view)`}
                      type={"net"}
                      chartType=""
                      chartName="Performance against Key Competitors"
                      winACVMixAnalysisData={this.props.final}
                      topCompetitors={this.props.topCompetitors}
                      oppStatus={this.props.oppStatus}
                      arra={this.props.computedArr}
                      handleChangeLocation={this.handleChangeLocation}
                      quarter={"closed_fiscal_year"}
                      unit="deals"
                      page="Performance against Key Competitors"
                      colors={updatedScheme10}
                      doughnutNoNeeded={true}
                      bar={"bar"}
                      legendComponent={true}
                      Value_Label={this.props.Value_Label}
                      legends={this.props.topCompetitors
                        .map((d) => d.competitor)
                        .filter(getUniqueValues)
                        .slice(0, 8)}
                      tableComponent={
                        <CompetitorPerformanceTable
                          data={this.props.final}
                          top={this.props.topCompetitors}
                          net={this.props.computedArr}
                          status={this.props.oppStatus}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Card>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="95%"
                  height={230}
                  style={{ margin: "2rem" }}
                />
              </Card>
            )}
          </Grid>
        </Grid>

        {this.state.location === "drilldownOnGraph1" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.headerName}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <CompetitorDrilldown
                Value_Label={this.props.Value_Label}
                header={"Won Opportunities"}
                body={this.state.drilldownOnGraphData}
                company={this.props.company}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.secondLevelLocation === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const {
    competitorAnalysisFilters,
    CompetitorAnalysisData,
    winRateDrilldownData,
    persistentAlert,
    user,
    updatingFunnelData,
    CompetitorColumns,
    ExpectedAcvData,
  } = state.app;

  const { final, topCompetitors, oppStatus } = CompetitorAnalysisData;

  if (CompetitorAnalysisData !== "") {
    const uniqueQuartes = final.map((it) => it.quarter).filter(getUniqueValues);
    const uniqueComp = topCompetitors
      .map((comp) => comp.competitor)
      .slice(0, 8);
    const status = oppStatus.map((st) => st.stage_name);
    var computedArr = [];
    uniqueQuartes.map((qtr) => {
      uniqueComp.map((comp) => {
        const resultArr = final.filter(
          (x) => x.quarter === qtr && x.competitor === comp
        );
        if (resultArr.length === 2) {
          const wonObj = resultArr.filter((it) => it.status === status[0])[0];
          const lostObj = resultArr.filter((it) => it.status === status[1])[0];
          const acvDiff = wonObj.acv - lostObj.acv;
          var resultObj = { ...wonObj, acv: acvDiff };
          computedArr = [...computedArr, resultObj];
        }
        // arra = [...arra, ]
      });
    });
  }

  return {
    funnelFilters: competitorAnalysisFilters,
    CompetitorColumns,
    funnelData: CompetitorAnalysisData,
    drilldownData: winRateDrilldownData,
    company: user.company,
    Value_Label: user.Value_Label,
    updatingFunnelData,
    final,
    topCompetitors,
    computedArr,
    oppStatus,
    ExpectedAcvData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFunnelFilters: () => {
    dispatch({ type: "get_competitor_analysis_filters_request" });
    appService.getCompetitorAnalysisFilters().then(
      (json) => {
        dispatch({ type: "get_competitor_analysis_filters_success", json });
        dispatch({ type: "get_competitor_analysis_data_request" });
        // console.log(json)
        //const stageSequence = json.message.filter(y => y.type === "default_stage" && y["default_stage"] !== '')[0].default_stage
        const defaultStages = json.message
          .filter((item) => item.type === "Earliest_Lost_Stage")
          .map((i) => i.stage_name)[1];
        let filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
          { name: "Earliest_Lost_Stage", value: [defaultStages], string: true },
        ];
        const defaultPrimaryCompititors = json.message.filter(
          (item) => item.type === "defaultPrimaryCompetitors"
        )[0].primaryCompetitors;
        if (defaultPrimaryCompititors.length > 0) {
          filters = [
            ...filters,
            {
              name: "Exclude_Primary_Competitor",
              value: defaultPrimaryCompititors,
              string: true,
            },
          ];
        }

        dispatch({ type: "current_selected_filters", filters });

        appService
          .getCompetitorAnalysisData(json.message.length === 0 ? [] : filters)
          .then(
            (json) => {
              dispatch({ type: "get_competitor_analysis_data_success", json });
              // console.log(json)
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_competitor_analysis_data_failure",
                  error: "Something went wrong",
                });
              else
                dispatch({
                  type: "get_competitor_analysis_data_failure",
                  error,
                });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_win_rate_analysis_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_win_rate_analysis_filters_failure", error });
      }
    );
  },
  getCompetitorAnalysisData: (filters, key) => {
    dispatch({ type: "get_competitor_analysis_data_request" });
    appService.getCompetitorAnalysisData(filters, key).then(
      (json) => {
        dispatch({ type: "get_competitor_analysis_data_success", json });
        // console.log(json)
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_competitor_analysis_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_competitor_analysis_data_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});

const connectedCompetitorPerformance = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CompetitorPerformance));
export default connectedCompetitorPerformance;
