import Skeleton from "@material-ui/lab/Skeleton";
import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import D3OpportunityPyramid2 from "./D3OppotunityPyramid2";
import Legend from "../../RepPerformance/AsOfDate/Legend";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import PyramidDrilldown from "./PyramidDrilldown";
import Drilldown from "../../components/Drilldown/Drilldown";
import OpportunityPyramidSummaryTable from "./OpportunityPyramidSummaryTable";
import { NumberFormatCustom } from "../../components/CustomNumberFormat";
import OppPyramidLegend from "./OppPyramidLegend";
import {
  getDefaultSelectedFiscalQuarters,
  getDefaultSelectedStage,
  getDefaultSelectedStageV2,
  getStageNames,
  getDefaultSalesType,
} from "../../util/customFunctions";
import OpportunityStageChangeTable from "./OpportunityTypeStageChangeTable";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import ExpectedCycleTime from "./ExpectedCycleTime";
import addHeaderdescription from "../../components/HeaderDescription";
import * as d3 from "d3";

// // import QualifiedPipelineTable from './QualifiedPipelineTable';
// import { weekEndingMapping } from './utils';
const styles = (theme) => ({
  root: {},
  content: {},
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  tableClickable: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
    cursor: "pointer",
  },
  rep: {
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  tableHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    color: "inherit",
  },
  repHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "2px 10px",
    width: "20%",
    color: "inherit",
  },
  mainTable: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 1000,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1400,
    },
  },
  mainTable2: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up(1250)]: {
      width: 800,
    },
    [theme.breakpoints.up(1900)]: {
      width: 1200,
    },
  },
});

class OpportunityPyramid extends Component {
  state = {
    location: null,
    closed_fiscal_quarter: ["All"],
    disabled: ["year", "month", "quarter"],
    earliestOpenStage: "",
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Segment: ["All"],
    filtersForDrilldownPcfq: ["All"],
    filtersForDrilldown: [],
    rep: ["All"],
    repTeam: "rep",
    team: ["All"],
    Acct_Vertical: ["All"],
    Solution_Cat: ["All"],
    Acct_Segment: ["All"],
    Cust_Type: ["All"],
    Acct_Industry: ["All"],
    Acct_Size_Segment: ["All"],
    deal_category__c: ["All"],
    cross_sell1__c: ["All"],
    acv: "20,000",
    acvlt: 0,
    acvRadio: "preset",
    acvError: "",
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    Type: ["All"],
  };
  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Smooth scrolling animation
    });
    this.props.getNewQualifiedPipelineFilters(this.props.company);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.OpportunityPyramidData !== "") {
      D3OpportunityPyramid2.destroy(this._rootNode1);
      this._chart1 = D3OpportunityPyramid2.create(this._rootNode1, {
        data: this.props.OpportunityPyramidData.data.sort(
          (a, b) => a.ACV - b.ACV
        ),
        qualified: this.props.OpportunityPyramidData.qualified,
        Value_Label: this.props.Value_Label,
        handleDrilldown: this.handleDrilldown,
      });
    }
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      // default fiscal quarter, stage , acv filters
      //const default_fiscal_quarters = this.props.defaultFilters.filter(item => item.type ===  "default_fiscal_quarters")[0].default_fiscal_quarters
      const stageFilters = this.props.funnelCompareFilters.filter(
        (f) => f.type === "stage_name"
      );
      const stageSequence = getDefaultSelectedStage(stageFilters);

      let stage_names = getStageNames(stageFilters, stageSequence);
      const default_fiscal_quarters = getDefaultSelectedFiscalQuarters(
        this.props.funnelCompareFilters,
        "closed_fiscal_quarter"
      );
      var salesTypes = ["All"];
      var dealsTypes = ["All"];
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter(
            (s) => s.type === "cross_sell1__c"
          ),
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelCompareFilters.filter((s) => s.type === "Type"),
          "Type"
        );
      }
      this.setState({
        ...prevState,
        closed_fiscal_quarter: default_fiscal_quarters,
        filtersForDrilldownPcfq: default_fiscal_quarters,
        earliestOpenStage: stageSequence,
        filtersForDrilldown: [
          { title: "Closed Fiscal Quarter", value: default_fiscal_quarters },
          { title: "Earliest Open Stage", value: stage_names[0] },
          ,
          { title: `${this.props.Value_Label} >=`, value: "20,000" },
        ],
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
      });
    }
  }
  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }

  onResize = async () => {
    D3OpportunityPyramid2.destroy(this._rootNode1);
    this._chart1 = D3OpportunityPyramid2.create(this._rootNode1, {
      data: this.props.OpportunityPyramidData.data.sort(
        (a, b) => a.ACV - b.ACV
      ),
      qualified: this.props.OpportunityPyramidData.qualified,
      Value_Label: this.props.Value_Label,
    });
  };
  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };
  handleChangeEarliestOpenStage = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };
  handleDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      location: location,
    });
    if (location === "drilldownOppId") {
      this.props.getOpenOppData(oppId);
    }
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    this.setState({
      ...this.state,
      location: location,
    });
    if (location === "drilldownOppId") {
      this.props.getOpenOppData(oppId);
    }
  };

  variableCols =
    this.props.company === "C0003"
      ? {
          Industry: "Region_Rollup",
          CustomerSegment: "Acct_Segment",
          CustomerType: "Booking_Type_NL_CS_US",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Acct_Vertical",
          projectedQuarter: "closed_fiscal_quarter",
          Lead_Source: "Lead_Source",
          Opp_Created_By: "Opp_Created_By",
          acv_filter: "acv",
        }
      : {
          Industry: "Acct_Industry",
          CustomerSegment: "Acct_Size_Segment",
          CustomerType: "Cust_Type",
          rep: "rep",
          team: "team",
          Acct_Vertical: "Solution_Cat",
          projectedQuarter: "closed_fiscal_quarter",
          acv_filter: "acv",
          Lead_Source: "Lead_Source",
          Opp_Created_By: "Opp_Created_By",
          deal_category__c: "deal_category__c",
          cross_sell1__c: "cross_sell1__c",
          Type: "Type",
        };

  orderRepsByLastName = () => {
    let temp = this.props.funnelCompareFilters.filter((y) =>
      y.type === "rep" ? y.Full_Name : null
    );
    let reps = temp
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[0]} ${res[1]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  getSelectedRepIDs = () => {
    let idArray = [];
    this.state.rep.map((i) => {
      this.props.funnelCompareFilters.map((j) => {
        if (j.type === "rep" && j.Full_Name === i) {
          idArray = [...idArray, j.UserID];
        }
      });
    });
    return idArray;
  };

  handleChangeLocation = (location, key) => {
    if (location !== "overview") {
      var filters = this.props.currentFilters;
      this.props.getPlotlyDrilldown(filters, key);
      this.setState({ location, key });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
    });
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };
  stepBack = () => {
    this.setState({
      ...this.state,
      location: "drilldownOppId",
    });
    this.props.clearExpectedAcvData();
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    var filters1 = this.props.lkpFilters.filter(
      (item) => item !== "sequence" && item !== "acv"
    );
    let filters = filters1
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string: this.props.funnelCompareFilters.filter(
            (i) => i.type === f && i[f] === this.state[f][0]
          )[0]
            ? this.props.funnelCompareFilters.filter(
                (i) => i.type === f && i[f] === this.state[f][0]
              )[0].string === "Y"
            : true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    let stage_names = getStageNames(
      this.props.funnelCompareFilters.filter((f) => f.type === "stage_name"),
      this.state.earliestOpenStage
    );
    // this.props.funnelCompareFilters.filter(f => f.type === "stage_name").map((i) => {
    //     if (!['',null,undefined].includes(i.stage_name)) {
    //         if (i.sequence >= this.state.earliestOpenStage)
    //             stage_names = [...stage_names, i.stage_name]
    //     }
    // })
    filters = [
      ...filters,
      {
        name: "final_stage_for_closed",
        value: stage_names,
        string: true,
      },
    ];

    if (this.state.acv !== null) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }
    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }

    if (this.state.closed_fiscal_quarter.includes("All")) {
      filters = [
        {
          name: "closed_fiscal_quarter",
          value: this.props.funnelCompareFilters
            .filter((i) => i.type === "closed_fiscal_quarter")
            .map((i) => i.closed_fiscal_quarter),
          string: true,
        },
        ...filters,
      ];
    }

    this.setState({
      ...this.state,
      filtersForDrilldown: this.passFiltersForDrilldown(),
      filtersForDrilldownPcfq: this.state.closed_fiscal_quarter,
    });
    this.props.getNewQualifiedPipelineData(filters);
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];

    if (!this.state.closed_fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Fiscal Quarter",
          value: this.state.closed_fiscal_quarter,
        },
      ];
    }
    let stage_names = [];
    this.props.funnelCompareFilters.map((i) => {
      if (i.sequence !== undefined) {
        if (i.sequence >= this.state.earliestOpenStage)
          stage_names = [...stage_names, i.stage_name];
      }
    });
    filtersArray = [
      ...filtersArray,
      { title: "Earliest Open Stage", value: stage_names[0] },
    ];
    if (!this.state.rep.includes("All")) {
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];
    }
    if (!this.state.team.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];
    }
    if (!this.state.cross_sell1__c.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Sales Type", value: this.state.cross_sell1__c },
      ];
    }
    filtersArray = [
      ...filtersArray,
      { title: `${this.props.Value_Label} >=`, value: this.state.acv },
    ];
    if (!this.state.acvlt !== null && this.state.acvlt !== 0)
      filtersArray = [
        ...filtersArray,
        {
          title: `${this.props.Value_Label} Less Than`,
          value:
            "$ " +
            d3.format(",")(
              this.state.acvlt && typeof this.state.acvlt === "string"
                ? this.state.acvlt.match(/,/g)
                  ? this.state.acvlt.match(/,/g).length === 1
                    ? parseInt(this.state.acvlt) * 1000
                    : this.state.acv.match(/,/g).length === 2
                    ? parseInt(this.state.acvlt) * 1000000
                    : parseInt(this.state.acvlt)
                  : parseInt(this.state.acvlt)
                : this.state.acvlt
            ),
        },
      ];

    if (!this.state.Cust_Type.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Customer Type", value: this.state.Cust_Type },
      ];
    }
    if (!this.state.Acct_Industry.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Industry", value: this.state.Acct_Industry },
      ];
    }
    if (!this.state.Acct_Size_Segment.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Customer segment", value: this.state.Acct_Size_Segment },
      ];
    }
    if (!this.state.Lead_Source.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Lead Source", value: this.state.Lead_Source },
      ];
    }
    if (!this.state.Opp_Created_By.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];
    }
    if (!this.state.Solution_Cat.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];
    }
    if (!this.state.Acct_Vertical.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Account Vertical", value: this.state.Acct_Vertical },
      ];
    }
    if (!this.state.deal_category__c.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Deal category", value: this.state.deal_category__c },
      ];
    }
    if (!this.state.Type.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Deal Type", value: this.state.Type },
      ];
    }

    return filtersArray;
  };

  render() {
    const classes = this.props;
    const ast = (x) => (
      <>
        <span>{x}</span> <span style={{ color: "#ed7d31" }}>&nbsp;*</span>
      </>
    );
    const ast2 = (x) => (
      <>
        <span style={{ color: "#ed7d31" }}>*</span>&nbsp; <span>{x}</span>{" "}
      </>
    );
    return (
      <Grid container className={classes.gridContainer}>
        <Grid
          item
          container
          className={classes.grid}
          style={{ marginBottom: 10, marginLeft: 25 }}
          xs={12}
        >
          {/* <Grid item xs={12} justify="center">
            <Typography variant='h2' align='center'>Cycle Time Analysis</Typography>
          </Grid>
          <Grid item xs={12} justify="left" style={{ margintop: 10 }}>
            <Typography variant='body1' >
            Analyze the cycle time for Won and Lost opportunities by ACV band
            </Typography>
          </Grid> */}
          {addHeaderdescription(
            "Cycle Time Analysis",
            `Analyze the cycle time for Won and Lost opportunities by ${this.props.Value_Label} band`
          )}
          {this.props.funnelCompareFilters !== "" ? (
            <Grid item container xs={12}>
              <Grid container item style={{ padding: 10 }} xs={4} md={4}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  justify="space-around"
                >
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.projectedQuarter
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-fiscalQuarter-label"
                      >
                        Closed Fiscal Quarter:
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.projectedQuarter]}
                        onChange={this.handleChange}
                        name={"closed_fiscal_quarter"}
                        data-test="closed-fiscal-quarter"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters.map((y) =>
                            y.type === this.variableCols.projectedQuarter ? (
                              <MenuItem
                                key={y[this.variableCols.projectedQuarter]}
                                value={y[this.variableCols.projectedQuarter]}
                              >
                                {y[this.variableCols.projectedQuarter]}
                              </MenuItem>
                            ) : null
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} style={{ paddingTop: 10 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-stage-label"
                        style={{ color: "#4472c4", width: 144 }}
                      >
                        Earliest Open Stage:
                      </InputLabel>
                      <Select
                        labelId="select-stage-label"
                        id="select-stage"
                        value={this.state.earliestOpenStage}
                        onChange={this.handleChangeEarliestOpenStage}
                        label="Earliest Open Stage:"
                        name="earliestOpenStage"
                        data-test="earliestOpenStage"
                      >
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters.map(
                            (y) =>
                              y.stage_name && (
                                <MenuItem key={y.sequence} value={y.sequence}>
                                  {y.stage_name}
                                </MenuItem>
                              )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item style={{ padding: 10 }} xs={4}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Autocomplete
                      data-test="rep"
                      id="combo-box-demo"
                      options={
                        this.props.funnelCompareFilters !== "" &&
                        this.orderRepsByLastName()
                      }
                      getOptionLabel={(option) => option}
                      value={this.state.rep[0]}
                      onChange={this.onRepChange}
                      name="rep"
                      disabled={!this.state.team.includes("All")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            style: {
                              color: !this.state.rep.includes("All")
                                ? "#4472c4"
                                : "#000",
                            },
                          }}
                          label="Rep:"
                          variant="standard"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={!this.state.rep.includes("All")}
                    >
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.team.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-team-label"
                      >
                        Team:
                      </InputLabel>
                      <Select
                        data-test="team"
                        value={this.state.team}
                        onChange={this.handleChange}
                        name="team"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) => y.type === "team" && y.Display_Name !== ""
                            )
                            .sort((a, b) =>
                              a.Display_Name < b.Display_Name
                                ? -1
                                : a.Display_Name > b.Display_Name
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y.Display_Name}
                                value={y.Display_Name}
                              >
                                {y.Display_Name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* Adding Acv Value */}
              <Grid container item style={{ padding: 10 }} xs={3}>
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 10 }}
                >
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={
                        this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.acv_filter &&
                              y[this.variableCols.acv_filter] !== ""
                          )
                          .map((y) => y[this.variableCols.acv_filter])[0]
                      }
                      // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                      //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                      onChange={this.onAcvChange}
                      value={this.state.acv}
                      data-test="acv"
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${this.props.Value_Label} >= :`}
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: "2px", marginBottom: "0px" }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.acv !== null && this.state.acv !== 0
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={
                        this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) =>
                              y.type === this.variableCols.acv_filter &&
                              y[this.variableCols.acv_filter] !== ""
                          )
                          .map((y) => y[this.variableCols.acv_filter])[0]
                      }
                      // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                      //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                      onChange={this.onAcvLtChange}
                      value={this.state.acvlt}
                      data-test="acvlt"
                      autoSelect={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${this.props.Value_Label} < :`}
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: "2px", marginBottom: "0px" }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.acvlt !== null &&
                                this.state.acvlt !== 0
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                style={{ marginBottom: 10, padding: 10 }}
                justify="space-between"
                xs={1}
                lg={1}
              >
                <Grid Grid item xs={2} xl={2} justify="flex-end">
                  <Button
                    data-test="go"
                    variant="contained"
                    color="primary"
                    onClick={this.handleGo}
                  >
                    GO
                  </Button>
                </Grid>
              </Grid>

              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={6}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.CustomerType
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        {this.props.company === "C0003"
                          ? "Opportunity Type:"
                          : "Customer Type:"}
                      </InputLabel>
                      <Select
                        data-test="Customer Type"
                        value={this.state[this.variableCols.CustomerType]}
                        onChange={this.handleChange}
                        name={this.variableCols.CustomerType}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.CustomerType &&
                                y[this.variableCols.CustomerType] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.CustomerType] <
                              b[this.variableCols.CustomerType]
                                ? -1
                                : a[this.variableCols.CustomerType] >
                                  b[this.variableCols.CustomerType]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.CustomerType]}
                                value={y[this.variableCols.CustomerType]}
                              >
                                {y[this.variableCols.CustomerType]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Industry
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        {this.props.company === "C0003"
                          ? "Region:"
                          : "Industry:"}
                      </InputLabel>
                      <Select
                        data-test="Industry"
                        value={this.state[this.variableCols.Industry]}
                        onChange={this.handleChange}
                        name={this.variableCols.Industry}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Industry &&
                                y[this.variableCols.Industry] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.Industry] <
                              b[this.variableCols.Industry]
                                ? -1
                                : a[this.variableCols.Industry] >
                                  b[this.variableCols.Industry]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Industry]}
                                value={y[this.variableCols.Industry]}
                              >
                                {y[this.variableCols.Industry]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.CustomerSegment
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        {this.props.company === "C0003"
                          ? "Segment:"
                          : "Customer Segment:"}
                      </InputLabel>
                      <Select
                        data-test="Customer Segment"
                        value={this.state[this.variableCols.CustomerSegment]}
                        onChange={this.handleChange}
                        name={this.variableCols.CustomerSegment}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.CustomerSegment &&
                                y[this.variableCols.CustomerSegment] !== ""
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.CustomerSegment]}
                                value={y[this.variableCols.CustomerSegment]}
                              >
                                {y[this.variableCols.CustomerSegment]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={6}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Lead_Source
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Lead Source:
                      </InputLabel>
                      <Select
                        data-test="Lead Source"
                        value={this.state[this.variableCols.Lead_Source]}
                        onChange={this.handleChange}
                        name={this.variableCols.Lead_Source}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Lead_Source &&
                                y[this.variableCols.Lead_Source] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.Lead_Source] <
                              b[this.variableCols.Lead_Source]
                                ? -1
                                : a[this.variableCols.Lead_Source] >
                                  b[this.variableCols.Lead_Source]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Lead_Source]}
                                value={y[this.variableCols.Lead_Source]}
                              >
                                {y[this.variableCols.Lead_Source]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Opp_Created_By
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        Opportunity Created By:
                      </InputLabel>
                      <Select
                        data-test="Opp_Created_By"
                        value={this.state[this.variableCols.Opp_Created_By]}
                        onChange={this.handleChange}
                        name={this.variableCols.Opp_Created_By}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Opp_Created_By &&
                                y[this.variableCols.Opp_Created_By] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.Opp_Created_By] <
                              b[this.variableCols.Opp_Created_By]
                                ? -1
                                : a[this.variableCols.Opp_Created_By] >
                                  b[this.variableCols.Opp_Created_By]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Opp_Created_By]}
                                value={y[this.variableCols.Opp_Created_By]}
                              >
                                {y[this.variableCols.Opp_Created_By]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state[
                            this.variableCols.Acct_Vertical
                          ].includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-industry-label"
                      >
                        {this.props.company === "C0003"
                          ? "Vertical:"
                          : "Solution Category:"}
                      </InputLabel>
                      <Select
                        value={this.state[this.variableCols.Acct_Vertical]}
                        onChange={this.handleChange}
                        name={this.variableCols.Acct_Vertical}
                        data-test={this.variableCols.Acct_Vertical}
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelCompareFilters !== "" &&
                          this.props.funnelCompareFilters
                            .filter(
                              (y) =>
                                y.type === this.variableCols.Acct_Vertical &&
                                y[this.variableCols.Acct_Vertical] !== ""
                            )
                            .sort((a, b) =>
                              a[this.variableCols.Acct_Vertical] <
                              b[this.variableCols.Acct_Vertical]
                                ? -1
                                : a[this.variableCols.Acct_Vertical] >
                                  b[this.variableCols.Acct_Vertical]
                                ? 1
                                : 0
                            )
                            .map((y) => (
                              <MenuItem
                                key={y[this.variableCols.Acct_Vertical]}
                                value={y[this.variableCols.Acct_Vertical]}
                              >
                                {y[this.variableCols.Acct_Vertical]}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {this.props.company === "C0001" && (
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={2}
                  lg={2}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.deal_category__c
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Deal Category:
                        </InputLabel>
                        <Select
                          data-test="Product_Category"
                          value={this.state[this.variableCols.deal_category__c]}
                          onChange={this.handleChange}
                          name={this.variableCols.deal_category__c}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type ===
                                    this.variableCols.deal_category__c &&
                                  y[this.variableCols.deal_category__c] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.deal_category__c]}
                                  value={y[this.variableCols.deal_category__c]}
                                >
                                  {y[this.variableCols.deal_category__c]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {this.props.company === "C0008" && (
                <Grid
                  container
                  item
                  style={{ padding: 10, paddingTop: 15 }}
                  xs={4}
                >
                  <Grid
                    item
                    spacing={2}
                    xs={12}
                    container
                    component={Paper}
                    style={{ paddingTop: 8 }}
                  >
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[
                              this.variableCols.cross_sell1__c
                            ].includes("All")
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Sales Type:
                        </InputLabel>
                        <Select
                          data-test="Product_Category"
                          value={this.state[this.variableCols.cross_sell1__c]}
                          onChange={this.handleChange}
                          name={this.variableCols.cross_sell1__c}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.cross_sell1__c &&
                                  y[this.variableCols.cross_sell1__c] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.cross_sell1__c]}
                                  value={y[this.variableCols.cross_sell1__c]}
                                >
                                  {y[this.variableCols.cross_sell1__c]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel
                          className={classes.filterLabel}
                          style={{
                            color: !this.state[this.variableCols.Type].includes(
                              "All"
                            )
                              ? "#4472c4"
                              : "#000",
                          }}
                          id="select-region-label"
                        >
                          Deal Type:
                        </InputLabel>
                        <Select
                          data-test="Type"
                          value={this.state[this.variableCols.Type]}
                          onChange={this.handleChange}
                          name={this.variableCols.Type}
                          multiple
                        >
                          <MenuItem value={"All"}>All</MenuItem>
                          {this.props.funnelCompareFilters !== "" &&
                            this.props.funnelCompareFilters
                              .filter(
                                (y) =>
                                  y.type === this.variableCols.Type &&
                                  y[this.variableCols.Type] !== ""
                              )
                              .map((y) => (
                                <MenuItem
                                  key={y[this.variableCols.Type]}
                                  value={y[this.variableCols.Type]}
                                >
                                  {y[this.variableCols.Type]}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid
              item
              container
              component={Card}
              style={{ margin: "1rem" }}
              justify="center"
            >
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            className={classes.grid}
            container
            justify="center"
          >
            <Grid item xs={12} style={{ margin: "2rem" }}>
              <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                <Typography
                  variant="h2"
                  style={{
                    margin: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    padding: "1rem",
                  }}
                >
                  Opportunity Pyramid
                </Typography>
                {this.props.OpportunityPyramidData !== "" ? (
                  <Grid item container xs={12} justify="center">
                    <div
                      style={{
                        justifyContent: "center",
                        marginTop: 20,
                        margin: "auto",
                        width: "95%",
                      }}
                      className="line-container"
                      ref={this._setRef1.bind(this)}
                    />
                    <Grid item xs={12} container style={{ margin: "1rem" }}>
                      <OppPyramidLegend
                        stages={this.props.OpportunityPyramidData.stages}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    style={{ margin: "1rem" }}
                    justify="center"
                  >
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                )}
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.grid}
              style={{ margin: "1rem" }}
            >
              <Card style={{ margin: "auto", width: "100%" }}>
                <Typography
                  variant="h2"
                  style={{
                    margin: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    padding: "0.5rem",
                  }}
                >
                  {" "}
                  {this.props.OpportunityPyramidData.text
                    ? ast(`Summary`)
                    : "Summary"}{" "}
                </Typography>

                {this.props.OpportunityPyramidData.summaryTableData !==
                undefined ? (
                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0.5rem",
                    }}
                  >
                    {this.props.OpportunityPyramidData.summaryTableData !==
                      undefined && (
                      <>
                        <OpportunityPyramidSummaryTable
                          data={
                            this.props.OpportunityPyramidData.summaryTableData
                          }
                          handleChangeLocation={this.handleChangeLocation}
                          text={this.props.OpportunityPyramidData.text}
                          Value_Label={this.props.Value_Label}
                          Win_Rate_Prefix={this.props.Win_Rate_Prefix}
                          winRateHeader={
                            this.props.OpportunityPyramidData.winRateHeader
                          }
                          qualified={
                            this.props.OpportunityPyramidData.qualified
                          }
                        />
                      </>
                    )}
                  </Grid>
                ) : (
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width="95%"
                    height={230}
                    style={{ margin: "2rem" }}
                  />
                )}
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.grid}
              style={{ margin: "1rem" }}
            >
              {this.props.OpportunityPyramidData.meanMedianData !==
                undefined && (
                <Card style={{ margin: "auto", width: "100%" }}>
                  <Typography
                    variant="h2"
                    style={{
                      margin: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      padding: "0.5rem",
                    }}
                  >
                    Time at each stage
                  </Typography>

                  {this.props.OpportunityPyramidData.meanMedianData !==
                  undefined ? (
                    <Grid
                      container
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0.5rem",
                      }}
                    >
                      {this.props.OpportunityPyramidData.meanMedianData !==
                        undefined && (
                        <>
                          <OpportunityStageChangeTable
                            data={
                              this.props.OpportunityPyramidData.meanMedianData
                            }
                            handleChangeLocation={this.handleChangeLocation}
                          />
                        </>
                      )}
                    </Grid>
                  ) : (
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width="95%"
                      height={230}
                      style={{ margin: "2rem" }}
                    />
                  )}
                </Card>
              )}
            </Grid>
          </Grid>
        </Grid>

        {console.log(this.props)}

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.key + " " + "Opportunities"}
              back={this.handleChangeLocationBack}
              filters={
                this.state.key === "Qualified"
                  ? [
                      {
                        title: "Qualified Fiscal Quarter",
                        value: this.state.filtersForDrilldownPcfq,
                      },
                      ...this.state.filtersForDrilldown.filter(
                        (f) => f.title !== "Closed Fiscal Quarter"
                      ),
                    ]
                  : this.state.filtersForDrilldown
              }
            >
              <Drilldown
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                // handleDrilldown={this.handleDrilldown}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
            {console.log(this.props)}
          </Grid>
        )}
        {this.state.location === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Cycle Time Opp Change Events"}
              back={this.handleDrilldown}
              filters={[]}
            >
              <ExpectedCycleTime
                header={this.state.selectedDrilldownStageLabel}
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
            {console.log(this.props.ExpectedAcvData)}
          </Grid>
        )}
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  var {
    OpportunityPyramidData,
    OpportunityPyramidFilters,
    lkpFilters,
    drilldownOnGraphData,
    currentFilters,
    user,
    ExpectedAcvData,
  } = state.app;

  return {
    OpportunityPyramidData,
    funnelCompareFilters: OpportunityPyramidFilters,
    lkpFilters,
    drilldownOnGraphData,
    currentFilters,
    company: user.company,
    Value_Label: user.Value_Label,
    Win_Rate_Prefix: user.Win_Rate_Prefix,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getNewQualifiedPipelineFilters: (company) => {
    dispatch({ type: "get_OpportunityPyramid_filters_request" });
    appService.getPlotlyFilters().then(
      (json) => {
        var lkpFilters = Object.values(
          json.message.lkpFilters.reduce((r, c) => Object.assign(r, c), {})
        );
        json.message = json.message.computedFilters;
        json.lkpFilters = lkpFilters;
        dispatch({ type: "get_OpportunityPyramid_filters_success", json });
        dispatch({ type: "get_OpportunityPyramid_data_request" });
        //default fiscal quarter filter
        //const defaultFiscalQuarters = json.defaultFilters.filter(item => item.type === "default_fiscal_quarters")[0].default_fiscal_quarters
        //default stage filter
        const stageSequence = getDefaultSelectedStage(
          json.message.filter((f) => f.type === "stage_name")
        );
        const stageFilters = json.message.filter(
          (f) => f.type === "stage_name"
        );
        //   console.log(stageFilters, x, stageSequence)
        //   const defaultStages = json.message.filter(item => item.sequence >= stageSequence && ![null,'',undefined].includes(item['stage_name'])).map(i => i.stage_name)
        const defaultStages = getStageNames(stageFilters, stageSequence);
        //default acv filter
        let filters = [
          {
            name: "closed_fiscal_quarter",
            value: getDefaultSelectedFiscalQuarters(
              json.message,
              "closed_fiscal_quarter"
            ),
            string: true,
          },
          {
            name: "final_stage_for_closed",
            string: true,
            value: defaultStages,
          },
          { name: "acv", string: false, value: 20000 },
        ];
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "cross_sell1__c"),
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.filter((s) => s.type === "Type"),
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }
        dispatch({ type: "current_selected_filters", filters });
        appService.getOppotunityPyramidData(filters).then(
          (json) => {
            dispatch({ type: "get_OpportunityPyramid_data_success", json });
          },
          (error) => {
            if (typeof error === "object")
              dispatch({
                type: "get_OpportunityPyramid_data_failure",
                error: "Something went wrong",
              });
            else
              dispatch({ type: "get_OpportunityPyramid_data_failure", error });
          }
        );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_OpportunityPyramid_filters_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_OpportunityPyramid_filters_failure", error });
      }
    );
  },
  getPlotlyDrilldown: (filters, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getPyramidDrilldown(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1];
        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });
        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getNewQualifiedPipelineData: (filters1) => {
    const filters = filters1.filter((x) => x.name !== "closed_fiscal_year");
    dispatch({ type: "get_OpportunityPyramid_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService.getOppotunityPyramidData(filters).then(
      (json) => {
        dispatch({ type: "get_OpportunityPyramid_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_OpportunityPyramid_data_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_OpportunityPyramid_data_failure", error });
      }
    );
  },
});
const connectedOpportunityPyramid = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OpportunityPyramid));
export default connectedOpportunityPyramid;
