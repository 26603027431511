import { capitalize } from "@material-ui/core";
import * as d3 from "d3"
import { computeDomainLimits } from "../../Dashboard/OpenOpportunities/utils";
import { updatedScheme10 } from "../../util/chartColorScheme";
import { convertToThousands, getUniqueValues, replaceHyphens,countOrACVFormatter } from "../../util/customFunctions";
import { formatDataValues } from "../../util/dataFormatter";
import { getMixKey } from "../../util/generateHeadings";
import './D3WonStackBar.scss'
const D3WonStackBar = {};

D3WonStackBar.create = (el, config) => {

    if (config.data) {
        const handleDrilldown = (drilldownOn, filters, meta1, meta2, meta3) => {
            if ((config.type === "Overall_Health") || (config.type === "label") || (config.type === "Account_Segment")) {
                // console.log('non cust succes')
                config.handleChangeLocation(drilldownOn, filters, meta1, meta2, meta3)
            } else {
                config.handleChangeLocation(drilldownOn, filters, meta1, meta3)
            }


        }
        const totalRow = config.uniqueQuarters.map(qtr => ({ [config.quarter]: qtr, [config.unit]: config.data.filter(item => item[config.quarter] === qtr).map(item => item[config.unit]).reduce((a, b) => a + b, 0) }))

        config.data = config.data

        const totalDeals = config.data.map(item => item[`${config.unit === undefined ? 'deals' : `${config.unit}`}`]).reduce((a, b) => a + b, 0)
        const uniqueQuarters = config.uniqueQuarters

        const uniqueStages = config.sortedUniqueStages

        let totalByQuarter = uniqueQuarters.map(quarter => (config.data.filter(item => item[config.quarter] === quarter).map(item => item[config.unit]).reduce((a, b) => a + b, 0)))



        config.margin = {
            top: 50,
            right: 70,
            bottom: 40,
            left: 120
        };

        config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width - config.margin.left - config.margin.right : 100;
        config.height = 500 - config.margin.top - config.margin.bottom;
        // set x axis
        var x = d3.scaleBand()
            .rangeRound([0, config.width])
            .padding(0.6)
            .align(0.1);
        // set y scale
        var y = d3.scaleLinear()
            .range([config.height, 0]);


        var color = config.color !== undefined ? config.color : d3.scaleOrdinal(updatedScheme10);

        x.domain(uniqueQuarters);
        const {upper_bound,lower_bound} = computeDomainLimits(0,d3.max(totalByQuarter, d => d) !== 0 ? d3.max(totalByQuarter, d => d) : 100 * 1000)
        y.domain([0, upper_bound])
        // y.domain([0, d3.max(totalByQuarter, d => d) !== 0 ? d3.max(totalByQuarter, d => d) : 100])

        color.domain(uniqueStages);


        var chartContainer = d3.select(el)
            .append('svg')
            .attr('width', config.width + config.margin.left + config.margin.right)
            .attr('height', config.height + config.margin.top + config.margin.bottom + 30)
        var chart = chartContainer
            .append('g')
            .attr("transform", "translate(" + config.margin.left + "," + config.margin.top + ")");



        // var legend = d3.select(el).append("div").attr('class', 'legend')

        let dataUpdated = []


        uniqueQuarters.map(qtr => {

            const temp = config.data.filter(item => item[config.quarter] === qtr).reduce(
                (obj, item) =>
                    Object.assign(obj, { [item[config.type]]: item[config.unit] }), {}
            );



            const tempUpdated = {
                ...temp,
                [config.quarter]: qtr
            }
            dataUpdated.push(tempUpdated)

        })

        var dataset = d3.stack()
            .keys(uniqueStages)
            (dataUpdated)



        function make_y_gridlines() {
            return d3.axisLeft(y).ticks(5)
        }
        // add the Y gridlines
        chart
            .append("g")
            .attr("class", "grid")
            .attr("z-index", -1)
            .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));
        chart.append("g")
            // .attr("class", "axis axis--x")
            .style('font-size', "0.75rem")
            .attr("transform", "translate(" + ((0)) + "," + (config.height) + ")")
            .call(d3.axisBottom(x)
                .ticks(2)

            ).selectAll("text")
            .style("text-anchor", d => uniqueQuarters.length >= 11 ? "end" : null)
            .attr("dx", d => uniqueQuarters.length >= 11 ? "-.4rem" : null)
            .attr("dy", d => uniqueQuarters.length >= 11 ? ".7rem" : "0.6rem")
            .attr("transform", d => uniqueQuarters.length >= 11 ? "rotate(-45)" : "rotate(0)");
        chart.append("g").call(d3.axisLeft(y).tickFormat(d => `${(d)}`.toUpperCase()));


        chart.append("g")
            .call(d3.axisLeft(y));


        let key;
        chart.append("g")
            .selectAll("g")
            .data(dataset)
            .enter().append("g")
            .attr("fill", function (d) { return color(d.key); })
            .on("mouseover", d => key = d.key)
            .style("opacity", 0.9)
            .selectAll("rect")
            .data(function (d) { return d; })
            .enter().append("rect")
            .attr("data-test", `${getMixKey(config.type)}-stackbar`)
            .style("cursor", config.isDrilldown === false ? "" : "pointer")
            .on("click", d => config.handleChangeLocation("drilldownOnGraph", { row: config.type ==='qfq' ? config.data.filter(i=>i.qfq === key && i.closed_fiscal_quarter === d.data[config.quarter])[0].qualified_fiscal_quarter : key, cell: d.data[config.quarter] }, config.type === "Acct_Size_Segment" ? "Acct_Size_Segment" : "Acct_Segment_Rollup", (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.quarter))
            .attr("x", function (d, i) { return x(d.data[config.quarter]); })
            .attr("y", function (d) { return y(d[1]); })
            .attr("height", function (d) { return y(d[0]) - y(d[1]); })
            .attr("width", x.bandwidth())

        chart.append("g")
            .selectAll("g")
            .data(dataset)
            .enter().append("g")
            .selectAll("text")
            .on("mouseover", d => key = d.key)
            .data(function (d) { return d; })
            .enter().append("text")
            .attr("fill", d => config.type === "Overall_Health" ? "black" : "white")
            .style("font-size", '0.8rem')
            .attr('text-anchor', 'middle')
            .style("cursor", config.isDrilldown === false ? "" : "pointer")
            .on("click", d => config.handleChangeLocation("drilldownOnGraph", { row: config.type ==='qfq' ? config.data.filter(i=>i.qfq === key && i.closed_fiscal_quarter === d.data[config.quarter])[0].qualified_fiscal_quarter : key, cell: d.data[config.quarter] },config.type === "Acct_Size_Segment" ? "Acct_Size_Segment" : "Acct_Segment_Rollup", (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.quarter))
            .attr("x", function (d) { return x(d.data[config.quarter]) + x.bandwidth() / 2; })
            .attr("y", function (d) { return y(d[1]) + ((y(d[0]) - y(d[1])) / 2); })
            .text((d, i) => `${y(d[0]) - y(d[1]) >= 27 && x.bandwidth() > 12 ? `${countOrACVFormatter((d[1] - d[0]),false)}` : ''}`)
        chart.append("g")
            .selectAll("g")
            .data(dataset)
            .enter().append("g")
            .selectAll("text")
            .on("mouseover", d => key = d.key)
            .data(function (d) { return d; })
            .enter().append("text")
            .attr("fill", d => config.type === "Overall_Health" ? "black" : "white")
            .style("font-size", '0.7rem')
            .attr('text-anchor', 'middle')
            .style("cursor", config.isDrilldown === false ? "" : "pointer")
            .on("click", d => config.handleChangeLocation("drilldownOnGraph", { row:config.type ==='qfq' ? config.data.filter(i=>i.qfq === key && i.closed_fiscal_quarter === d.data[config.quarter])[0].qualified_fiscal_quarter : key, cell: d.data[config.quarter] },config.type === "Acct_Size_Segment" ? "Acct_Size_Segment" : "Acct_Segment_Rollup", (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.quarter))
            .attr("x", function (d) { return x(d.data[config.quarter]) + x.bandwidth() / 2; })
            .attr("y", function (d) { return y(d[1]) + ((y(d[0]) - y(d[1])) / 2) + 12; })
            .text((d, i) => `${y(d[0]) - y(d[1]) >= 27 && x.bandwidth() > 12 && x.bandwidth() > 30 ? `(${Math.round(((d[1] - d[0]) / totalByQuarter[i]) * 100)}%)` : ''}`)

        chart.append('g')
            .selectAll('g')
            .data(totalRow)
            .enter().append('text')
            .attr('text-anchor', 'middle')
            .style("font-size", '0.85rem')
            .attr("x", d => x(d[config.quarter]) + (x.bandwidth() / 2))
            .attr("y", d => y(d[config.unit]) - 5)
            .text(d => `${countOrACVFormatter( d[config.unit],false)}`)
            .style("cursor", config.isDrilldown === false ? "" : "pointer")
            .on("click", d => {
                handleDrilldown("drilldownOnGraph", { row: (config.type === "Overall_Health") || (config.type === "label") || (config.type === "Account_Segment")  ? uniqueStages : "Total", cell: d[config.quarter] }, config.type === "Acct_Size_Segment" ? "Acct_Size_Segment" : "Total", (config.type === "Overall_Health") || (config.type === "label") || (config.type === "Account_Segment") ? "rawValsTotal" : config.type, config.quarter)

            })


        chart
            .append("text")
            .attr("dy", "1em")
            .attr("font-size", "0.85rem")
            // .attr("font-weight", "500")
            .attr("y", config.margin.left - 200)
            .attr("x", -(config.height / 2))
            .attr("transform", "rotate(-90)") // although axis is rotated, text is not
            .attr("fill", "#00000")
            .text(`${config.unit === "SFDC" ? "# of Accounts" : capitalize(config.unit)}`);
        chart
            .append("text")
            .attr("y", config.height + 45)
            .attr("x", (config.width / 2) - 90)
            .attr("dy", "1em")
            .attr("class", "axis-label")
            .attr("font-size", "0.85rem")
            // .attr("font-weight", "500")
            .attr("fill", "#000000")
            .text(config.quarter.split("_").map(item => capitalize(item)).join(" "));






    }

}
D3WonStackBar.destroy = (el) => {
    // Cleaning code here
    d3.select(el).selectAll("svg").remove();
    d3.select('.tooltip').remove();
    d3.select('.legend-flex').remove();
};
export default D3WonStackBar;