import {
  Button,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appService } from "../../App/app.service";
import AverageDealSizeTable from "../../components/AverageDealSizeTable";
import AverageDealSizeDealsTable from "../../components/AverageDealSizeDealsTable";
import * as d3 from "d3";

import D3WonStackBar from "../../components/D3Components/D3WonStackBar";
import {
  getCurrentFiscalQuarter,
  getNPriorQuarters,
  getPriorQuarter,
  nearestCompletedFiscalQuarter,
} from "../../util/fiscalQuarterFunctions";
import D3LineChart from "./D3LineChart";
import { deSnake, getLineChartFormatedData } from "../../util/dataFormatter";
import { getUniqueValues, _isNaN } from "../../util/customFunctions";
import { customSortedSegmentsForLegends } from "../../util/customSortings";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import FiscalQuarterFilter from "../../Filters/FiscalQuarterFilter";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import { updatedScheme10 } from "../../util/chartColorScheme";
import RegionFilter from "../../Filters/Region_Filter";
import OpportunityType from "../../Filters/OpportunityType";
import Acct_SegmentFilter from "../../Filters/Acct_SegmentFilter";
import FilterContainer from "../../Filters/FilterContainer";
import YearQuarterFilterCombo from "../../Filters/YearQuarterFilterCombo";
import Legend from "../../RepPerformance/AsOfDate/Legend";
import Drilldown from "../../components/Drilldown/DrilldownV4";
import ExpectedAcvPage from "../NewWaterFall/ExpectedAcvPage";
import addHeaderdescription from "../../components/HeaderDescription";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NumberFormat from "react-number-format";

const styles = (theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% - ${(window.innerWidth - 288) * 0.1125 + 86}px)`,
    },
  },
  grid: {
    width: "95%",
    margin: "auto",
    marginTop: "1rem",
  },
});
class WinACVMixAnalysis extends Component {
  filters = [
    "closed_fiscal_quarter",
    "Region_Rollup",
    "Booking_Type_NL_CS_US",
    "Acct_Segment",
    "closed_fiscal_year",
    "ACV_Range",
  ];
  state = {
    closed_fiscal_quarter:
      this.props.funnelCompareFilters === ""
        ? ["All"]
        : [
            this.props.funnelCompareFilters.filter(
              (item) => item.type === "current_fiscal_quarter"
            )[0].current_fiscal_quarter,
            ...getNPriorQuarters(
              this.props.funnelCompareFilters.filter(
                (item) => item.type === "current_fiscal_quarter"
              )[0].current_fiscal_quarter,
              7
            ),
          ],
    closed_fiscal_year: ["All"],
    disabled: ["year", "month", "quarter"],
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Segment: ["All"],
    filter_Region_Rollup: ["All"],
    filter_Booking_Type_NL_CS_US: ["All"],
    filter_Acct_Segment: ["All"],
    acv: 0,
    acvlt: 0,
    ACV_Range: ["All"],
  };
  componentDidMount() {
    this.props.getfunnelCompareFilters(this.props.company);
    window.addEventListener("resize", this.onResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onAcvLtChange = (event, value) => {
    this.setState({
      ...this.state,
      acvlt: value,
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.funnelCompareFilters !== "" &&
      prevProps.funnelCompareFilters === ""
    ) {
      const currentQuarter = this.props.funnelCompareFilters.filter(
        (item) => item.type === "current_fiscal_quarter"
      )[0].current_fiscal_quarter;

      // const currentQuarter = getCurrentFiscalQuarter()
      // console.log(getPriorQuarter(currentQuarter),getPriorQuarter(getPriorQuarter(currentQuarter)))

      this.setState({
        ...prevState,
        closed_fiscal_quarter: [
          currentQuarter,
          ...getNPriorQuarters(currentQuarter, 7),
        ],
      });
    }
    if (this.props.winACVMixAnalysisData !== "") {
      let quarter =
        this.props.winACVMixAnalysisData.filter(
          (item) => item.type === "closed_fiscal_year"
        )[0] !== undefined
          ? "closed_fiscal_year"
          : "closed_fiscal_quarter";
      D3WonStackBar.destroy(this._rootNode1);
      D3LineChart.destroy(this._rootNode2);
      D3LineChart.destroy(this._rootNode3);
      const uniqueQuarters = this.props.winACVMixAnalysisData[0]
        .map((item) => item[quarter])
        .filter(getUniqueValues);
      // this._chart1 = D3WonStackBar.create(this._rootNode1, { data: this.props.winACVMixAnalysisData, quarter: 'closed_fiscal_quarter', handleChangeLocation: this.handleChangeLocation, uniqueQuarters: uniqueQuarters.length === 0 ? this.state.closed_fiscal_quarter : uniqueQuarters })
      this._chart2 = D3LineChart.create(this._rootNode2, {
        data: getLineChartFormatedData(this.props.winACVMixAnalysisData, "acv"),
        type: "acv",
        uniqueQuarters:
          uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
        quarter,
      });
      this._chart3 = D3LineChart.create(this._rootNode3, {
        data: getLineChartFormatedData(
          this.props.winACVMixAnalysisData,
          "duration"
        ),
        type: "duration",
        uniqueQuarters:
          uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
        quarter,
      });
    }
  }
  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? "All"
                : this.getSelectedRepIDs()
              : this.state[f],
          string:
            f === "team"
              ? true
              : this.props.funnelCompareFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0]
              ? this.props.funnelCompareFilters.filter(
                  (i) => i.type === f && i[f] === this.state[f][0]
                )[0].string === "Y"
              : true,
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));

    if (this.state.acv !== null && this.state.acv !== 0) {
      filters = [
        ...filters,
        {
          name: "acv",
          value:
            this.state.acv && typeof this.state.acv === "string"
              ? this.state.acv.match(/,/g)
                ? this.state.acv.match(/,/g).length === 1
                  ? parseInt(this.state.acv) * 1000
                  : this.state.acv.match(/,/g).length === 2
                  ? parseInt(this.state.acv) * 1000000
                  : parseInt(this.state.acv)
                : parseInt(this.state.acv)
              : this.state.acv,
          string: false,
        },
      ];
    }

    if (this.state.acvlt !== null && this.state.acvlt !== 0) {
      filters = [
        ...filters,
        {
          name: "acvlt",
          value:
            this.state.acvlt && typeof this.state.acvlt === "string"
              ? this.state.acvlt.match(/,/g)
                ? this.state.acvlt.match(/,/g).length === 1
                  ? parseInt(this.state.acvlt) * 1000
                  : this.state.acvlt.match(/,/g).length === 2
                  ? parseInt(this.state.acvlt) * 1000000
                  : parseInt(this.state.acvlt)
                : parseInt(this.state.acvlt)
              : this.state.acvlt,
          string: false,
        },
      ];
    }
    this.setState({
      ...this.state,
      filter_Region_Rollup: this.passFilterForDrilldownRegion(),
      filter_Acct_Segment: this.passFilterForDrilldownAccount(),
      filter_Booking_Type_NL_CS_US: this.passFilterForDrilldownBook(),
    });
    this.props.getWinACVMixAnalysisData(
      this.props.company,
      this.state.activeTimeFilter === "closed_fiscal_year"
        ? "closed_fiscal_year"
        : "",
      filters
    );
  };
  _setRef1(componentNode) {
    this._rootNode1 = componentNode;
  }
  _setRef2(componentNode) {
    this._rootNode2 = componentNode;
  }
  _setRef3(componentNode) {
    this._rootNode3 = componentNode;
  }
  onResize = async () => {
    D3WonStackBar.destroy(this._rootNode1);
    D3LineChart.destroy(this._rootNode2);
    D3LineChart.destroy(this._rootNode3);
    let quarter =
      this.props.winACVMixAnalysisData.filter(
        (item) => item.type === "closed_fiscal_year"
      )[0] !== undefined
        ? "closed_fiscal_year"
        : "closed_fiscal_quarter";

    const uniqueQuarters = this.props.winACVMixAnalysisData[0]
      .map((item) => item[quarter])
      .filter(getUniqueValues);
    // this._chart1 = D3WonStackBar.create(this._rootNode1, { data: this.props.winACVMixAnalysisData, quarter: 'closed_fiscal_quarter', handleChangeLocation: this.handleChangeLocation, uniqueQuarters: uniqueQuarters.length === 0 ? this.state.closed_fiscal_quarter : uniqueQuarters })
    this._chart2 = D3LineChart.create(this._rootNode2, {
      data: getLineChartFormatedData(this.props.winACVMixAnalysisData, "acv"),
      type: "acv",
      uniqueQuarters:
        uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
      quarter,
    });
    this._chart3 = D3LineChart.create(this._rootNode3, {
      data: getLineChartFormatedData(
        this.props.winACVMixAnalysisData,
        "duration"
      ),
      type: "duration",
      uniqueQuarters:
        uniqueQuarters.length === 0 ? this.state[quarter] : uniqueQuarters,
      quarter,
    });
  };
  passFilterForDrilldownRegion = () => {
    if (this.state.Region_Rollup) {
      return this.state.Region_Rollup;
    }
  };

  passFilterForDrilldownAccount = () => {
    if (this.state.Acct_Segment) {
      return this.state.Acct_Segment;
    }
  };

  passFilterForDrilldownBook = () => {
    if (this.state.Booking_Type_NL_CS_US) {
      return this.state.Booking_Type_NL_CS_US;
    }
  };

  handleChange = (e) => {
    console.log("e.target_name", e.target.name);
    console.log("e.target_Value", e.target.value);

    if (
      e.target.name === "closed_fiscal_year" &&
      (e.target.value.length === 0 || e.target.value.includes("All"))
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
      });
    } else if (e.target.name === "closed_fiscal_year") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_year",
      });
    } else if (e.target.name === "closed_fiscal_quarter") {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        activeTimeFilter: "closed_fiscal_quarter",
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };
  handleChangeLocationBack = (location, rep, drilldown) => {
    this.setState({
      ...this.state,
      location: location,
      drilldownTable: "",
    });
    this.props.setTable("");
  };
  handleChangeLocation = (location, data, type, quarter) => {
    if (location !== "overview") {
      this.props.setTable("WinAcv");

      const uniqueStages = this.props.winACVMixAnalysisData[0]
        .map((item) => item.Acct_Segment)
        .filter(getUniqueValues);
      let localFilters = this.props.currentFilters;
      let filters =
        this.state.filter_Region_Rollup.includes("All") &&
        this.state.filter_Booking_Type_NL_CS_US.includes("All")
          ? [
              {
                name: quarter,
                value:
                  typeof data.cell === "string" || typeof data.cell === "number"
                    ? [`${data.cell}`]
                    : data.cell,
              },
              {
                name: type !== "Total" ? type : "Acct_Segment",
                value: data.row !== "Total" ? [data.row] : uniqueStages,
              },
            ]
          : !this.state.filter_Region_Rollup.includes("All") &&
            !this.state.filter_Booking_Type_NL_CS_US.includes("All")
          ? [
              {
                name: quarter,
                value:
                  typeof data.cell === "string" || typeof data.cell === "number"
                    ? [`${data.cell}`]
                    : data.cell,
              },
              {
                name: type !== "Total" ? type : "Acct_Segment",
                value: data.row !== "Total" ? [data.row] : uniqueStages,
              },
              { name: "Region_Rollup", value: this.state.filter_Region_Rollup },
              {
                name: "Booking_Type_NL_CS_US",
                value: this.state.filter_Booking_Type_NL_CS_US,
              },
            ]
          : !this.state.filter_Booking_Type_NL_CS_US.includes("All")
          ? [
              {
                name: quarter,
                value:
                  typeof data.cell === "string" || typeof data.cell === "number"
                    ? [`${data.cell}`]
                    : data.cell,
              },
              {
                name: type !== "Total" ? type : "Acct_Segment",
                value: data.row !== "Total" ? [data.row] : uniqueStages,
              },
              {
                name: "Booking_Type_NL_CS_US",
                value: this.state.filter_Booking_Type_NL_CS_US,
              },
            ]
          : [
              {
                name: quarter,
                value:
                  typeof data.cell === "string" || typeof data.cell === "number"
                    ? [`${data.cell}`]
                    : data.cell,
              },
              {
                name: type !== "Total" ? type : "Acct_Segment",
                value: data.row !== "Total" ? [data.row] : uniqueStages,
              },
              { name: "Region_Rollup", value: this.state.filter_Region_Rollup },
            ];
      filters = [...filters, ...localFilters];
      this.props.getWinACVDrilldown(filters, {
        company: this.props.company,
        closed: "closed",
      });

      this.setState({
        ...this.state,
        location: location,
        filtersForDrilldown: [
          {
            title: "Account Segment",
            value: data.row !== "Total" ? [data.row] : "All",
          },
          {
            title: deSnake(quarter),
            value:
              typeof data.cell === "string" || typeof data.cell === "number"
                ? data.cell
                : "All",
          },
          {
            title: "Opportunity Type",
            value: this.state.filter_Booking_Type_NL_CS_US.includes("All")
              ? "All"
              : this.state.filter_Booking_Type_NL_CS_US,
          },
          {
            title: "Region",
            value: this.state.filter_Region_Rollup.includes("All")
              ? "All"
              : this.state.filter_Region_Rollup,
          },
          {
            title: "Segment",
            value: this.state.filter_Acct_Segment.includes("All")
              ? "All"
              : this.state.filter_Acct_Segment,
          },
        ],
      });
    } else {
      this.props.clearDrilldownData();
    }
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      location: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      location: "drilldownOnGraph",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;

    console.log("Funnel_Filters", this.props.funnelCompareFilters);

    console.log("Acv_Range_Filter", this.state.ACV_Range);
    return (
      <Grid container style={{ position: "relative" }}>
        {/* <Grid className={classes.grid} item xs={12} style={{ marginBottom: 10 }}>
                    <Typography variant='h2' align='center' style={{ marginBottom: 20 }}>Average Deal Size and Cycle Time</Typography>
                    <Typography variant='body1'>
                        Explore trends in average deal sizes and cycle times across regions and opportunity types.
                    </Typography>
                </Grid> */}
        {addHeaderdescription(
          "Average Deal Size and Cycle Time",
          "Explore trends in average deal sizes and cycle times across regions and opportunity types."
        )}
        {this.props.funnelCompareFilters !== "" ? (
          <Grid item container xs={12}>
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              xs={12}
            >
              <Grid xs={4} style={{ paddingTop: "0.8rem" }}>
                <YearQuarterFilterCombo
                  activeTimeFilter={this.state.activeTimeFilter}
                  closedFiscalQuarterState={this.state.closed_fiscal_quarter}
                  closedFiscalYear={this.state.closed_fiscal_year}
                  handleChange={this.handleChange}
                  funnelCompareFilters={this.props.funnelCompareFilters}
                />
              </Grid>

              <Grid container item style={{ padding: 10 }} xs={6}>
                <Grid item container xs={12}>
                  <FilterContainer
                    filters={[
                      <OpportunityType
                        Booking_Type_NL_CS_US={this.state.Booking_Type_NL_CS_US}
                        handleChange={this.handleChange}
                        funnelCompareFilters={this.props.funnelCompareFilters}
                      />,
                      <RegionFilter
                        Region_RollupValue={this.state.Region_Rollup}
                        handleChange={this.handleChange}
                        funnelCompareFilters={this.props.funnelCompareFilters}
                      />,
                      <Acct_SegmentFilter
                        selectedAccount_Segment={this.state.Acct_Segment}
                        handleChange={this.handleChange}
                        funnelCompareFilters={this.props.funnelCompareFilters}
                      />,
                    ]}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
            {/* Adding Acv Value */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={12}
              md={12}
              lg={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: "0.8rem" }}
              >
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((y) => y.type === "acv" && y.acv !== "")
                        .map((y) => d3.format(",")(String(y.acv))) // Convert to string
                    }
                    onChange={this.onAcvChange}
                    value={this.state.acv}
                    data-test="acv"
                    autoSelect={true}
                    // getOptionLabel={(option) => option} // Ensure options are treated as strings
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} >= :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acv !== null && this.state.acv !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={
                      this.props.funnelCompareFilters !== "" &&
                      this.props.funnelCompareFilters
                        .filter((y) => y.type === "acv" && y.acv !== "")
                        .map((y) => d3.format(",")(String(y.acv))) // Convert to string
                    }
                    onChange={this.onAcvLtChange}
                    value={this.state.acvlt}
                    data-test="acvlt"
                    autoSelect={true}
                    // getOptionLabel={(option) => option} // Ensure options are treated as strings
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${this.props.Value_Label} < :`}
                        margin="normal"
                        variant="standard"
                        style={{ marginTop: "0px", marginBottom: "0px" }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color:
                              this.state.acvlt !== null &&
                              this.state.acvlt !== 0
                                ? "#4472c4"
                                : "#000",
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputComponent: NumberFormatCustom,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Adding ACV Range Filter */}
            <Grid
              container
              item
              style={{ marginBottom: 10, padding: 10 }}
              justify="space-between"
              xs={2}
              md={2}
              lg={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: "0.6rem" }}
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.ACV_Range.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-industry-label"
                    >
                      {this.props.Value_Label} Range
                    </InputLabel>
                    <Select
                      value={this.state.ACV_Range}
                      onChange={this.handleChange}
                      name={"ACV_Range"}
                      data-test={this.state.ACV_Range}
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelCompareFilters !== "" &&
                        this.props.funnelCompareFilters
                          .filter(
                            (y) => y.type === "ACV_Range" && y.ACV_Range !== ""
                          )
                          .map((y) => (
                            <MenuItem key={y.ACV_Range} value={y.ACV_Range}>
                              {y.ACV_Range}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}
        {/* Content */}
        <Grid className={classes.grid} item xs={12}>
          <Grid>
            {this.props.winACVMixAnalysisData !== "" ? (
              <>
                {this.props.winACVMixAnalysisData.filter(
                  (item) => item.type === "closed_fiscal_year"
                )[0] !== undefined ? (
                  <>
                    <Grid container>
                      <Grid className={classes.grid} item xs={12}>
                        <DataVisualizer
                          title={`Number of deals Won`}
                          type={"Acct_Segment_Rollup"}
                          chartType="number"
                          chartName="AverageDealSize"
                          winACVMixAnalysisData={
                            this.props.winACVMixAnalysisData[1]
                          }
                          handleChangeLocation={this.handleChangeLocation}
                          quarter={"closed_fiscal_year"}
                          unit="deals"
                          page="AverageDealSize"
                          colors={updatedScheme10}
                          doughnutNoNeeded={true}
                          tableComponent={
                            <AverageDealSizeDealsTable
                              type={"deals"}
                              quarter={"closed_fiscal_year"}
                              winAcvData={this.props.winACVMixAnalysisData}
                              isAcv={false}
                              handleChangeLocation={this.handleChangeLocation}
                            />
                          }
                        />
                      </Grid>
                      <Grid className={classes.grid} item xs={12}>
                        <Grid container component={Card} raised>
                          <Grid
                            container
                            item
                            style={{ padding: "1rem" }}
                            xs={12}
                          >
                            <Grid item xs={12}>
                              <Typography
                                align="center"
                                variant="h2"
                                style={{ margin: "1rem" }}
                              >
                                Average deal size for Won deals
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <div
                              style={{
                                justifyContent: "center",
                                marginTop: 20,
                                margin: "auto",
                                width: "95%",
                              }}
                              id="svgContainer"
                              className="svg-container"
                              ref={this._setRef2.bind(this)}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              margin: "2rem",
                              marginTop: "0rem",
                              paddingRight: "5rem",
                            }}
                            justify="flex-start"
                            container
                          >
                            <Legend
                              square={15}
                              legends={customSortedSegmentsForLegends}
                            />
                            <AverageDealSizeTable
                              subtitle="Avg. Deal Size"
                              type={"acv"}
                              quarter={"closed_fiscal_year"}
                              winAcvData={this.props.winACVMixAnalysisData}
                              isAcv={true}
                              handleChangeLocation={this.handleChangeLocation}
                              dataTest={"avg deal size for won deals"}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid className={classes.grid} item xs={12}>
                        <Grid container component={Card} raised>
                          <Grid
                            container
                            item
                            style={{ padding: "1rem" }}
                            xs={12}
                          >
                            <Grid item xs={12}>
                              <Typography
                                align="center"
                                variant="h2"
                                style={{ margin: "1rem" }}
                              >
                                Average cycle time (Qualifying to Win days) for
                                Won deals
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <div
                              style={{
                                justifyContent: "center",
                                marginTop: 20,
                                margin: "auto",
                                width: "95%",
                              }}
                              id="svgContainer3"
                              className="svg-container"
                              ref={this._setRef3.bind(this)}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              margin: "2rem",
                              marginTop: "0rem",
                              paddingRight: "5rem",
                            }}
                            justify="flex-start"
                            container
                          >
                            <Legend
                              square={15}
                              legends={customSortedSegmentsForLegends}
                            />
                            <AverageDealSizeTable
                              subtitle={
                                <>
                                  <p>Avg.</p>
                                  <p>E to W Cycle Time</p>
                                </>
                              }
                              type={"duration"}
                              quarter={"closed_fiscal_year"}
                              winAcvData={this.props.winACVMixAnalysisData}
                              isAcv={false}
                              handleChangeLocation={this.handleChangeLocation}
                              dataTest={"avg deal size cycle time"}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container>
                      <Grid className={classes.grid} item xs={12}>
                        <DataVisualizer
                          title={`Number of deals Won`}
                          type={"Acct_Segment_Rollup"}
                          chartType="number"
                          chartName="AverageDealSize"
                          winACVMixAnalysisData={
                            this.props.winACVMixAnalysisData[1]
                          }
                          handleChangeLocation={this.handleChangeLocation}
                          quarter={"closed_fiscal_quarter"}
                          unit="deals"
                          page="AverageDealSize"
                          colors={updatedScheme10}
                          doughnutNoNeeded={true}
                          tableComponent={
                            <AverageDealSizeDealsTable
                              type={"deals"}
                              quarter={"closed_fiscal_quarter"}
                              winAcvData={this.props.winACVMixAnalysisData}
                              isAcv={false}
                              handleChangeLocation={this.handleChangeLocation}
                            />
                          }
                        />
                      </Grid>
                      <Grid className={classes.grid} item xs={12}>
                        <Grid container component={Card} raised>
                          <Grid
                            container
                            item
                            style={{ padding: "1rem" }}
                            xs={12}
                          >
                            <Grid item xs={12}>
                              <Typography
                                align="center"
                                variant="h2"
                                style={{ margin: "1rem" }}
                              >
                                Average deal size for Won deals
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <div
                              style={{
                                justifyContent: "center",
                                marginTop: 20,
                                margin: "auto",
                                width: "95%",
                              }}
                              id="svgContainer"
                              className="svg-container"
                              ref={this._setRef2.bind(this)}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              margin: "2rem",
                              marginTop: "0rem",
                              paddingRight: "5rem",
                            }}
                            justify="flex-start"
                            container
                          >
                            <Legend
                              square={15}
                              legends={customSortedSegmentsForLegends}
                            />
                            <AverageDealSizeTable
                              subtitle="Avg. Deal Size"
                              type={"acv"}
                              quarter={"closed_fiscal_quarter"}
                              winAcvData={this.props.winACVMixAnalysisData}
                              isAcv={true}
                              handleChangeLocation={this.handleChangeLocation}
                              dataTest={"avg deal size for won deals"}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid className={classes.grid} item xs={12}>
                        <Grid container component={Card} raised>
                          <Grid
                            container
                            item
                            style={{ padding: "1rem" }}
                            xs={12}
                          >
                            <Grid item xs={12}>
                              <Typography
                                align="center"
                                variant="h2"
                                style={{ margin: "1rem" }}
                              >
                                Average cycle time (Qualifying to Win days) for
                                Won deals
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <div
                              style={{
                                justifyContent: "center",
                                marginTop: 20,
                                margin: "auto",
                                width: "95%",
                              }}
                              id="svgContainer3"
                              className="svg-container"
                              ref={this._setRef3.bind(this)}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              margin: "2rem",
                              marginTop: "0rem",
                              paddingRight: "5rem",
                            }}
                            justify="flex-start"
                            container
                          >
                            <Legend
                              square={15}
                              legends={customSortedSegmentsForLegends}
                            />
                            <AverageDealSizeTable
                              subtitle={
                                <>
                                  <p>Avg.</p>
                                  <p>Qualifying to Win Cycle Time</p>
                                </>
                              }
                              type={"duration"}
                              quarter={"closed_fiscal_quarter"}
                              winAcvData={this.props.winACVMixAnalysisData}
                              isAcv={false}
                              handleChangeLocation={this.handleChangeLocation}
                              dataTest={"avg deal size cycle time"}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <Card>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width="95%"
                  height={230}
                  style={{ margin: "2rem" }}
                />
              </Card>
            )}
          </Grid>
        </Grid>
        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Won Opportunities"}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                body={this.props.drilldownOnGraphData}
                Value_Label={this.props.Value_Label}
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.location === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                Value_Label={this.props.Value_Label}
                body={this.props.ExpectedAcvData}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    NewWinAcvMixFilters,
    NewWinAcvMixData,
    user,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelCompareFilters: NewWinAcvMixFilters,
    winACVMixAnalysisData: NewWinAcvMixData,
    company: user.company,
    Value_Label: user.Value_Label,
    drilldownOnGraphData,
    currentFilters,
    ExpectedAcvData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setTable: (drilldownTable) => {
    dispatch({ type: "update_table", table: drilldownTable });
  },
  getWinACVDrilldown: (filters, meta) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getWinAcvAvgDealSizeDrilldown(filters, meta).then(
      (json) => {
        let tempOpportunities = json.message.recordsets[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            ...json.message,
            count_acv: json.message.recordsets[0],
            opportunities: tempOpportunities,
            columns: json.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getfunnelCompareFilters: (company) => {
    dispatch({ type: "get_new_win_acv_mix_analysis_filters_request" });
    appService
      .getChartFilters(
        company === "C0003" ? "C0003.CHID00000015" : `${company}.CHID0000009`
      )
      .then(
        (json) => {
          dispatch({
            type: "get_new_win_acv_mix_analysis_filters_success",
            json,
          });
          dispatch({ type: "get_new_win_acv_mix_analysis_data_request" });
          const currentQuarter = json.message.filter(
            (item) => item.type === "current_fiscal_quarter"
          )[0].current_fiscal_quarter;
          const filters = [
            {
              name: "closed_fiscal_quarter",
              value: [currentQuarter, ...getNPriorQuarters(currentQuarter, 7)],
              string: true,
            },
          ];
          dispatch({ type: "current_selected_filters", filters });
          appService
            .getChartData(
              company ? `${company}.CHID00000015` : "C0000.CHID00000015",
              json.message.length === 0 ? [] : filters
            )
            .then(
              (json) => {
                dispatch({
                  type: "get_new_win_acv_mix_analysis_data_success",
                  json,
                });
              },
              (error) => {
                if (typeof error === "object")
                  dispatch({
                    type: "get_new_win_acv_mix_analysis_data_failure",
                    error: "Something went wrong",
                  });
                else
                  dispatch({
                    type: "get_new_win_acv_mix_analysis_data_failure",
                    error,
                  });
              }
            );
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_new_win_acv_mix_analysis_filters_failure",
              error: "Something went wrong",
            });
          else
            dispatch({
              type: "get_new_win_acv_mix_analysis_filters_failure",
              error,
            });
        }
      );
  },
  getWinACVMixAnalysisData: (company, key, filters = []) => {
    dispatch({ type: "get_new_win_acv_mix_analysis_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService
      .getChartData(
        company ? `${company}.CHID00000015` : "C0000.CHID00000015",
        filters,
        key
      )
      .then(
        (json) => {
          dispatch({ type: "get_new_win_acv_mix_analysis_data_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_new_win_acv_mix_analysis_data_failure",
              error: "Something went wrong",
            });
          else
            dispatch({
              type: "get_new_win_acv_mix_analysis_data_failure",
              error,
            });
        }
      );
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const connectedWinACVMixAnalysis = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WinACVMixAnalysis));
export default connectedWinACVMixAnalysis;
