import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  Divider,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper,
  TextField,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { appService } from "../../App/app.service";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Skeleton from "@material-ui/lab/Skeleton";
import { styles } from "../../EnhancedInsights/NewFunnel/FunnelStyles";
import Loading from "../../components/Loading";
import * as d3 from "d3";
import NumberFormat from "react-number-format";
import SingleLevelDrilldown from "../../components/Drilldown/SingleLevelDrilldown";
import {
  _isNaN,
  getDefaultSelectedFiscalQuarters,
  getDefaultACV,
  dateFormatterMomentV4,
  getDefaultSalesType,
  getDefaultThreshold,
  getUniqueValues,
} from "../../util/customFunctions";
import Drilldown from "../../components/Drilldown/DrilldownWithSLD";
import ExpectedAcvPage from "../../EnhancedInsights/NewWaterFall/ExpectedAcvPage";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PipelineTable from "./PipelineTable";
import LowMIdHighTable from "./LowMidHighTable";
import DeviationTable from "./DeviationTable";
import NextQuarterConversionTable from "./NextQuarterConversionTable";
import ExpectedLowMidHighTable from "./ExpectedLowMidHighTable";
import DataVisualizer from "../../components/ChartBuilder/DataVisualizer";
import GrowthModel from "./GrowthModel";
import addHeaderdescription from "../../components/HeaderDescription";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "15rem",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
    background: "white",
    border: "0.5px solid lightgrey",
  },
});

class ConversionEfficiencyBySource extends React.Component {
  state = {
    Opportunity_Record_Type: ["All"],
    closed_year: ["All"],
    closed_month: ["All"],
    quarter: ["All"],
    rep: ["All"],
    fiscal_quarter: ["All"],
    Cust_Type: ["All"],
    Region_Rollup: ["All"],
    Booking_Type_NL_CS_US: ["All"],
    Acct_Industry: ["All"],
    Lead_Source: ["All"],
    Opp_Created_By: ["All"],
    Solution_Cat: ["All"],
    custType: ["All"],
    deal_category__c: ["All"],
    Acct_Segment: ["All"],
    Acct_Size_Segment: ["All"],
    cross_sell1__c: ["All"],
    team: ["All"],
    Type: ["All"],
    timeFrame: "quarter",
    disabled: [],
    acvPreset: "All",
    acv: null,
    displayacv: 0,
    acvRadio: "preset",
    acvError: "",
    location: "overview",
    defaultColDef: {
      flex: 1,
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
    },
    selectedDrilldownStage: "",
    lastStage: false,
    activeFilters: ["All"],

    filtersForDrilldown: [],
    toggleValue: "AcvSort",
    THRESHOLDS_VALUES: "",
    exclude_large_deals: "Yes",
    offset_days: 0,
    tab: "Summary",
    // tab: "Growth-Modeling"
  };

  // filters = ['closed_year', 'closed_month', 'quarter', 'rep', 'fiscal_quarter', 'team', 'Cust_Type', 'Acct_Industry', 'custType', "Booking_Type_NL_CS_US", 'Acct_Size_Segment','Acct_Segment', 'Lead_Source', 'Opp_Created_By', 'Solution_Cat', 'Region_Rollup','deal_category__c']

  filters = [
    "fiscal_quarter",
    "cross_sell1__c",
    "rep",
    "team",
    "Booking_Type_NL_CS_US",
    "Cust_Type",
    "Type",
    "Solution_Cat",
    "offset_days",
    "Opportunity_Record_Type",
    "deal_category__c",
  ];

  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  componentDidMount() {
    this.props.getFunnelFilters(this.props.company);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.funnelFilters !== "" && prevProps.funnelFilters === "") {
      const defaultFQuarter = getDefaultSelectedFiscalQuarters(
        this.props.funnelFilters.fiscalQuarters,
        "fiscal_quarter"
      );
      var defaultQuarter =
        defaultFQuarter.length > 0 ? defaultFQuarter : ["All"];

      let excludDeals = "Yes";
      if (this.props.funnelFilters.exclude_large_deals) {
        excludDeals = ["All", "No"].includes(
          getDefaultSalesType(
            this.props.funnelFilters.exclude_large_deals,
            "exclude_large_deals"
          )[0]
        )
          ? "No"
          : "Yes";
        // this.setState({
        //   ...prevState,
        //   [oppTypeColumn]:oppTypes,
        //   oppType : oppTypeColumn,
        // })
      }
      let thresholdValues = 0;
      if (excludDeals === "Yes") {
        thresholdValues =
          getDefaultThreshold(this.props.funnelFilters.large_deal_threshold) > 0
            ? d3.format(",")(
                String(
                  getDefaultThreshold(
                    this.props.funnelFilters.large_deal_threshold
                  )
                )
              )
            : getDefaultThreshold(
                this.props.funnelFilters.large_deal_threshold
              );
      }

      let salesTypes = ["All"];
      let dealsTypes = ["All"];
      if (this.props.company === "C0008") {
        salesTypes = getDefaultSalesType(
          this.props.funnelFilters.salesType,
          "cross_sell1__c"
        );
        dealsTypes = getDefaultSalesType(
          this.props.funnelFilters.dealType,
          "Type"
        );
      }
      this.setState({
        ...prevState,
        fiscal_quarter: defaultQuarter,
        filtersForDrilldown: [
          {
            title: "Fiscal Quarter",
            value: defaultQuarter,
          },
          ...this.state.filtersForDrilldown.filter(
            (f) => f.title !== "Fiscal Quarter"
          ),
        ],
        cross_sell1__c: salesTypes,
        Type: dealsTypes,
        activeFilters: [{ name: "fiscal_quarter", value: defaultQuarter }],
        exclude_large_deals: excludDeals,
        THRESHOLDS_VALUES: thresholdValues,
        offset_days: 0,
      });
    }
  }

  handleChangeTab = (e) => {
    this.handleGo();
    console.log(e.target.value);
    this.setState({
      ...this.state,
      tab: e.target.value,
    });
  };

  handleChange = (e) => {
    if (
      e.target.name === "closed_year" &&
      e.target.value.filter((v) => v === "All").length > 0
    ) {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
        closed_month: ["All"],
        quarter: ["All"],
      });
    } else {
      this.setState({
        ...this.state,
        [e.target.name]:
          e.target.name === "rep"
            ? [e.target.value]
            : e.target.value.length === 0
            ? ["All"]
            : this.state[e.target.name].length === 1 &&
              this.state[e.target.name].includes("All")
            ? e.target.value.filter((v) => v !== "All").length > 0
              ? e.target.value.filter((v) => v !== "All")
              : ["All"]
            : e.target.value.includes("All")
            ? ["All"]
            : e.target.value,
      });
    }
  };

  handleChangeOffsetDays = (e) => {
    this.setState({
      ...this.state,
      offset_days: e.target.value,
    });
  };

  handleGo = () => {
    localStorage.removeItem("skygeni-auto-update");
    let filters = this.filters
      .map((f) => {
        return {
          name: f,
          value:
            f === "rep"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.reps.filter(
                        (r) => r.Full_Name === s
                      )[0].UserID
                  )
              : f === "team"
              ? this.state[f].includes("All")
                ? this.state[f]
                : this.state[f].map(
                    (s) =>
                      this.props.funnelFilters.teams.filter(
                        (r) => r.Display_Name === s
                      )[0].Display_Name
                  )
              : this.state[f],
        };
      })
      .filter(
        (f) =>
          (!Array.isArray(f.value) && f.value !== "All") ||
          !f.value.includes("All")
      )
      .filter((f) => !this.state.disabled.includes(f.name));
    if (this.state.fiscal_quarter.includes("All")) {
      filters = [
        ...filters,
        {
          name: "fiscal_quarter",
          value: this.props.funnelFilters.fiscalQuarters.map(
            (s) => s.fiscal_quarter
          ),
        },
      ];
    }

    if (this.state.offset_days === 0) {
      filters = filters.filter((f) => f.name !== "offset_days");
    }

    if (this.state.exclude_large_deals === "Yes") {
      filters = [
        ...filters,
        {
          name: "threshold",
          value:
            this.state.THRESHOLDS_VALUES &&
            typeof this.state.THRESHOLDS_VALUES === "string"
              ? this.state.THRESHOLDS_VALUES.match(/,/g)
                ? this.state.THRESHOLDS_VALUES.match(/,/g).length === 1
                  ? parseInt(this.state.THRESHOLDS_VALUES) * 1000
                  : this.state.THRESHOLDS_VALUES.match(/,/g).length === 2
                  ? parseInt(this.state.THRESHOLDS_VALUES) * 1000000
                  : parseInt(this.state.THRESHOLDS_VALUES)
                : parseInt(this.state.THRESHOLDS_VALUES)
              : this.state.THRESHOLDS_VALUES,
          string: false,
        },
      ];
    }
    // if (this.state.acv !== null && this.state.acv !== 0) {
    //   filters = [
    //     ...filters,
    //     {
    //         name: 'acv',
    //         value: this.state.acv && typeof (this.state.acv) === 'string' ?

    //           this.state.acv.match(/,/g) ?
    //             this.state.acv.match(/,/g).length === 1 ?
    //               parseInt(this.state.acv) * 1000
    //               :
    //               this.state.acv.match(/,/g).length === 2 ?
    //                 parseInt(this.state.acv) * 1000000
    //                 :
    //                 parseInt(this.state.acv)
    //             :
    //             parseInt(this.state.acv)

    //           : this.state.acv,
    //         string: false
    //       }
    //   ]
    // }
    this.props.getFunnelDataNew(filters);
    this.setState({
      ...this.state,
      activeFilters: filters,
      filtersForDrilldown: this.passFiltersForDrilldown(),
    });
  };

  orderRepsByLastName = () => {
    let reps = this.props.funnelFilters.reps
      .map((r) => r.Full_Name)
      .map((rep) => {
        const res = rep.split(" ", 2);
        return {
          firstName: res[0],
          lastName: res[1],
          fullName: rep,
          sortName: `${res[1]} ${res[0]}`,
        };
      });
    function compare(a, b) {
      if (a.sortName < b.sortName) {
        return -1;
      }
      if (a.sortName > b.sortName) {
        return 1;
      }
      return 0;
    }
    reps.sort(compare);
    reps = [
      { firstName: "All", lastName: "All", fullName: "All", sortName: "All" },
      ...reps,
    ];
    const menuItems = reps.map((rep, index) => {
      return rep.fullName;
    });
    return menuItems;
  };

  handleChangeTimeFrame = (e) => {
    this.setState({
      ...this.state,
      timeFrame: e.target.value,
      disabled:
        e.target.value === "custom"
          ? [
              ...this.state.disabled.filter(
                (f) =>
                  f !== "closed_year" && f !== "closed_month" && f !== "quarter"
              ),
              "closed_fiscal_quarter",
            ]
          : [
              ...this.state.disabled.filter(
                (f) => f !== "closed_fiscal_quarter"
              ),
              "closed_year",
              "closed_month",
              "quarter",
            ],
    });
  };

  handleChangeLocation = (location, obj, key, tableHeader) => {
    console.log(tableHeader);
    if (location !== "overview") {
      let keyName = key;
      var stage = obj.row;
      var quarter = obj.cell;
      console.log(obj.row);
      if (typeof obj.row === "string") {
        keyName =
          obj.row.split(":")[0] === "B"
            ? "Won"
            : obj.row.split(":")[0] === "C"
            ? "PCDNotin"
            : obj.row.split(":")[0] === "C"
            ? "Actual"
            : "Total";
        stage = this.props.funnelData.uniqueNames.filter((c) => c !== "Total");
        quarter = typeof obj.cell === "string" ? [obj.cell] : obj.cell;
      }
      var drilldownFilters = [
        { title: "Fiscal Quarter", value: quarter },
        { title: "Stage", value: stage },
      ];

      var filters = this.props.currentFilters.filter(
        (s) => s.name !== "fiscal_quarter"
      );
      filters = [
        ...filters,
        {
          name: "fiscal_quarter",
          value:
            quarter[0] === "Total"
              ? this.props.currentFilters
                  .filter((s) => s.name === "fiscal_quarter")
                  .map((f) => f.value)[0]
                  .filter(Boolean)
              : quarter,
        },
        { name: "Stage_At_Time_T", value: stage },
      ];
      var keyNames = ["Fiscal Quarter", "Stage"];
      var filtersForDrilldown = this.state.filtersForDrilldown.filter(
        (i) => !keyNames.includes(i.title)
      );

      this.setState({
        ...this.state,
        location: location,
        header:
          typeof obj.row === "string"
            ? "Source of Qualified pipe that went to Won"
            : tableHeader,
        drildownACVName: keyName,
        filtersForDrilldown: [...filtersForDrilldown, ...drilldownFilters],
      });
      this.props.getConversionEffecienceyDrilldown(filters, keyName);
    } else {
      this.props.clearDrilldownData();
    }
  };

  passFiltersForDrilldown = () => {
    let filtersArray = [];
    if (!this.state.fiscal_quarter.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Fiscal Quarter", value: this.state.fiscal_quarter.sort() },
      ];
    }
    if (!this.state.closed_year.includes("All")) {
      filtersArray = [
        ...filtersArray,
        { title: "Closed Calendar Year", value: this.state.closed_year.sort() },
      ];
    }
    if (!this.state.closed_month.includes("All")) {
      filtersArray = [
        ...filtersArray,
        {
          title: "Closed Calendar Month",
          value: this.state.closed_month
            .sort((a, b) => (a < b ? -1 : 1))
            .map((i) => this.months[i - 1]),
        },
      ];
    }
    if (!this.state.rep.includes("All"))
      filtersArray = [...filtersArray, { title: "Rep", value: this.state.rep }];

    if (!this.state.team.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Team", value: this.state.team },
      ];

    if (!this.state.Booking_Type_NL_CS_US.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Type", value: this.state.Booking_Type_NL_CS_US },
      ];

    // if (!this.state.acv !== null && this.state.acv !== 0)
    //   filtersArray = [...filtersArray, {
    //     title: "ACV Greater Than", value: '$ ' + d3.format(",")(this.state.acv && typeof (this.state.acv) === 'string' ?

    //       this.state.acv.match(/,/g) ?
    //         this.state.acv.match(/,/g).length === 1 ?
    //           parseInt(this.state.acv) * 1000
    //           :
    //           this.state.acv.match(/,/g).length === 2 ?
    //             parseInt(this.state.acv) * 1000000
    //             :
    //             parseInt(this.state.acv)
    //         :
    //         parseInt(this.state.acv)

    //       : this.state.acv)
    //   }]

    if (!this.state.Acct_Industry.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Vertical" : "Industry",
          value: this.state.Acct_Industry,
        },
      ];
    if (!this.state.Acct_Size_Segment.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: "Customer Segment",
          value:
            this.props.company === "C0003"
              ? this.state.Acct_Industry
              : this.state.Acct_Size_Segment,
        },
      ];
    if (!this.state.Cust_Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        {
          title: this.props.company === "C0003" ? "Region" : "Customer Type",
          value:
            this.props.company === "C0003"
              ? this.state.Region_Rollup
              : this.state.Cust_Type,
        },
      ];
    if (!this.state.Lead_Source.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Lead Source", value: this.state.Lead_Source },
      ];
    if (!this.state.Opp_Created_By.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Opportunity Created By", value: this.state.Opp_Created_By },
      ];

    if (!this.state.deal_category__c.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Deal Category", value: this.state.deal_category__c },
      ];

    if (!this.state.Type.includes("All"))
      filtersArray = [
        ...filtersArray,
        { title: "Deal Type", value: this.state.Type },
      ];

    if (
      this.props.company !== "C0003" &&
      !this.state.Solution_Cat.includes("All")
    )
      filtersArray = [
        ...filtersArray,
        { title: "Solution Category", value: this.state.Solution_Cat },
      ];

    return filtersArray;
  };

  onRepChange = (event, value) => {
    this.setState({
      ...this.state,
      rep: [value ? value : "All"],
    });
  };

  onAcvChange = (event, value) => {
    this.setState({
      ...this.state,
      acv: value,
    });
  };

  onThresholdChange = (event, value) => {
    console.log(
      this.state.exclude_large_deals,
      this.state.THRESHOLDS_VALUES,
      value
    );
    this.setState({
      ...this.state,
      THRESHOLDS_VALUES: [null, ""].includes(value)
        ? d3.format(",")(
            String(
              getDefaultThreshold(this.props.funnelFilters.large_deal_threshold)
            )
          )
        : value,
    });
  };

  handleChangeFilter = (e) => {
    // console.log(e)
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "rep"
          ? [e.target.value]
          : e.target.value.length === 0
          ? ["All"]
          : this.state[e.target.name].length === 1 &&
            this.state[e.target.name].includes("All")
          ? e.target.value.filter((v) => v !== "All").length > 0
            ? e.target.value.filter((v) => v !== "All")
            : ["All"]
          : e.target.value.includes("All")
          ? ["All"]
          : e.target.value,
    });
  };

  handleChangeLocationBack = (location) => {
    this.setState({
      ...this.state,
      location: "",
    });
  };

  handleSecondLevelDrilldown = (location, oppId) => {
    console.log(oppId);
    this.setState({
      ...this.state,
      location: location,
    });
    this.props.getOpenOppData(oppId);
  };

  stepBack = () => {
    this.setState({
      ...this.state,
      location: "drilldownOnGraph",
    });
    this.props.clearExpectedAcvData();
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ position: "relative" }}>
        {/* Caption and description */}
        {/* <Grid item style={{ marginBottom: 10 }} xs={12}>
          <Typography variant="h2" align="center" style={{ marginBottom: 20 }}>
            Conversion Efficiency by Source + Growth Modeling
          </Typography>
          <Typography variant="body1">
            Examine how much of the various sources of qualified pipe were
            converted to Won in each time frame.
          </Typography>
        </Grid> */}
        {addHeaderdescription(
          "Conversion Efficiency by Source + Growth Modeling",
          "Examine how much of the various sources of qualified pipe were converted to Won in each time frame."
        )}

        {this.props.funnelFilters ? (
          <>
            {/* dates */}
            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={2}
              md={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={2} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-fiscalQuarter-label"
                      style={{
                        color: !this.state.fiscal_quarter.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Fiscal Quarter:
                    </InputLabel>
                    <Select
                      data-test="closed-fiscal-quarter"
                      labelId="select-fiscalQuarter-label"
                      id="select-fiscalQuarter"
                      value={this.state.fiscal_quarter}
                      onChange={this.handleChange}
                      label="Fiscal Quarter"
                      name="fiscal_quarter"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.fiscalQuarters
                          .filter((item) => item.fiscal_quarter !== "")
                          .map((y) => (
                            <MenuItem
                              key={y.fiscal_quarter}
                              value={y.fiscal_quarter}
                            >
                              {y.fiscal_quarter}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={2}
              md={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={2} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-offset_days-label"
                      style={{ color: "#4472c4" }}
                    >
                      Offset days from qtr. start:
                    </InputLabel>
                    <Select
                      data-test="offset_days"
                      labelId="soffset_days-label"
                      id="offset_days"
                      value={this.state.offset_days}
                      onChange={this.handleChangeOffsetDays}
                      label="Offset days from qtr. start"
                      name="offset_days"
                    >
                      <MenuItem value={0}>0</MenuItem>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                        (y) => (
                          <MenuItem key={y} value={y}>
                            {y}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={4}
              md={4}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                justify="space-around"
              >
                <Grid item xs={6} style={{ paddingBottom: "4px" }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.exclude_large_deals.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-exclude-label"
                    >
                      Exclude large deals:
                    </InputLabel>
                    <Select
                      value={this.state.exclude_large_deals}
                      onChange={this.handleChangeFilter}
                      name="exclude_large_deals"
                      data-test="exclude_large_deals"
                    >
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.exclude_large_deals.map(
                          (y) => (
                            <MenuItem
                              key={y["exclude_large_deals"]}
                              value={y["exclude_large_deals"]}
                            >
                              {y["exclude_large_deals"]}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} style={{ paddingBottom: "4px" }}>
                  {console.log(
                    this.state.exclude_large_deals,
                    this.state.THRESHOLDS_VALUES
                  )}
                  {this.state.exclude_large_deals === "Yes" ? (
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={
                        this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.large_deal_threshold.map((d) =>
                          d3.format(",")(String(d.THRESHOLDS_VALUES))
                        )
                      }
                      // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                      //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                      onChange={this.onThresholdChange}
                      value={
                        [null, ""].includes(this.state.THRESHOLDS_VALUES)
                          ? d3.format(",")(
                              String(
                                getDefaultThreshold(
                                  this.props.funnelFilters.large_deal_threshold
                                )
                              )
                            )
                          : this.state.THRESHOLDS_VALUES
                      }
                      // value={this.state.acv}
                      data-test="THRESHOLDS_VALUES"
                      autoSelect={true}
                      disabled={this.state.exclude_large_deals !== "Yes"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Threshold > :"
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4 }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.THRESHOLDS_VALUES !== null &&
                                this.state.THRESHOLDS_VALUES !== 0 &&
                                this.state.exclude_large_deals === "Yes"
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={[]}
                      // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                      //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                      onChange={this.onThresholdChange}
                      value={
                        this.state.exclude_large_deals !== "Yes"
                          ? ""
                          : this.state.THRESHOLDS_VALUES
                      }
                      // value={this.state.acv}
                      data-test="THRESHOLDS_VALUES"
                      autoSelect={true}
                      disabled={this.state.exclude_large_deals !== "Yes"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Threshold > :"
                          margin="normal"
                          variant="standard"
                          style={{ marginTop: 4 }}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              color:
                                this.state.THRESHOLDS_VALUES !== null &&
                                this.state.THRESHOLDS_VALUES !== 0 &&
                                this.state.exclude_large_deals === "Yes"
                                  ? "#4472c4"
                                  : "#000",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            inputComponent: NumberFormatCustom,
                            startAdornment: (
                              <>
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* rep & team */}
            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              xs={4}
              md={4}
            >
              {this.props.funnelFilters !== "" ? (
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={
                        this.props.funnelFilters !== "" &&
                        this.orderRepsByLastName()
                      }
                      getOptionLabel={(option) => option}
                      value={this.state.rep[0]}
                      onChange={this.onRepChange}
                      name="rep"
                      data-test="rep"
                      disabled={!this.state.team.includes("All")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rep:"
                          variant="standard"
                          InputLabelProps={{
                            style: {
                              color: !this.state.rep.includes("All")
                                ? "#4472c4"
                                : "#000",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      disabled={!this.state.rep.includes("All")}
                    >
                      <InputLabel
                        id="select-team-label"
                        style={{
                          color: !this.state.team.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Team:
                      </InputLabel>
                      <Select
                        labelId="select-team-label"
                        id="select-team"
                        value={this.state.team}
                        onChange={this.handleChange}
                        label="Team"
                        data-test="team"
                        name="team"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.teams
                            .map((t) => t.Display_Name)
                            .sort()
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                <Grid item container component={Paper} justify="center">
                  {" "}
                  <Skeleton
                    variant="text"
                    animation="wave"
                    height={40}
                    width={290}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              justify="space-between"
              xs={2}
              md={2}
            >
              <Grid
                item
                spacing={2}
                xs={12}
                container
                component={Paper}
                style={{ paddingTop: 8 }}
              >
                <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      id="select-Acct_Industry-label"
                      style={{
                        color: !this.state.Cust_Type.includes("All")
                          ? "#4472c4"
                          : "#000",
                      }}
                    >
                      Customer Type:
                    </InputLabel>
                    <Select
                      labelId="select-Acct_Industry-label"
                      id="select-Acct_Industry"
                      value={this.state.Cust_Type}
                      onChange={this.handleChange}
                      name="Cust_Type"
                      data-test="Cust_Type"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters !== "" &&
                        this.props.funnelFilters.regions
                          .sort()
                          .filter((item) => item !== "")
                          .map((y) => (
                            <MenuItem key={y} value={y}>
                              {y}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {this.props.company === "C0001" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.deal_category__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Category:
                      </InputLabel>
                      <Select
                        data-test="Product_Category"
                        value={this.state.deal_category__c}
                        onChange={this.handleChange}
                        name="deal_category__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.dealCat.map((y) => (
                            <MenuItem
                              key={y.deal_category__c}
                              value={y.deal_category__c}
                            >
                              {y.deal_category__c}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company === "C0003" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Booking_Type_NL_CS_US.includes(
                            "All"
                          )
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-Acct_Industry-label"
                      >
                        Opportunity Type:
                      </InputLabel>
                      <Select
                        value={this.state.Booking_Type_NL_CS_US}
                        onChange={this.handleChange}
                        name="Booking_Type_NL_CS_US"
                        data-test="Booking_Type_NL_CS_US"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.Booking_Type_NL_CS_US.sort(
                            (a, b) =>
                              a["Booking_Type_NL_CS_US"] <
                              b["Booking_Type_NL_CS_US"]
                                ? -1
                                : a["Booking_Type_NL_CS_US"] >
                                  b["Booking_Type_NL_CS_US"]
                                ? 1
                                : 0
                          )
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company === "C0008" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={4}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.cross_sell1__c.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Sales Type:
                      </InputLabel>
                      <Select
                        data-test="cross_sell1__c"
                        value={this.state.cross_sell1__c}
                        onChange={this.handleChange}
                        name="cross_sell1__c"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.salesType.map((y) => (
                            <MenuItem
                              key={y.cross_sell1__c}
                              value={y.cross_sell1__c}
                            >
                              {y.cross_sell1__c}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        className={classes.filterLabel}
                        style={{
                          color: !this.state.Type.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                        id="select-region-label"
                      >
                        Deal Type:
                      </InputLabel>
                      <Select
                        data-test="Type"
                        value={this.state.Type}
                        onChange={this.handleChange}
                        name="Type"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.dealType.map((y) => (
                            <MenuItem key={y.Type} value={y.Type}>
                              {y.Type}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.props.company !== "C0003" && (
              <Grid
                container
                item
                style={{ padding: 10, paddingTop: 15 }}
                xs={2}
              >
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  container
                  component={Paper}
                  style={{ paddingTop: 8 }}
                >
                  <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel
                        id="select-Solution-Cat-label"
                        style={{
                          color: !this.state.Solution_Cat.includes("All")
                            ? "#4472c4"
                            : "#000",
                        }}
                      >
                        Solution Category:
                      </InputLabel>
                      <Select
                        labelId="select-Solution-Cat-label"
                        id="select-Solution_Cat"
                        value={this.state.Solution_Cat}
                        onChange={this.handleChange}
                        name="Solution_Cat"
                        data-test="Solution_Cat"
                        multiple
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {this.props.funnelFilters !== "" &&
                          this.props.funnelFilters.solutionCat
                            .sort()
                            .filter((item) => item !== "")
                            .map((y) => (
                              <MenuItem key={y} value={y}>
                                {y}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* OppType */}
            {this.props.company === "C0013" && (
              <Grid
                item
                spacing={2}
                xs={2}
                container
                component={Paper}
                style={{ margin: 3 }}
              >
                <Grid item xs={12} style={{ paddingTop: 12 }}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      className={classes.filterLabel}
                      style={{
                        color: !this.state.Opportunity_Record_Type.includes(
                          "All"
                        )
                          ? "#4472c4"
                          : "#000",
                      }}
                      id="select-region-label"
                    >
                      Opportunity Record Type:
                    </InputLabel>
                    <Select
                      data-test="Opportunity_Record_Type"
                      value={this.state.Opportunity_Record_Type}
                      onChange={this.handleChangeFilter}
                      name="Opportunity_Record_Type"
                      multiple
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {this.props.funnelFilters.OpportunityRecordType !== "" &&
                        this.props.funnelFilters.OpportunityRecordType.filter(
                          (y) =>
                            y.type === "Opportunity_Record_Type" &&
                            y.Opportunity_Record_Type !== ""
                        ).map((y) => (
                          <MenuItem
                            key={y.Opportunity_Record_Type}
                            value={y.Opportunity_Record_Type}
                          >
                            {y.Opportunity_Record_Type}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {/* ACV and GO */}
            <Grid
              container
              item
              style={{ padding: 10, paddingTop: 15 }}
              justify="space-between"
              xs={2}
              lg={2}
            >
              {/* <Grid item spacing={2} xs={8} xl={9} container component={Paper} style={{paddingTop:8}} >

                <Grid item xs={12} sm={12} style={{ paddingTop: 0, height: '0px' }}>

                <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={this.props.funnelFilters !== '' && this.props.funnelFilters.acv}
                        // options={this.props.company === 'C0001' ? ['0', '5,000', '10,000', '15,000', '20,000', '30,000', '50,000', '100,000'] : ['0', '50,000', '100,000', '250,000', '500,000', '750,000', '1,000,000']}
                        //getOptionLabel={(option) => typeof (option) === 'string' ? option : stringNumFormatter(parseInt(option))}
                        onChange={this.onAcvChange}
                        value={this.state.acv}
                        data-test="acv"
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField {...params} label="ACV >= :" margin="normal" variant="standard" style={{ marginTop: 4, }}
                            InputLabelProps={{ shrink: true, style: { color: this.state.acv !== null && this.state.acv !== 0 ? '#4472c4' : '#000' } }}
                            InputProps={{
                              ...params.InputProps,
                              inputComponent: NumberFormatCustom,
                              startAdornment: (
                                <>
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />

                </Grid>

              </Grid> */}

              <Grid item xs={4} xl={3} container justify="flex-end">
                <Button
                  data-test="go"
                  variant="contained"
                  color="primary"
                  onClick={this.handleGo}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            container
            component={Card}
            style={{ margin: "1rem" }}
            justify="center"
          >
            <Skeleton
              variant="react"
              animation="wave"
              height={120}
              width="90%"
              style={{ margin: "1rem" }}
            />
          </Grid>
        )}

        {this.props.funnelFilters ? (
          <Grid
            item
            xs={12}
            md={12}
            id="grid-container"
            style={{ marginTop: "1rem" }}
          >
            <Paper style={{ marginBottom: "-7px" }}>
              <ToggleButtonGroup
                color="primary"
                indicatorColor="primary"
                value={this.state.tab}
                exclusive
                size="large"
                onChange={this.handleChangeTab}
              >
                <ToggleButton
                  style={{
                    background: this.state.tab === "Summary" ? "#4472C4" : "",
                    color: this.state.tab === "Summary" ? "white" : "",
                    fontSize: "1rem",
                    fontWeight: 600,
                    height: "3.5rem",
                    width: "15rem",
                  }}
                  value="Summary"
                >
                  Conversion Efficiency
                </ToggleButton>
                <ToggleButton
                  style={{
                    background:
                      this.state.tab === "Growth-Modeling" ? "#4472C4" : "",
                    color: this.state.tab === "Growth-Modeling" ? "white" : "",
                    fontSize: "1rem",
                    fontWeight: 600,
                    height: "3.5rem",
                    width: "15rem",
                  }}
                  value="Growth-Modeling"
                >
                  Growth Modeling
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>
          </Grid>
        ) : (
          <Card style={{ margin: "auto", width: "100%" }} raised={true}>
            <Grid item container justify="center">
              <Skeleton
                variant="react"
                animation="wave"
                height={20}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          </Card>
        )}

        {this.props.funnelData !== "" && this.props.funnelData.text ? (
          <Grid
            item
            xs={12}
            className={classes.grid}
            container
            justify="center"
            style={{ marginTop: "1rem" }}
          >
            <Grid
              item
              xs={12}
              style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
            >
              <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                {this.props.funnelData !== "" ? (
                  <Grid item container xs={12} justify="center">
                    <Typography
                      variant="h2"
                      style={{
                        margin: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      Yield from Qualified pipeline at quarter start
                    </Typography>
                    <PipelineTable
                      winAcvData={[this.props.funnelData.text]}
                      splitType={"text"}
                      uniqueNames={["No Data Available"]}
                    />
                  </Grid>
                ) : (
                  <Grid item container justify="center">
                    <Skeleton
                      variant="react"
                      animation="wave"
                      height={120}
                      width="90%"
                      style={{ margin: "1rem" }}
                    />
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
        ) : this.state.tab === "Summary" ? (
          <>
            <Grid
              item
              xs={12}
              className={classes.grid}
              container
              justify="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid
                item
                xs={12}
                style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
              >
                <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                  {this.props.funnelData !== "" ? (
                    <Grid item container xs={12} justify="center">
                      <Grid className={classes.grid} item xs={12}>
                        <DataVisualizer
                          chartName={`Source of Qualified pipe that went to Won`}
                          title={`Source of Qualified pipe that went to Won`}
                          type={this.props.funnelData.splitType}
                          chartType={"acv"}
                          winACVMixAnalysisData={this.props.funnelData.combinedData.filter(
                            (s) =>
                              s.quarter !== "Total" &&
                              s[this.props.funnelData.splitType] !== "Total"
                          )}
                          handleChangeLocation={this.handleChangeLocation}
                          quarter={"quarter"}
                          Value_Label={this.props.Value_Label}
                          tableComponent={
                            <PipelineTable
                              winAcvData={this.props.funnelData.combinedData}
                              splitType={this.props.funnelData.splitType}
                              uniqueNames={this.props.funnelData.uniqueStrings}
                              uniqueStages={this.props.funnelData.uniqueNames.filter(
                                (c) => c !== "Total"
                              )}
                              firstCell="count"
                              secondCell="acv"
                              thirdCell="perc"
                              drilldownHeader="Summary view of Source of Qualified pipe"
                              tableHeader="Source of Qualified pipe"
                              cellHeader1="Opps"
                              cellHeader2="ACV"
                              cellHeader3="% of Total"
                              handleChangeLocation={this.handleChangeLocation}
                              disableColumnTotal={false}
                              dataTest={"Source-of-Qualified-pipe"}
                              alphabetRequired={true}
                              Value_Label={this.props.Value_Label}
                              // averageDevaitionCalculation={this.props.funnelData.averageDevaitionCalculation}
                            />
                          }
                          unit={"acv"}
                          company={this.props.company}
                          clusterTotal={[]}
                          sortByTotal={false}
                          label={""}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item container justify="center">
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>

            {/* <Grid item xs={12} className={classes.grid} container justify="center" style= {{marginTop: "1rem"}}>
            <Grid item xs={12} style={{ marginBottom: '2rem', paddingBottom: '2rem' }} >
              <Card style={{ margin: "auto", width: "100%" }} raised={true}>
                {
                  this.props.funnelData !== '' ?
                    (<Grid style={{ padding: '0.5rem 0.5rem' }} align="center">
                     
                      <Typography variant='h2' style={{ margin: '1rem', display: 'flex', justifyContent: 'center', padding: '1rem' }}>Source of Qualified pipe that went to Won</Typography>
                      <PipelineTable 
                        winAcvData={this.props.funnelData.combinedData}
                        splitType={this.props.funnelData.splitType}
                        uniqueNames={this.props.funnelData.uniqueStrings}
                        uniqueStages={this.props.funnelData.uniqueNames.filter(c => c !== 'Total')}
                        firstCell='count'
                        secondCell='acv'
                        thirdCell='perc'
                        drilldownHeader='Summary view of Source of Qualified pipe'
                        tableHeader = 'Source of Qualified pipe'
                        cellHeader1 = 'Opps'
                        cellHeader2 = 'ACV'
                        cellHeader3 = '% of Total'
                        handleChangeLocation={this.handleChangeLocation}
                        disableColumnTotal={true}
                        dataTest={"Source-of-Qualified-pipe"}
                        alphabetRequired={true}
                        // averageDevaitionCalculation={this.props.funnelData.averageDevaitionCalculation}
                      />
                     
                     
                    
                    </Grid>) :
                    (<Grid item container justify="center">
                      <Skeleton variant="react" animation="wave" height={120} width='90%' style={{ margin: '1rem' }} />
                    </Grid>)
                }
              </Card>
            </Grid>
            </Grid> */}

            <Grid
              item
              xs={12}
              className={classes.grid}
              container
              justify="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid
                item
                xs={12}
                style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
              >
                <Card style={{ margin: "auto", width: "100%" }} raised={false}>
                  {this.props.funnelData !== "" ? (
                    <Grid style={{ padding: "0.5rem 0.5rem" }} align="center">
                      <Typography
                        variant="h2"
                        style={{
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        A: What was qualified and above and projected to be Won
                        vs. what was Won
                      </Typography>
                      <PipelineTable
                        winAcvData={this.props.funnelData.qualifiedAndPrjData}
                        splitType={this.props.funnelData.splitType}
                        uniqueNames={this.props.funnelData.uniqueNames}
                        // averageDevaitionCalculation={this.props.funnelData.averageDevaitionCalculation}
                        firstCell="projectedACV"
                        secondCell="actualACV"
                        thirdCell="convPerc"
                        tableHeader="Stage at quarter start"
                        drilldownHeader="What was qualified and above and projected to be Won vs. what was Won"
                        // cellHeader1="Projected"
                        // cellHeader2="Actual"
                        cellHeader1="In Pipeline"
                        cellHeader2="Converted"
                        key1="Projected"
                        key2="Actual"
                        cellHeader3="% Conv."
                        handleChangeLocation={this.handleChangeLocation}
                        Value_Label={this.props.Value_Label}
                        dataTest={"Stage-at-quarter-start"}
                      />
                    </Grid>
                  ) : (
                    <Grid item container justify="center">
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.grid}
              container
              justify="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid
                item
                xs={12}
                style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
              >
                <Card style={{ margin: "auto", width: "100%" }} raised={false}>
                  {this.props.funnelData !== "" ? (
                    <Grid style={{ padding: "0.5rem 0.5rem" }} align="center">
                      <Typography
                        variant="h2"
                        style={{
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        B: What was Qualified in the qtr. and what was Won
                        within the qtr.
                      </Typography>
                      <PipelineTable
                        winAcvData={
                          this.props.funnelData
                            .newQuaifiedPipeineCreatedVsWonData
                        }
                        splitType={this.props.funnelData.splitType}
                        uniqueNames={
                          this.props.funnelData
                            .newQuaifiedPipeineCreatedVsWonString
                        }
                        uniqueStages={this.props.funnelData.uniqueNames.filter(
                          (c) => c !== "Total"
                        )}
                        firstCell="createdACV"
                        secondCell="actualACV"
                        thirdCell="convPerc"
                        tableHeader=" "
                        drilldownHeader="What was Qualified in the qtr. and what was Won within the qtr."
                        cellHeader1="Created"
                        // cellHeader2="Won"
                        cellHeader2="Converted"
                        key2="Won"
                        cellHeader3="% Conv."
                        handleChangeLocation={this.handleChangeLocation}
                        Value_Label={this.props.Value_Label}
                        dataTest={"qualified-in-quarter"}
                        // averageDevaitionCalculation={this.props.funnelData.averageDevaitionCalculation}
                      />
                    </Grid>
                  ) : (
                    <Grid item container justify="center">
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.grid}
              container
              justify="center"
              style={{ marginTop: "1rem" }}
            >
              <Grid
                item
                xs={12}
                style={{ marginBottom: "2rem", paddingBottom: "2rem" }}
              >
                <Card style={{ margin: "auto", width: "100%" }} raised={false}>
                  {this.props.funnelData !== "" ? (
                    <Grid style={{ padding: "0.5rem 0.5rem" }} align="center">
                      <Typography
                        variant="h2"
                        style={{
                          margin: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem",
                        }}
                      >
                        C. What was available qualified or later at start of
                        qtr. with projected close date NOT in qtr. and what was
                        Won (Typically pulled in)
                      </Typography>
                      <PipelineTable
                        winAcvData={
                          this.props.funnelData.availableVsPulledinData
                        }
                        splitType={this.props.funnelData.splitType}
                        uniqueNames={[
                          this.props.funnelData.availableVsPulledinDataString,
                        ]}
                        uniqueStages={this.props.funnelData.uniqueNames.filter(
                          (c) => c !== "Total"
                        )}
                        firstCell="availableACV"
                        secondCell="pulledinACV"
                        thirdCell="convPerc"
                        tableHeader=" "
                        drilldownHeader="What was Available Qualified pipe vs  Won from Opps qualified or later at start of quarter "
                        cellHeader1="Available"
                        // cellHeader2="Pulled In"
                        //Pulled in converted has 'Converted'
                        cellHeader2="Converted"
                        cellHeader3="% Conv."
                        key2="PCDNotin"
                        key1="Available"
                        handleChangeLocation={this.handleChangeLocation}
                        Value_Label={this.props.Value_Label}
                        dataTest={"PCDNotin-Available"}
                        // averageDevaitionCalculation={this.props.funnelData.averageDevaitionCalculation}
                      />
                    </Grid>
                  ) : (
                    <Grid item container justify="center">
                      <Skeleton
                        variant="react"
                        animation="wave"
                        height={120}
                        width="90%"
                        style={{ margin: "1rem" }}
                      />
                    </Grid>
                  )}
                </Card>
              </Grid>
            </Grid>
          </>
        ) : this.props.funnelData !== "" ? (
          <GrowthModel
            uniquarters={this.props.funnelData.combinedData
              .filter(
                (s) =>
                  s.quarter !== "Total" &&
                  s[this.props.funnelData.splitType] !== "Total"
              )
              .map((f) => f.quarter)
              .filter(getUniqueValues)}
            selectedACVValue={this.props.funnelData.combinedData.filter(
              (s) =>
                s.quarter === "Total" &&
                s[this.props.funnelData.splitType] === "Total"
            )}
            data={this.props.funnelData.combinedData.filter(
              (s) =>
                s.quarter === "Total" &&
                s[this.props.funnelData.splitType] !== "Total"
            )}
            dataA={this.props.funnelData.qualifiedAndPrjData.filter(
              (f) => f.quarter === "Total" && f.Stage_at_time_T === "Total"
            )}
            dataB={this.props.funnelData.newQuaifiedPipeineCreatedVsWonData.filter(
              (f) => f.quarter === "Total"
            )}
            dataC={this.props.funnelData.availableVsPulledinData.filter(
              (f) => f.quarter === "Total"
            )}
            Value_Label={this.props.Value_Label}
            futureQuarters={
              this.props.funnelData.futureQuarterWithdefaultQuarter
            }
            activeFilters={this.state.activeFilters}
          />
        ) : (
          <Card
            style={{ margin: "auto", width: "100%", marginTop: "2rem" }}
            raised={false}
          >
            <Grid item container justify="center">
              <Skeleton
                variant="react"
                animation="wave"
                height={120}
                width="90%"
                style={{ margin: "1rem" }}
              />
            </Grid>
          </Card>
        )}

        {this.state.location === "drilldownOnGraph" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={this.state.header}
              back={this.handleChangeLocationBack}
              filters={this.state.filtersForDrilldown}
            >
              <Drilldown
                header={this.state.header}
                body={this.props.drilldownOnGraphData}
                company={this.props.company}
                acvName={
                  this.state.drildownACVName === "Projected" ||
                  this.state.drildownACVName === "Available"
                    ? "ACV at qtr. start"
                    : "ACV"
                }
                handleSecondLevelDrilldown={this.handleSecondLevelDrilldown}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
        {this.state.location === "drilldownOppId" && (
          <Grid
            item
            xs={12}
            style={{ position: "absolute", width: "100%", marginTop: "30px" }}
          >
            <SingleLevelDrilldown
              header={"Opportunity Change Events"}
              back={this.handleChangeLocationBack}
              stepBack={this.stepBack}
              filters={[]}
            >
              <ExpectedAcvPage
                body={this.props.ExpectedAcvData}
                Value_Label={this.props.Value_Label}
              />
            </SingleLevelDrilldown>
          </Grid>
        )}
      </Grid>
    );
  }
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
      allowNegative={false}
    />
  );
}

function mapStateToProps(state) {
  const {
    funnelFilters,
    funnelData,
    persistentAlert,
    user,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    currentFilters,
    ExpectedAcvData,
  } = state.app;

  return {
    funnelFilters,
    funnelData,
    persistentAlert,
    company: user.company,
    Value_Label: user.Value_Label,
    drilldownOnGraphData,
    updatingFunnelData,
    updatingDrillDownData,
    currentFilters,
    ExpectedAcvData,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getFunnelFilters: (company) => {
    dispatch({ type: "get_funnel_filters_request" });
    appService.getTimeSeriesPredictionFilters("conversionEffeciency").then(
      (json) => {
        dispatch({ type: "get_funnel_filters_success", json });
        dispatch({ type: "get_funnel_data_request" });
        const defaultFiscalQuarter = getDefaultSelectedFiscalQuarters(
          json.message.fiscalQuarters,
          "fiscal_quarter"
        );
        let filters = [];
        if (defaultFiscalQuarter.length > 0) {
          filters = [{ name: "fiscal_quarter", value: defaultFiscalQuarter }];
        }
        if (company === "C0008") {
          const salesFilters = getDefaultSalesType(
            json.message.salesType,
            "cross_sell1__c"
          );
          const dealsFilters = getDefaultSalesType(
            json.message.dealType,
            "Type"
          );
          filters =
            salesFilters[0] === "All"
              ? filters
              : [...filters, { name: "cross_sell1__c", value: salesFilters }];
          filters =
            dealsFilters[0] === "All"
              ? filters
              : [...filters, { name: "Type", value: dealsFilters }];
        }

        const excludLargeDeals = ["All", "No"].includes(
          getDefaultSalesType(
            json.message.exclude_large_deals,
            "exclude_large_deals"
          )[0]
        )
          ? "No"
          : "Yes";
        // console.log(excludLargeDeals)
        if (excludLargeDeals === "Yes") {
          const thresholdFilters = getDefaultThreshold(
            json.message.large_deal_threshold
          );
          filters = [
            ...filters,
            { name: "threshold", value: thresholdFilters },
          ];
        }
        // const xyAxis=json.message.Dimensions.filter(i=>i.type === 'Ch_Opp_Column_Name')
        // const primary=xyAxis.filter(i=>i.default_axis === 'y')[0].Ch_Opp_Column_Name
        // const secondary=xyAxis.filter(i=>i.default_axis === 'x')[0].Ch_Opp_Column_Name
        dispatch({ type: "current_selected_filters", filters });

        appService
          .getTimeSeriesPredictionData(filters, "conversionEffeciency")
          .then(
            (json) => {
              dispatch({ type: "get_funnel_data_success", json });
            },
            (error) => {
              if (typeof error === "object")
                dispatch({
                  type: "get_funnel_data_failure",
                  error: "Something went wrong",
                });
              else dispatch({ type: "get_funnel_data_failure", error });
            }
          );
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_funnel_filters_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_funnel_filters_failure", error });
      }
    );
  },
  getFunnelDataNew: (filters, primary, secondary) => {
    dispatch({ type: "get_funnel_data_request" });
    dispatch({ type: "current_selected_filters", filters });
    appService
      .getTimeSeriesPredictionData(filters, "conversionEffeciency")
      .then(
        (json) => {
          dispatch({ type: "get_funnel_data_success", json });
        },
        (error) => {
          if (typeof error === "object")
            dispatch({
              type: "get_funnel_data_failure",
              error: "Something went wrong",
            });
          else dispatch({ type: "get_funnel_data_failure", error });
        }
      );
  },
  getConversionEffecienceyDrilldown: (filters, key) => {
    dispatch({ type: "get_drilldown_on_graph_request" });
    appService.getConversionEffecienceyDrilldown(filters, key).then(
      (json) => {
        let tempOpportunities = json.message.opportunities[1].map(
          (item, index) => {
            let x = {
              ...item,
              OppNo: index + 1,
            };
            return x;
          }
        );

        tempOpportunities.sort((a, b) => (a.ACV > b.ACV ? -1 : 1));

        tempOpportunities = tempOpportunities.map((item, index) => {
          let temp = {
            ...item,
            OppNo: index + 1,
          };
          return temp;
        });

        json = {
          ...json,
          message: {
            count_acv: json.message.opportunities[0],
            opportunities: tempOpportunities,
            columns: json.message.columns,
          },
        };
        dispatch({ type: "get_drilldown_on_graph_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_drilldown_on_graph_failure",
            error: "Something went wrong",
          });
        else dispatch({ type: "get_drilldown_on_graph_failure", error });
      }
    );
  },
  clearDrilldownData: () => {
    dispatch({ type: "clear_drilldown_data" });
  },
  getOpenOppData: (oppId) => {
    dispatch({ type: "get_expectedAcv_drilldown_data_request" });
    appService.getWaterfallOppData(oppId).then(
      (json) => {
        dispatch({ type: "get_expectedAcv_drilldown_data_success", json });
      },
      (error) => {
        if (typeof error === "object")
          dispatch({
            type: "get_expectedAcv_drilldown_data_failure",
            error: "Something went wrong",
          });
        else
          dispatch({ type: "get_expectedAcv_drilldown_data_failure", error });
      }
    );
  },
  clearExpectedAcvData: () => {
    dispatch({ type: "clear_expected_acv_data" });
  },
});
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(TableRow);

const connectedConversionEfficiencyBySource = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConversionEfficiencyBySource));
export { connectedConversionEfficiencyBySource as ConversionEfficiencyBySource };
