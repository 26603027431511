import { capitalize } from "@material-ui/core";
import * as d3 from "d3"
import { computeDomainLimits } from "../../Dashboard/OpenOpportunities/utils";
import { updatedScheme10 } from "../../util/chartColorScheme";
import { convertToThousands, getUniqueValues, replaceHyphens, _isNaN,customDateFormatter } from "../../util/customFunctions";
import { formatDataValues } from "../../util/dataFormatter";
import { getMixKey } from "../../util/generateHeadings";
import './D3MixAnalysisStackBarChart.scss'
const D3MixStackBarChart = {};
D3MixStackBarChart.create = (el, config) => {

    if (config.data) {
        const handleDrilldown = (drilldownOn, filters, meta1, meta2, meta3) => {
            if ((config.type === "Overall_Health") || (config.type === "label") || (config.type === "Account_Segment")) {
                // console.log('non cust succes')
                config.handleChangeLocation(drilldownOn, filters, meta1, meta2, meta3)
            } else {
                config.handleChangeLocation(drilldownOn, filters, meta1, meta3)
            }


        }

        // config.data = config.data.filter(item => item.Name !== "total").sort((a, b) => b[`${wonLabel}_ACV_%`] - a[`${wonLabel}_ACV_%`])        const totalAcv = config.data.map(item => item.acv).reduce((a, b) => a + b, 0)
        const qtrsOnly = config.data.map(item => item[config.quarter]).filter(getUniqueValues)
        const uniqueQuarters = config.uniqueQuarters.sort()
        let uniqueStages = config.sortedUniqueStages
        const typesArray=['Acct_Industry','Stage','Region_Rollup','Booking_Type_NL_CS_US','Acct_Vertical','Solution_Cat','Team','Cust_Type','Acct_Size_Segment','Apps_Cluster','Partner_Channel__c','Implementation_Primed_by__c','Acct_Segment','Stage_At_Time_T']


        // const YTDData = uniqueQuarters.length > 1 ? uniqueStages.map(stage => ({
        //     acv: config.data.filter(item => item[config.type] === stage).map(item => item.acv).reduce((a, b) => a + b),
        //     [config.quarter]: "Total",
        //     [config.type]: stage
        // })) : null

        let totalByQuarter = uniqueQuarters.map(quarter => (config.data.filter(item => item[config.quarter] === quarter).map(item => item.acv).reduce((a, b) => a + b, 0)))
        // totalByQuarter = [...totalByQuarter, totalAcv]
        let totalByQuarterOnly = uniqueQuarters.map(quarter => {
            return {
                closed_fiscal_quarter: quarter,
                acv: (config.data.filter(item => item[config.quarter] === quarter).map(item => item.acv).reduce((a, b) => a + b, 0))
            }
        })
        totalByQuarterOnly = totalByQuarterOnly

        // if (YTDData !== null) {
        //     config.data = [...config.data, ...YTDData]
        //     uniqueQuarters.push("Total")

        // }
        config.barColor = '#70ad47';
        config.barHeight = 50;
        config.margin = {
            top: 50,
            right: 10,
            bottom: 70,
            left: 70
        };
        config.width = d3.select(el).node() ? d3.select(el).node().getBoundingClientRect().width : 100;
        // if(qtrsOnly.length === 2){
        //     config.width = 400;
        //     // config.margin.left = 500
        // } else if(qtrsOnly.length === 1){
        //     config.width = 250;
        // }



        config.height = 500 - config.margin.top - config.margin.bottom;
        var x = d3.scaleBand()
            .rangeRound([0, config.width - config.margin.left])
            .padding(0.6)
            .align(0.5);


        // set y scale
        var y = d3.scaleLinear()
            .range([config.height, 0]);
        x.domain((uniqueQuarters));
        // y.domain([0, _isNaN(d3.max(config.data, d => d.acv) + d3.max(config.data, d => d.acv)) || d3.max(config.data, d => d.acv) + d3.max(config.data, d => d.acv) === undefined ? 100 * 1000 : d3.max(config.data, d => d.acv) + (config.quarter === "projected_close_fiscal_quarter" || config.quarter === "Account_Segment" || config.quarter === "Exec_Outreach_Fiscal_Qtr" ? 1.9 * d3.max(config.data, d => d.acv) : config.quarter === "fiscalQuarter" ? 2.3 * d3.max(config.data, d => d.acv) : 1 * d3.max(config.data, d => d.acv))])
        const {upper_bound,lower_bound} = computeDomainLimits(0,d3.max(totalByQuarter, d => d) !== 0 ? d3.max(totalByQuarter, d => d) : 100 * 1000)
        y.domain([0, upper_bound])




        const color = config.color
        var chartContainer = d3.select(el)
            .append('svg')
            .attr('width', config.width)
            .attr('height', config.height + config.margin.top + config.margin.bottom + 30)
        var chart = chartContainer
            .append('g')
            .attr("transform", "translate(" + config.margin.left + "," + config.margin.top + ")");

        var dataConfig = []
        var graphDataTemp;
        var graphData = []
        // var legend = d3.select(el).append("div").attr('class', 'legend')
        uniqueQuarters.forEach((qtr, i) => {
            graphDataTemp = config.data.filter(it => it[config.quarter]).reduce(
                (obj, item) => Object.assign(obj, { [item[config.type]]: item.acv }), {});

            graphDataTemp[config.quarter] = qtr
            graphData.push(graphDataTemp)

        })
        let dataUpdated = []
        uniqueQuarters.map(qtr => {
            const temp = config.data.filter(item => item[config.quarter] === qtr).reduce(
                (obj, item) =>
                    Object.assign(obj, { [item[config.type]]: item.acv }), {}
            );

            const tempUpdated = {
                ...temp,
                [config.quarter]: qtr
            }
            dataUpdated.push(tempUpdated)

        })
        var dataset = d3.stack()
            .keys(uniqueStages)
            (dataUpdated)

        var xAxis = chart.append("g")
            .attr("transform", `translate(0,${config.height})`)
            .attr("class", "x-axis")
        function make_y_gridlines() {
            return d3.axisLeft(y)
        }
        // add the Y gridlines
        chart
            .append("g")
            .attr("class", "grid")
            .attr("z-index", -1)
            .call(make_y_gridlines().tickSize(-config.width).tickFormat(""));

        chart.selectAll(".x-axis")


            .call(d3.axisBottom(x).tickFormat(d => (config.quarter === "Open_Datetime" || config.isWeek) ? customDateFormatter(d) : d).tickSizeOuter(0))
            .selectAll("text")
            .style("text-anchor", d => uniqueQuarters.length >= 11 ? "end" : null)
            .attr("dx", d => uniqueQuarters.length >= 11 ? "-.4rem" : null)
            .attr("dy", d => uniqueQuarters.length >= 11 ? ".7rem" : "0.6rem")
            .attr("transform", d => uniqueQuarters.length >= 11 ? "rotate(-45)" : "rotate(0)");
        chart.append("g").call(d3.axisLeft(y).tickFormat(d => `$${d3.format(".2s")(d)}`.toUpperCase()));


        //   chart.append("g")
        //       .attr("class", "axis")
        //       .call(d3.axisLeft(y))
        //         .append("text")
        //       .attr("x", 2)
        //       .attr("y", y(y.ticks().pop()) + 0.5)
        //       .attr("dy", "0.32em")
        //       .attr("fill", "#000")
        //       .attr("font-weight", "bold")
        //       .attr("text-anchor", "start");

        let key;
        chart.append("g")
            .selectAll("g")
            .data(dataset)
            .enter().append("g")
            .attr("fill", function (d) { return color(d.key); })
            .on("mouseover", d => key = d.key)
            .style("opacity", 0.9)
            .selectAll("rect")
            .data(function (d) { return d; })
            .enter().append("rect")
            .style("cursor", config.isDrilldown === false ? "" :  "pointer")
            .attr("data-test", `${getMixKey(config.type)}-stackbar`)
            // .on("click", d => config.isDrilldown !== false ? typesArray.includes(config.type) ? config.handleChangeLocation("drilldownOnGraph", { row: [key], cell: [d.data[config.quarter]], x:config.type, y: key}, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : config.handleChangeLocation("drilldownOnGraph", { row: key, cell: d.data[config.quarter]}, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : '')
            .on('click', d => config.chartName === 'Pipeline Trends' ? config.handleChangeLocation("drilldownOnGraph", { row: [key], cell: [d.data[config.quarter]], x:"Stage_At_Time_T", y: key}, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : config.chartName === 'Pipeline Trends Rolling' ? config.isDrilldown !== false ? typesArray.includes(config.type) ? config.handleChangeLocation("drilldownOnGraph", { row: [key], cell: [d.data[config.quarter]], x:config.type, y: key}, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : '' : '' : config.handleChangeLocation("drilldownOnGraph", { row: config.type ==='qfq' ? config.data.filter(i=>i.qfq === key && i.closed_fiscal_quarter === d.data[config.quarter])[0].qualified_fiscal_quarter : key, cell: d.data[config.quarter]}, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter))
            .attr("x", d => x(d.data[config.quarter]))
            .attr("y", d => y(d[1]))
            .attr("height", d => y(d[0]) - y(d[1]))
            .attr("width", x.bandwidth())

        chart.append("g")
            .selectAll("g")
            .data(dataset)
            .enter().append("g")
            .selectAll("text")
            .on("mouseover", d => key = d.key)
            .data(function (d) { return d; })
            .enter().append("text")
            .attr("data-test", `${getMixKey(config.type)}-stackbar`)
            .style("font-size", '0.8rem')

            .style("cursor", config.isDrilldown === false ? "" : "pointer")
            .attr("text-anchor", "middle")
            // .on("click", d => config.isDrilldown !== false ? typesArray.includes(config.type) ? config.handleChangeLocation("drilldownOnGraph", { row: [key], cell: [d.data[config.quarter]],x:config.type, y:key }, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : config.handleChangeLocation("drilldownOnGraph", { row: key, cell: d.data[config.quarter] }, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : '')
            .on('click', d => config.chartName === 'Pipeline Trends' ? config.handleChangeLocation("drilldownOnGraph", { row: [key], cell: [d.data[config.quarter]],x:"Stage_At_Time_T", y:key }, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : config.chartName === 'Pipeline Trends Rolling' ? config.isDrilldown !== false ? typesArray.includes(config.type) ? config.handleChangeLocation("drilldownOnGraph", { row: [key], cell: [d.data[config.quarter]],x:config.type, y:key }, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : '' : '' : config.handleChangeLocation("drilldownOnGraph", { row: config.type ==='qfq' ? config.data.filter(i=>i.qfq === key && i.closed_fiscal_quarter === d.data[config.quarter])[0].qualified_fiscal_quarter : key, cell: d.data[config.quarter] }, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter))
            .attr("x", function (d) { return x(d.data[config.quarter]) + x.bandwidth() / 2; })
            .attr("y", function (d) { return y(d[1] - 10) + (y(d[0]) - y(d[1])) / 2; })
            .attr('fill', d => config.type === "Overall_Health" ? 'black' : 'white')
            .text((d, i) => { return `${y(d[0]) - y(d[1]) >= 27 && x.bandwidth() > 40 ? `${formatDataValues(d[1] - d[0], true)}` : ''}`; })
        chart.append("g")
            .attr('class', 'percent')
            .selectAll("g")
            .data(dataset)
            .enter().append("g")
            // .attr('class', 'percent')
            .selectAll("text")
            .on("mouseover", d => key = d.key)
            .data(function (d) { return d; })
            .enter().append("text")
            .attr("data-test", `${getMixKey(config.type)}-stackbar`)
            .attr('fill', d => config.type === "Overall_Health" ? 'black' : 'white')
            // .style('display', 'none')
            .style("font-size", '0.7rem')
            .attr("text-anchor", "middle")
            .style("cursor", config.isDrilldown === false ? "" : "pointer")
            // .on("click",  d => config.isDrilldown !== false ?  typesArray.includes(config.type) ?  config.handleChangeLocation("drilldownOnGraph", { row: [key], cell: [d.data[config.quarter]], x:config.type, y:key }, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : config.handleChangeLocation("drilldownOnGraph", { row: key, cell: d.data[config.quarter] }, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : '')
            // .on('click', d => config.handleChangeLocation("drilldownOnGraph", { row: key, cell: d.data[config.quarter] }, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter))
            .on("click",  d => config.chartName === 'Pipeline Trends' ? config.handleChangeLocation("drilldownOnGraph", { row: [key], cell: [d.data[config.quarter]], x:"Stage_At_Time_T", y:key }, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : config.chartName === 'Pipeline Trends Rolling' ? config.isDrilldown !== false ? typesArray.includes(config.type) ?  config.handleChangeLocation("drilldownOnGraph", { row: [key], cell: [d.data[config.quarter]], x:config.type, y:key }, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter) : '' : '' : config.handleChangeLocation("drilldownOnGraph", { row: config.type ==='qfq' ? config.data.filter(i=>i.qfq === key && i.closed_fiscal_quarter === d.data[config.quarter])[0].qualified_fiscal_quarter : key, cell: d.data[config.quarter] }, (config.type === "Overall_Health") || (config.type === "label") ? "rawVals" : config.type, config.quarter))
            .attr("x", function (d) { return x(d.data[config.quarter]) + x.bandwidth() / 2; })
            .attr("y", function (d) { return y(d[1] - 10) + ((y(d[0]) - y(d[1])) / 2) + (x.bandwidth() > 40 ? 13 : 0); })
            .text((d, i) => { return `${y(d[0]) - y(d[1]) >= 27 && x.bandwidth() > 30 ? `(${formatDataValues(((d[1] - d[0]) / totalByQuarter[i]) * 100)})` : ''}`; })
        // 

        chart.append('g')
            .selectAll('g')
            .data(totalByQuarterOnly)
            .enter().append('text')
            .attr("data-test", `${getMixKey(config.type)}-stackbar`)
            .attr("x", d => x(d.closed_fiscal_quarter) + (x.bandwidth() / 2))
            .attr("y", d => y(d.acv) - 5)
            .attr("text-anchor", "middle")
            .style("font-size", '0.8rem')
            .text(d => x.bandwidth() > 20 ? `${formatDataValues(d.acv, true)}` : "")
            .style("cursor", config.isDrilldown === false ? "" : config.disablecolumnTotal ? '' : "pointer")
            .on("click", d => config.isDrilldown === false ? ""  : config.disablecolumnTotal ? '' : config.chartName === "Pipeline Trends Rolling" ? handleDrilldown("drilldownOnGraph", { row: (config.type === "Overall_Health") || (config.type === "Account_Segment") || (config.type === "label") || ( typesArray.includes(config.type)) ? uniqueStages : "Total", cell: ( typesArray.includes(config.type)) ? [d.closed_fiscal_quarter] : d.closed_fiscal_quarter, x:config.type, y:"All" }, config.type, (config.type === "Overall_Health") || (config.type === "label") || (config.type === "Account_Segment") ? "rawValsTotal" : config.type, config.quarter) : 
             handleDrilldown("drilldownOnGraph", { row: (config.type === "Overall_Health") || (config.type === "Account_Segment") || (config.type === "label") || (config.type === "Stage_At_Time_T") ? uniqueStages : "Total", cell: (config.type === "Stage_At_Time_T") ? [d.closed_fiscal_quarter] : d.closed_fiscal_quarter, x:"Stage_At_Time_T", y:"All" }, config.type, (config.type === "Overall_Health") || (config.type === "label") || (config.type === "Account_Segment") ? "rawValsTotal" : config.type, config.quarter))
            // .on("click", d => config.isDrilldown === false ? "" : handleDrilldown("drilldownOnGraph", { row: (config.type === "Overall_Health") || (config.type === "Account_Segment") || (config.type === "label") || ( typesArray.includes(config.type)) ? uniqueStages : "Total", cell: ( typesArray.includes(config.type) || config.chartName === "PipPipeline Trends Rolling" ) ? [d.closed_fiscal_quarter] : d.closed_fiscal_quarter, x:config.type, y:"All" }, config.type, (config.type === "Overall_Health") || (config.type === "label") || (config.type === "Account_Segment") ? "rawValsTotal" : config.type, config.quarter))

        chart
            .append("text")
            .attr("dy", "1rem")
            .attr("font-size", "0.85rem")
            // .attr("font-weight", "500")
            .attr("y", config.margin.left - 200)
            .attr("x", -(config.height / 2))
            .attr("transform", "rotate(-90)") // although axis is rotated, text is not
            .attr("fill", "#00000")
            .text("ACV");
        chart
            .append("text")
            .attr("y", config.height + 45)
            .attr("x", (config.width / 2) - 90)
            .attr("dy", (config.quarter === "Open_Datetime" || config.isWeek) ? uniqueQuarters.length >= 10 ? "3rem": "1rem" : "1rem")
            .attr("class", "axis-label")
            .attr("font-size", "0.85rem")
            // .attr("font-weight", "500")
            .attr("fill", "#000000")
            .text(d => config.quarter === "fiscalQuarter" ? "Fiscal Qtr." : config.quarter === "Open_Datetime" ? "Date" : config.quarter.split("_").map(item => capitalize(item)).join(" "))




    }

}
D3MixStackBarChart.destroy = (el) => {
    // Cleaning code here
    d3.select(el).selectAll("svg").remove();
    d3.select('.tooltip').remove();
    d3.select('.legend-flex').remove();
};
export default D3MixStackBarChart;