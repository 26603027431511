import {
  CardContent,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import StyledTableRow from "./StyledTableRow";
import {
  convertToThousands,
  getUniqueValues,
  _isNaN,
  countOrACVFormatter,
  customDateFormatter,
} from "../util/customFunctions";
//  import { convertToThousands, getUniqueValues, _isNaN } from '../../util/customFunctions';
import * as d3 from "d3";
import CopyTablesObject from "./xlsx-export/CopyTablesObject";
// import { getMixKey } from '../../util/generateHeadings';
// import { customSortedOppType, customSortedSegments } from '../../util/customSortings';
// import { deSnake } from '../../util/dataFormatter';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CustomWidthTooltip from "./Buttons/Expand_Collapse_Tooltip";

const useStyle = makeStyles((theme) => ({
  content: { width: "100%" },
  sticky: {
    position: "sticky",
    left: -1,
    background: "white",
    // outline: "0.5px solid lightgrey",
    // outlineOffset: "-1.5px",
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "1.5px", // Adjust the thickness as needed
      background: "lightgrey", // Adjust the color as needed
    },
  },
  stickyHeader: {
    position: "sticky",
    top: -1,
    background: "white",
    // outline: "0.2px solid lightgrey",
    // outlineOffset: "-1.5px",
    outline: "lightgrey solid 0.5px",
    outlineOffset: "-1.5px",
    zIndex: 5,
    // Create a pseudo-element for the fixed right border
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.5px", // Adjust the thickness as needed
      // background: "lightgrey", // Adjust the color as needed
    },
  },
  table: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  segmentHeader: {
    fontWeight: 600,
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
  },
  repHeader: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    fontWeight: "inherit",
  },
  repText: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "left",
  },
  repNumber: {
    border: "0.5px solid lightgrey",
    padding: "5px",
    color: "inherit",
    textAlign: "right",
  },
  fontBold: {
    fontWeight: 600,
  },
  tableColWidth: {
    minWidth: 90,
  },
  drilldownCell: {
    cursor: "pointer",
  },
}));
export default function DynamictableV2(props) {
  const classes = useStyle();
  const tableRef = React.createRef();

  const tableContainerRef = useRef();

  // const [isOverflowing, setIsOverflowing] = useState(false);
  let acvLabel = "ACV";
  if (props.Value_Label) {
    acvLabel = props.Value_Label;
  }

  const [isSticky, setIsSticky] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [rowPin, setrowPin] = useState(false);
  const [globalExpandArrow, setglobalExpandArrow] = useState(false);
  const [parentWidth, setparentWidth] = useState();

  let globalExpand = [];

  const handleExpandClick = (rowId) => {
    setExpandedRows((prevExpandedRows) => {
      const isRowExpanded = prevExpandedRows.includes(rowId);
      if (isRowExpanded) {
        return prevExpandedRows.filter((expandedRow) => expandedRow !== rowId);
      } else {
        return [...prevExpandedRows, rowId];
      }
    });
  };

  useEffect(() => {
    setExpandedRows(globalExpand);
  }, []);

  const globalExpandData = (con) => {
    if (con === true) {
      setExpandedRows(globalExpand);
      // setglobalExpandArrow(true);
    } else {
      setExpandedRows([]);
      // setglobalExpandArrow(false);
    }
  };

  let countinc = 0;

  var uniqueQuarters = props.data
    .map((d) => d[props.quarter])
    .filter(getUniqueValues)
    .sort((a, b) => (a < b ? -1 : 1));
  let uniqueStages = props.data
    .map((d) => d[props.stage])
    .filter(getUniqueValues);
  let uniqueRep = props.data
    .filter((d) => d[props.stage] !== "total")
    .map((d) => d[props.stage])
    .filter(getUniqueValues);
  let uniqueTeam = props.data.map((d) => d.Team).filter(getUniqueValues);
  let uniqueTeams = uniqueTeam.filter((d) => d !== "total");
  const reps = props.data.filter((d) => d.team && d[props.stage] !== "total");
  // const Rep = uniqueTeams.map(team => reps.filter(x => x.team === team).map(x => x[props.stage]))

  const total = [];
  uniqueQuarters.map((qtr) => {
    const data = props.data.filter((d) => d[props.quarter] === qtr);
    const acv = data.map((a) => a.acv).reduce((a, b) => a + b, 0);
    const count = data.map((a) => a.count).reduce((a, b) => a + b, 0);
    total.push({
      acv,
      count,
      Team: "total",
      Owner_Name: "total",
      [props.quarter]: qtr,
    });
  });

  var sortedUniquestages = {};
  uniqueTeams.map(
    (item) =>
      (sortedUniquestages[item] = props.data
        .filter((f) => f.Team === item)
        .map((i) => i.acv)
        .reduce((a, b) => a + b, 0))
  );
  const sortable = Object.fromEntries(
    Object.entries(sortedUniquestages)
      .sort(([, a], [, b]) => a - b)
      .reverse()
  );

  uniqueTeams = Object.keys(sortable);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    const handleScroll = () => {
      if (tableContainer.scrollLeft > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (tableContainer.scrollTop > 0) {
        setrowPin(true);
      } else {
        setrowPin(false);
      }
    };

    // Attach a scroll event listener to the TableContainer
    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [classes.sticky]);

  const updateParentWidth = () => {
    const newParentWidth =
      tableContainerRef.current.getBoundingClientRect().width;
    setparentWidth(newParentWidth);
  };

  useEffect(() => {
    // Initial width calculation
    updateParentWidth();

    // Add event listener for window resize
    window.addEventListener("resize", updateParentWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateParentWidth);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <div
      className={classes.root}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CardContent className={classes.content} style={{ padding: "1rem" }}>
        {/* {isOverflowing ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              marginRight: "1rem",
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null} */}
        {parentWidth !== undefined ? (
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "0.6rem",
              // marginRight: "1rem",
              width: `${parentWidth}px`,
            }}
          >
            <CopyTablesObject tableRef={tableRef} />
          </div>
        ) : null}
        <Grid container justify="flex-start" style={{ padding: "1rem" }}>
          <TableContainer
            style={{
              width: "auto",
              borderLeft: isSticky ? "0.5px solid lightgrey" : "",
              borderTop: rowPin ? "0.5px solid lightgrey" : "",
              maxHeight: "84vh",
            }}
            id="myTableContainer-Second"
            ref={tableContainerRef}
          >
            {/* {isOverflowing === false ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  // marginRight: "10px",
                }}
              >
                <CopyTablesObject tableRef={tableRef} />
              </div>
            ) : null} */}
            <Table
              ref={tableRef}
              size="small"
              aria-label="html table"
              style={{ tableLayout: "auto", width: "initial" }}
              data-test="Dynamic-Team-Rep-detail-table"
              id={props.dataID}
            >
              <TableHead style={{ position: "sticky", top: -1, zIndex: 5 }}>
                <TableRow
                  // className={rowPin ? classes.stickyHeader : ""}
                  style={{
                    position: "sticky",
                    outline: "lightgrey solid 0.5px",
                    // outlineOffset: "-1.5px",
                  }}
                >
                  <TableCell
                    align="center"
                    // className={`${classes.repHeader}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : `${classes.repHeader}`
                    }
                    colSpan={1}
                    style={{ minWidth: 150, background: "white" }}
                  >
                    {props.label}
                  </TableCell>
                  {uniqueQuarters.map((i, index) => {
                    return (
                      <TableCell
                        key={i}
                        align="center"
                        style={
                          index % 2 === 0
                            ? {
                                background: "#4471c4",
                                color: "white",
                                minWidth: 140,
                              }
                            : {
                                background: "#5b9bd5",
                                color: "white",
                                padding: "10px 0",
                                minWidth: 100,
                              }
                        }
                        className={classes.repHeader}
                        colSpan={3}
                      >
                        {props.isWeek ? customDateFormatter(i) : i}
                      </TableCell>
                    );
                  })}
                  {uniqueQuarters.length >= 1 && (
                    <TableCell
                      key={"24"}
                      align="center"
                      style={
                        uniqueQuarters.length > 1 &&
                        uniqueQuarters.length % 2 === 0
                          ? { background: "#4471c4", color: "white" }
                          : { background: "#5b9bd5", color: "white" }
                      }
                      className={classes.repHeader}
                      colSpan={3}
                    >
                      {"Total"}
                    </TableCell>
                  )}

                  {uniqueQuarters.length === 0 && (
                    <TableCell
                      rowSpan={8}
                      className={classes.repHeader}
                      style={{ width: 400, textAlign: "center" }}
                    >
                      No data available
                    </TableCell>
                  )}
                </TableRow>

                <TableRow
                  style={{
                    fontWeight: 600,
                    paddingLeft: 5,
                    position: "sticky",
                    outline: "lightgrey solid 0.5px",
                    // outlineOffset: "-1.5px",
                  }}
                  // className={rowPin ? classes.stickyHeader : ""}
                >
                  <TableCell
                    align="center"
                    // className={classes.repHeader}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repHeader}`
                        : `${classes.repHeader} `
                    }
                    colSpan={1}
                    style={{
                      // outline: "lightgrey solid 0.5px",
                      // outlineOffset: "-1.5px",
                      background: "white",
                    }}
                  >
                    {/* {`Team/Rep`} */}
                    <div style={{ display: "flex" }}>
                      <div style={{ marginTop: "2.5px" }}> {`Team/Rep`}</div>

                      <CustomWidthTooltip title={"Expand All"} placement="top">
                        <KeyboardArrowDownIcon
                          onClick={() => globalExpandData(true)}
                          style={{ color: "gray" }}
                        />
                      </CustomWidthTooltip>
                      <CustomWidthTooltip
                        title={"Collapse All"}
                        placement="top"
                      >
                        <KeyboardArrowUpIcon
                          onClick={() => globalExpandData(false)}
                          style={{ color: "gray" }}
                        />
                      </CustomWidthTooltip>
                    </div>
                  </TableCell>
                  {uniqueQuarters.map((quarter, i) => (
                    <React.Fragment key={i}>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 60, background: "white" }}
                      >
                        {" "}
                        # of Opps{" "}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 90, background: "white" }}
                      >
                        {/* ACV */}
                        {`${acvLabel}`}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 90, background: "white" }}
                      >
                        % of Total
                      </TableCell>
                    </React.Fragment>
                  ))}
                  {uniqueQuarters.length >= 1 && (
                    <React.Fragment key={"xyz"}>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 60, background: "white" }}
                      >
                        {" "}
                        # of Opps{" "}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 90, background: "white" }}
                      >
                        {/* ACV */}
                        {`${acvLabel}`}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.repHeader}
                        colSpan={1}
                        style={{ minWidth: 90, background: "white" }}
                      >
                        % of Total
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueTeams.map((team, i) => {
                  countinc = countinc + 1; //For team background colour
                  globalExpand.push(i);
                  const teamData = props.data.filter(
                    (item) => item.Team === team
                  );
                  const reps = props.data
                    .filter((item) => item.Team === team)
                    .map((item) => item[props.stage])
                    .filter(getUniqueValues);
                  return (
                    <>
                      <StyledTableRow key={i} style={{ background: "#dae3f3" }}>
                        <TableCell
                          align="left"
                          // className={`${classes.repText} ${classes.fontBold}`}

                          className={
                            isSticky
                              ? `${classes.sticky} ${classes.repText} ${classes.fontBold}`
                              : `${classes.repText} ${classes.fontBold}`
                          }
                          style={
                            isSticky && countinc % 2 === 0
                              ? { background: "#dae3f3" }
                              : { background: "#dae3f3" }
                          }
                          colSpan={1}
                          onClick={() => handleExpandClick(i)}
                        >
                          {/* {team} */}
                          <div style={{ display: "flex" }}>
                            {team !== "Grand Total" ? (
                              expandedRows.includes(i) ? (
                                <KeyboardArrowUpIcon
                                  style={{ color: "gray" }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  style={{ color: "gray" }}
                                />
                              )
                            ) : null}
                            <div style={{ marginTop: "3px" }}>{team}</div>
                          </div>
                        </TableCell>
                        {uniqueQuarters.map((qtr, i) => {
                          const acv = teamData
                            .filter((item) => item[props.quarter] === qtr)
                            .reduce((a, b) => a + b.acv, 0);
                          const count = teamData
                            .filter((item) => item[props.quarter] === qtr)
                            .reduce((a, b) => a + b.count, 0);
                          //  console.log(total, total.reduce((a,b) => a+b.acv, 0))
                          const perc =
                            acv !== 0 &&
                            total
                              .filter((item) => item[props.quarter] === qtr)
                              .reduce((a, b) => a + b.acv, 0) !== 0
                              ? acv /
                                total
                                  .filter((item) => item[props.quarter] === qtr)
                                  .reduce((a, b) => a + b.acv, 0)
                              : 0;

                          const reps = teamData
                            .filter((item) => item[props.quarter] === qtr)
                            .map((x) => x[props.stage]);
                          return (
                            <>
                              <TableCell
                                align="left"
                                className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                                colSpan={1}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row: [team], cell: [qtr] },
                                    "Team",
                                    props.quarter
                                  )
                                }
                              >
                                {count === "undefiend" ||
                                count === null ||
                                _isNaN(count)
                                  ? "-"
                                  : count}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                                colSpan={1}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row: [team], cell: [qtr] },
                                    "Team",
                                    props.quarter
                                  )
                                }
                              >
                                {acv === "undefiend" ||
                                acv === null ||
                                _isNaN(acv)
                                  ? "-"
                                  : countOrACVFormatter(acv, true)}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                                colSpan={1}
                                onClick={() =>
                                  props.handleChangeLocation(
                                    "drilldownOnGraph",
                                    { row: [team], cell: [qtr] },
                                    "Team",
                                    props.quarter
                                  )
                                }
                              >
                                {perc === "undefiend" ||
                                perc === null ||
                                _isNaN(perc)
                                  ? "0"
                                  : Math.round(perc * 100)}
                                %
                              </TableCell>
                            </>
                          );
                        })}
                        {uniqueQuarters.length >= 1 && (
                          <>
                            <TableCell
                              align="left"
                              className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                              colSpan={1}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row: [team], cell: uniqueQuarters },
                                  "Team",
                                  props.quarter
                                )
                              }
                            >
                              {teamData.reduce((a, b) => a + b.count, 0) ===
                                "undefiend" ||
                              teamData.reduce((a, b) => a + b.count, 0) ===
                                null ||
                              _isNaN(teamData.reduce((a, b) => a + b.count, 0))
                                ? "-"
                                : countOrACVFormatter(
                                    teamData.reduce((a, b) => a + b.count, 0)
                                  )}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                              colSpan={1}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row: [team], cell: uniqueQuarters },
                                  "Team",
                                  props.quarter
                                )
                              }
                            >
                              {teamData.reduce((a, b) => a + b.acv, 0) ===
                                "undefiend" ||
                              teamData.reduce((a, b) => a + b.acv, 0) ===
                                null ||
                              _isNaN(teamData.reduce((a, b) => a + b.acv, 0))
                                ? "-"
                                : countOrACVFormatter(
                                    teamData.reduce((a, b) => a + b.acv, 0),
                                    true
                                  )}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                              colSpan={1}
                              onClick={() =>
                                props.handleChangeLocation(
                                  "drilldownOnGraph",
                                  { row: [team], cell: uniqueQuarters },
                                  "Team",
                                  props.quarter
                                )
                              }
                            >
                              {_isNaN(
                                teamData.reduce((a, b) => a + b.acv, 0) /
                                  total.reduce((a, b) => a + b.acv, 0)
                              )
                                ? 0
                                : Math.round(
                                    (teamData.reduce((a, b) => a + b.acv, 0) /
                                      total.reduce((a, b) => a + b.acv, 0)) *
                                      100
                                  )}
                              %
                            </TableCell>
                          </>
                        )}
                      </StyledTableRow>

                      {expandedRows.includes(i) &&
                        reps.map((rep) => {
                          countinc = countinc + 1;
                          return (
                            <StyledTableRow key={i}>
                              <TableCell
                                align="left"
                                // style={{ paddingLeft: "25px" }}
                                // className={`${classes.table}`}
                                className={
                                  isSticky
                                    ? `${classes.sticky} ${classes.table}`
                                    : classes.table
                                }
                                style={
                                  isSticky && countinc % 2 === 0
                                    ? {
                                        background: "#f5f5f5",
                                        paddingLeft: "40px",
                                      }
                                    : { paddingLeft: "40px" }
                                }
                                colSpan={1}
                              >
                                {rep}
                              </TableCell>
                              {uniqueQuarters.map((qtr, i) => {
                                const acv = teamData
                                  .filter(
                                    (item) =>
                                      item[props.quarter] === qtr &&
                                      item[props.stage] === rep
                                  )
                                  .reduce((a, b) => a + b.acv, 0);
                                const count = teamData
                                  .filter(
                                    (item) =>
                                      item[props.quarter] === qtr &&
                                      item[props.stage] === rep
                                  )
                                  .reduce((a, b) => a + b.count, 0);
                                const perc =
                                  acv !== 0 &&
                                  total
                                    .filter(
                                      (item) => item[props.quarter] === qtr
                                    )
                                    .reduce((a, b) => a + b.acv, 0) !== 0
                                    ? acv /
                                      total
                                        .filter(
                                          (item) => item[props.quarter] === qtr
                                        )
                                        .reduce((a, b) => a + b.acv, 0)
                                    : 0;
                                return (
                                  <>
                                    <TableCell
                                      align="left"
                                      className={`${classes.repNumber} ${classes.drilldownCell}`}
                                      colSpan={1}
                                      onClick={() =>
                                        props.handleChangeLocation(
                                          "drilldownOnGraph",
                                          { row: [rep], cell: [qtr] },
                                          props.stage,
                                          props.quarter,
                                          team
                                        )
                                      }
                                    >
                                      {count === "undefiend" ||
                                      count === null ||
                                      _isNaN(count)
                                        ? "-"
                                        : count}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={`${classes.repNumber} ${classes.drilldownCell}`}
                                      colSpan={1}
                                      onClick={() =>
                                        props.handleChangeLocation(
                                          "drilldownOnGraph",
                                          { row: [rep], cell: [qtr] },
                                          props.stage,
                                          props.quarter,
                                          team
                                        )
                                      }
                                    >
                                      {acv === "undefiend" ||
                                      acv === null ||
                                      _isNaN(acv)
                                        ? "-"
                                        : countOrACVFormatter(acv, true)}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={`${classes.repNumber} ${classes.drilldownCell}`}
                                      colSpan={1}
                                      onClick={() =>
                                        props.handleChangeLocation(
                                          "drilldownOnGraph",
                                          { row: [rep], cell: [qtr] },
                                          props.stage,
                                          props.quarter,
                                          team
                                        )
                                      }
                                    >
                                      {perc === "undefiend" ||
                                      perc === null ||
                                      _isNaN(perc)
                                        ? "0"
                                        : Math.round(perc * 100)}
                                      %
                                    </TableCell>
                                  </>
                                );
                              })}

                              {uniqueQuarters.length >= 1 && (
                                <>
                                  <TableCell
                                    align="left"
                                    className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                                    colSpan={1}
                                    onClick={() =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        { row: [rep], cell: uniqueQuarters },
                                        props.stage,
                                        props.quarter,
                                        team
                                      )
                                    }
                                  >
                                    {teamData
                                      .filter(
                                        (item) => item[props.stage] === rep
                                      )
                                      .reduce((a, b) => a + b.count, 0) ===
                                      "undefiend" ||
                                    teamData
                                      .filter(
                                        (item) => item[props.stage] === rep
                                      )
                                      .reduce((a, b) => a + b.count, 0) ===
                                      null ||
                                    _isNaN(
                                      teamData
                                        .filter(
                                          (item) => item[props.stage] === rep
                                        )
                                        .reduce((a, b) => a + b.count, 0)
                                    )
                                      ? "-"
                                      : countOrACVFormatter(
                                          teamData
                                            .filter(
                                              (item) =>
                                                item[props.stage] === rep
                                            )
                                            .reduce((a, b) => a + b.count, 0)
                                        )}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                                    colSpan={1}
                                    onClick={() =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        { row: [rep], cell: uniqueQuarters },
                                        props.stage,
                                        props.quarter,
                                        team
                                      )
                                    }
                                  >
                                    {teamData
                                      .filter(
                                        (item) => item[props.stage] === rep
                                      )
                                      .reduce((a, b) => a + b.acv, 0) ===
                                      "undefiend" ||
                                    teamData
                                      .filter(
                                        (item) => item[props.stage] === rep
                                      )
                                      .reduce((a, b) => a + b.acv, 0) ===
                                      null ||
                                    _isNaN(
                                      teamData
                                        .filter(
                                          (item) => item[props.stage] === rep
                                        )
                                        .reduce((a, b) => a + b.acv, 0)
                                    )
                                      ? "-"
                                      : countOrACVFormatter(
                                          teamData
                                            .filter(
                                              (item) =>
                                                item[props.stage] === rep
                                            )
                                            .reduce((a, b) => a + b.acv, 0),
                                          true
                                        )}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={`${classes.repNumber} ${classes.fontBold}  ${classes.drilldownCell}`}
                                    colSpan={1}
                                    onClick={() =>
                                      props.handleChangeLocation(
                                        "drilldownOnGraph",
                                        { row: [rep], cell: uniqueQuarters },
                                        props.stage,
                                        props.quarter,
                                        team
                                      )
                                    }
                                  >
                                    {_isNaN(
                                      teamData
                                        .filter(
                                          (item) => item[props.stage] === rep
                                        )
                                        .reduce((a, b) => a + b.acv, 0) /
                                        props.data.reduce(
                                          (a, b) => a + b.acv,
                                          0
                                        )
                                    )
                                      ? 0
                                      : Math.round(
                                          (teamData
                                            .filter(
                                              (item) =>
                                                item[props.stage] === rep
                                            )
                                            .reduce((a, b) => a + b.acv, 0) /
                                            props.data.reduce(
                                              (a, b) => a + b.acv,
                                              0
                                            )) *
                                            100
                                        )}
                                    %
                                  </TableCell>
                                </>
                              )}
                            </StyledTableRow>
                          );
                        })}
                    </>
                  );
                })}

                <StyledTableRow
                  key={"Total"}
                  className={classes.fontBold}
                  style={{ background: "#dae3f3" }}
                >
                  <TableCell
                    align="left"
                    // className={`${classes.repText} ${classes.fontBold}`}
                    className={
                      isSticky
                        ? `${classes.sticky} ${classes.repText} ${classes.fontBold}`
                        : `${classes.repText} ${classes.fontBold}`
                    }
                    style={
                      isSticky && (countinc + 1) % 2 === 0
                        ? { background: "#dae3f3" }
                        : { background: "#dae3f3" }
                    }
                    colSpan={1}
                  >
                    {"Total"}
                  </TableCell>

                  {total
                    .sort((a, b) => (a < b ? -1 : 1))
                    .map((cell, j) => {
                      const dat = cell[props.quarter];

                      return (
                        <React.Fragment>
                          {/* {console.log(dat)} */}
                          <TableCell
                            align="right"
                            className={`${classes.repNumber} ${classes.fontBold} ${classes.drilldownCell}`}
                            colSpan={1}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { row: uniqueRep, cell: [cell[props.quarter]] },
                                props.stage,
                                props.quarter
                              )
                            }
                          >
                            {cell.count === "undefiend" ||
                            cell.count === null ||
                            _isNaN(cell.count)
                              ? "-"
                              : cell.count}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={`${classes.repNumber} ${classes.fontBold} ${classes.drilldownCell}`}
                            colSpan={1}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { row: uniqueRep, cell: [cell[props.quarter]] },
                                props.stage,
                                props.quarter
                              )
                            }
                          >
                            {cell.acv === "undefiend" ||
                            cell.acv === null ||
                            _isNaN(cell.acv)
                              ? "-"
                              : countOrACVFormatter(cell.acv, true)}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={`${classes.repNumber} ${classes.fontBold} ${classes.drilldownCell}`}
                            colSpan={1}
                            onClick={() =>
                              props.handleChangeLocation(
                                "drilldownOnGraph",
                                { row: uniqueRep, cell: [cell[props.quarter]] },
                                props.stage,
                                props.quarter
                              )
                            }
                          >
                            100%
                          </TableCell>
                        </React.Fragment>
                      );
                    })}

                  <TableCell
                    align="right"
                    className={`${classes.repNumber} ${classes.fontBold} ${classes.drilldownCell}`}
                    colSpan={1}
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        { row: uniqueRep, cell: uniqueQuarters },
                        props.stage,
                        props.quarter
                      )
                    }
                  >
                    {total.reduce((a, b) => a + b.count, 0) === "undefiend" ||
                    total.reduce((a, b) => a + b.count, 0) === null ||
                    _isNaN(total.reduce((a, b) => a + b.count, 0))
                      ? "-"
                      : countOrACVFormatter(
                          total.reduce((a, b) => a + b.count, 0)
                        )}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={`${classes.repNumber} ${classes.fontBold} ${classes.drilldownCell}`}
                    colSpan={1}
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        { row: uniqueRep, cell: uniqueQuarters },
                        props.stage,
                        props.quarter
                      )
                    }
                  >
                    {total.reduce((a, b) => a + b.acv, 0) === "undefiend" ||
                    total.reduce((a, b) => a + b.acv, 0) === null ||
                    _isNaN(total.reduce((a, b) => a + b.acv, 0))
                      ? "-"
                      : countOrACVFormatter(
                          total.reduce((a, b) => a + b.acv, 0),
                          true
                        )}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={`${classes.repNumber} ${classes.fontBold} ${classes.drilldownCell}`}
                    colSpan={1}
                    onClick={() =>
                      props.handleChangeLocation(
                        "drilldownOnGraph",
                        { row: uniqueRep, cell: uniqueQuarters },
                        props.stage,
                        props.quarter
                      )
                    }
                  >
                    100%
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </div>
  );
}
