import { D3SankeyChart } from "./D3Sankey";
import { countOrACVFormatter } from "../../util/customFunctions";
import * as d3Sankey from "d3-sankey";
import * as d3 from "d3";
class SankeyChartBuilder {
  createSankeySVG(data, options) {
    const chart = D3SankeyChart(
      {
        links: data,
      },
      {
        stageToColorMap: options.stageToColorMap,
        nodeGroup: (d) => d.id.split(/\W/)[0], // take first word for color
        nodeAlign: d3Sankey.sankeyLeft, // e.g., d3.sankeyJustify; set by input above
        linkColor: "source-target", // e.g., "source" or "target"; set by input above
        format: ((f) => (d) => {
          const value = `${f(d)}`;

          return `${options.label} ${options.isAcv} ${value}`;
        })(d3.format(",.1~f")),
        width: 1100,
        height: 600,
      }
    );
    return chart;
  }
  attachToDOM(chart, svgContainer) {
    if (svgContainer !== null) {
      console.log(`inserting chart`, chart, svgContainer);
      svgContainer.innerHTML += chart.outerHTML;
    }
  }
  create(data, svgContainer, options) {
    try {
      const chart = this.createSankeySVG(data, options);
      this.attachToDOM(chart, svgContainer);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  remove(el) {
    console.log(el);
    d3.select(el).selectAll("svg").remove();
  }
}
const sankeyChartBuilder = new SankeyChartBuilder();
export default sankeyChartBuilder;
